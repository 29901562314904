import { IonContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import React from 'react';
import TimeLineCustom from './TimeLine';
import { es } from 'date-fns/locale';
import { format, isAfter, subSeconds } from 'date-fns';
import { CardFooter } from './AlertCard';

const SaeDetailsModal: React.FC<any> = ({ massageDetails, active }) => {
  return (
    <IonContent
      className={`ion-content-custom-background${!active ? '-inactive' : ''}`}
    >
      <div
        className={`alert-details-content-block${
          !active ? '-inactive' : ''
        } alert ionitem-no-margin alert-details-item ${
          !active && 'alert-details-item-inactive'
        }`}
        style={{
          paddingBottom: '0',
          // marginTop: active ? '0' : 'var(--display-large)',
        }}
      >
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol>
              <h1
                className="alert-details-item-header1"
                style={{
                  textOverflow: 'inherit',
                  whiteSpace: 'normal',
                  paddingTop: '0',
                  // textDecoration: !active ? 'line-through' : 'none',
                }}
              >
                {massageDetails.Event}
              </h1>
            </IonCol>
          </IonRow>

          <IonRow style={{ margin: '10px 0' }}>
            <IonCol size="4">
              <p
                className="ion-no-margin
              alert-details-item-text-md"
                style={{ fontWeight: 'var(--font-weight-bold)' }}
              >
                Fecha:
              </p>
            </IonCol>
            <IonCol>
              <p className="ion-no-margin alert-details-item-text-md">
                {format(
                  new Date(massageDetails.DateTime),
                  "dd/LL/yyyy 'a las' HH:mm",
                  {
                    locale: es,
                  }
                )}
              </p>
            </IonCol>
          </IonRow>

          <IonRow style={{ margin: '10px 0' }}>
            <IonCol size="4">
              <p
                className="ion-no-margin
              alert-details-item-text-md"
                style={{ fontWeight: 'var(--font-weight-bold)' }}
              >
                Región:
              </p>
            </IonCol>
            <IonCol>
              <p className="ion-no-margin alert-details-item-text-md">
                {massageDetails.Region}
              </p>
            </IonCol>
          </IonRow>

          <IonRow style={{ margin: '10px 0' }}>
            <IonCol size="4">
              <p
                className="ion-no-margin
              alert-details-item-text-md"
                style={{ fontWeight: 'var(--font-weight-bold)' }}
              >
                Comuna:
              </p>
            </IonCol>
            <IonCol>
              <p className="ion-no-margin alert-details-item-text-md">
                {massageDetails.Polygon}
              </p>
            </IonCol>
          </IonRow>

          <IonRow
            style={{
              margin: '10px 0',
              paddingBottom: 'var(--space-3)',
              borderBottom: '1px solid var(--itrend--blueGray-100)',
              paddingTop: 'var(--space-3)',
              borderTop: '1px solid var(--itrend--blueGray-100)',
            }}
          >
            <IonCol>
              <p className="ion-no-margin alert-details-item-text-md">
                {massageDetails.Message}
              </p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-justify-content-start">
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <CardFooter
                  active={true}
                  cardType={'Sae'}
                  shareData={{
                    ...massageDetails,
                  }}
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonContent>
  );
};

export default SaeDetailsModal;
