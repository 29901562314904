/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
/* eslint-disable no-var */

import { FirebaseOptions } from 'firebase/app';

import { PermissionState } from '@capacitor/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { CDAlertasApi } from '../../services/CDAlertasApi';
import { AppDispatch, RootState } from '../../store';
import {
  addCardToFavoritesBulk,
  addCardToFavoritesBulkRaw,
  removeCardFromFavoritesBulkRaw,
} from '../favorites/favoritesActions';
import { snsSubscribeTopicChunked } from '../featuresUtils';
import {
  alert_type_amarilla_str,
  alert_type_preventiva_str,
  alert_type_roja_str,
  AlertLifecycle,
  alertLifecycleCodes,
  AlertType,
  alertType2name,
  cutCodesToRegion,
  RegionCode,
  regionCodes,
  regionCutCodes,
  StaticTopicType,
} from './notifications';

export const addAllRegionsToNotifications = createAsyncThunk<
  {
    target: string;
    alertType: string;
    regionTypeSuccess: boolean;
    perAlertSuccess: boolean;
  },
  { alertType: string },
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('notifications/add_all_regions', async ({ alertType }, thunkApi) => {
  const { notifications } = thunkApi.getState();
  const { lifecycle } = notifications;
  // Get region cut codes
  const cutCodes: Array<string> = Object.values(regionCutCodes);

  // Get arns from cut codes
  const topics = notifications.staticTopics.filter((topic: StaticTopicType) => {
    return (
      cutCodes.includes(topic.regionCut) &&
      alertType2name[alertType as AlertType] === topic.alertStatus
    );
  });
  const arns = topics.map((topic: StaticTopicType) => topic.arn) ?? [];

  // ! Unsuccessful return when there are no ARNs
  if (arns.length == 0)
    return {
      target: '',
      alertType,
      regionTypeSuccess: false,
      perAlertSuccess: false,
    };

  const staticDedupFilters = topics.map((topic: StaticTopicType) => {
    return {
      minRegion: [{ numeric: ['>', parseInt(topic.regionCut) - 1, '<', 17] }],
    };
  });

  // * ###########################################################################
  // * Subscribe to region-type specific static topics

  // * Subscribe to ARNs
  const regionTypeTopicsResponse = await snsSubscribeTopicChunked({
    topicARN: arns,
    endpoint: notifications.endpointARN,
    filters: staticDedupFilters,
    thunkApi,
  });

  // * ###########################################################################
  // * Subscribe and mark as favorite all active alerts in given region and type
  // Retrieve all alerts in the given region-type
  const alertsInRegionTypes = await thunkApi.dispatch(
    CDAlertasApi.endpoints.getAllActiveAlertsByRegionAndType.initiate({
      alertType: alertType2name[alertType as AlertType],
    })
  );

  // Compute per-alert ARNs
  const alertIdsForRegionTypes = (alertsInRegionTypes?.data?.data || [])
    .map((a: any) => a?.sinapredId)
    .filter((x: any) => x);

  const arnsForAlertsInRegionTypes = (alertsInRegionTypes?.data?.data || [])
    .map((a: any) => a?.topicData?.arn)
    .filter((x: any) => x);

  // Compute current lifecycle filters for per-alert suscription
  const enabledLifecycles = Object.entries(lifecycle)
    .filter((l) => l[1])
    .map((l) => l[0]);
  const lifecycleCodes = enabledLifecycles.map(
    (l) => alertLifecycleCodes[l as AlertLifecycle]
  );

  // Perform bulk suscription to alerts
  const perAlertResponse = await snsSubscribeTopicChunked(
    (lifecycleCodes || []).length > 0
      ? {
          topicARN: arnsForAlertsInRegionTypes,
          endpoint: notifications.endpointARN,
          filters: {
            actionType: lifecycleCodes,
          },
          thunkApi,
        }
      : {
          topicARN: arnsForAlertsInRegionTypes,
          endpoint: notifications.endpointARN,
          thunkApi,
        }
  );

  // Mark all alert ids as favorites by dispatching to the current store
  await thunkApi.dispatch(addCardToFavoritesBulkRaw(alertIdsForRegionTypes));

  const regionTypeSuccess =
    (regionTypeTopicsResponse as any)?.data &&
    !('error' in (regionTypeTopicsResponse as any));

  const perAlertSuccess =
    (perAlertResponse as any)?.data && !('error' in (perAlertResponse as any));

  return {
    target: 'all',
    alertType,
    regionTypeSuccess,
    perAlertSuccess,
  };
});

export const removeAllRegionsToNotifications = createAsyncThunk<
  {
    target: string;
    alertType: string;
    regionTypeSuccess: boolean;
    perAlertSuccess: boolean;
  },
  { alertType: string },
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
>('notifications/remove_all_regions', async ({ alertType }, thunkApi) => {
  const { notifications } = thunkApi.getState();
  // Get region cut codes
  const cutCodes: Array<string> = Object.values(regionCutCodes);

  // Get arns from cut codes
  const topics = notifications.staticTopics.filter((topic: StaticTopicType) => {
    return (
      cutCodes.includes(topic.regionCut) &&
      alertType2name[alertType as AlertType] === topic.alertStatus
    );
  });
  const arns = topics.map((topic: StaticTopicType) => topic.arn) ?? [];

  // ! Unsuccessful return when there are no ARNs
  if (arns.length == 0)
    return {
      target: 'all',
      alertType,
      regionTypeSuccess: false,
      perAlertSuccess: false,
    };

  // * ###########################################################################
  // * Unsubscribe from region-type specific static topics
  const regionTypeTopicResponses = await thunkApi.dispatch(
    CDAlertasApi.endpoints.snsUnsubscribeTopic.initiate({
      topicARN: arns,
      endpoint: notifications.endpointARN,
    })
  );

  // * ###########################################################################
  // * Unsubscribe and unmark as favorite all active alerts in given region and type

  // Retrieve all alerts in the given region-type
  const alertsInRegionTypes = await thunkApi.dispatch(
    CDAlertasApi.endpoints.getAllActiveAlertsByRegionAndType.initiate({
      alertType: alertType2name[alertType as AlertType],
    })
  );

  // Compute per-alert ARNs
  const alertIdsForRegionTypes = (alertsInRegionTypes?.data?.data || [])
    .map((a: any) => a?.sinapredId)
    .filter((x: any) => x);

  const arnsForAlertsInRegionTypes = (alertsInRegionTypes?.data?.data || [])
    .map((a: any) => a?.topicData?.arn)
    .filter((x: any) => x);

  // Perform bulk unsuscription to alerts
  const perAlertResponse = await thunkApi.dispatch(
    CDAlertasApi.endpoints.snsUnsubscribeTopic.initiate({
      topicARN: arnsForAlertsInRegionTypes,
      endpoint: notifications.endpointARN,
    })
  );

  // Unmark all alert ids as favorites by dispatching to the current store
  await thunkApi.dispatch(
    removeCardFromFavoritesBulkRaw(alertIdsForRegionTypes)
  );

  const regionTypeSuccess =
    (regionTypeTopicResponses as any)?.data &&
    !('error' in (regionTypeTopicResponses as any));

  const perAlertSuccess =
    (perAlertResponse as any)?.data && !('error' in (perAlertResponse as any));

  return {
    target: 'all',
    alertType,
    regionTypeSuccess,
    perAlertSuccess,
  };
});

export const addRegionToNotifications = createAsyncThunk<
  {
    region: string;
    alertType: string;
    regionTypeSuccess: boolean;
    perAlertSuccess: boolean;
  },
  { region: string; alertType: string },
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('notifications/add_region', async ({ region, alertType }, thunkApi) => {
  const { notifications } = thunkApi.getState();
  const { lifecycle } = notifications;
  // Get region cut code
  const cutCode = regionCutCodes[region as RegionCode];
  const cutCodeInt = parseInt(cutCode);

  // Get arns from cut code
  const topics = notifications.staticTopics.filter((topic: StaticTopicType) => {
    return (
      topic.regionCut === cutCode &&
      alertType2name[alertType as AlertType] === topic.alertStatus
    );
  });
  const arns = topics.map((topic: StaticTopicType) => topic.arn) ?? [];

  if (arns.length == 0)
    return {
      region,
      alertType,
      regionTypeSuccess: false,
      perAlertSuccess: false,
    };

  // * ###########################################################################
  // * Subscribe to region-type specific static topic
  const regionTypeTopicResponse = await snsSubscribeTopicChunked({
    topicARN: arns,
    endpoint: notifications.endpointARN,
    filters: {
      minRegion: [
        {
          numeric: ['>', cutCodeInt - 1, '<', 17],
        },
      ],
    },
    thunkApi,
  });

  // * ###########################################################################
  // * Subscribe and mark as favorite all active alerts in given region and type

  // Retrieve all alerts in the given region-type
  const alertsInRegionType = await thunkApi.dispatch(
    CDAlertasApi.endpoints.getAllActiveAlertsByRegionAndType.initiate({
      cutRegion: cutCode,
      alertType: alertType2name[alertType as AlertType],
    })
  );

  // Compute per-alert ARNs
  const alertIdsForRegionType = (alertsInRegionType?.data?.data || [])
    .map((a: any) => a?.sinapredId)
    .filter((x: any) => x);

  const arnsForAlertsInRegionType = (alertsInRegionType?.data?.data || [])
    .map((a: any) => a?.topicData?.arn)
    .filter((x: any) => x);

  // Compute current lifecycle filters for per-alert suscription
  const enabledLifecycles = Object.entries(lifecycle)
    .filter((l) => l[1])
    .map((l) => l[0]);
  const lifecycleCodes = enabledLifecycles.map(
    (l) => alertLifecycleCodes[l as AlertLifecycle]
  );

  // Perform bulk suscription to alerts
  const perAlertResponse = await snsSubscribeTopicChunked(
    (lifecycleCodes || []).length > 0
      ? {
          topicARN: arnsForAlertsInRegionType,
          endpoint: notifications.endpointARN,
          filters: {
            actionType: lifecycleCodes,
          },
          thunkApi,
        }
      : { topicARN: arns, endpoint: notifications.endpointARN, thunkApi }
  );

  // Mark all alert ids as favorites by dispatching to the current store
  await thunkApi.dispatch(addCardToFavoritesBulkRaw(alertIdsForRegionType));

  const regionTypeSuccess =
    (regionTypeTopicResponse as any)?.data &&
    !('error' in (regionTypeTopicResponse as any));

  const perAlertSuccess =
    (perAlertResponse as any)?.data && !('error' in (perAlertResponse as any));

  return {
    region,
    alertType,
    regionTypeSuccess,
    perAlertSuccess,
  };
});

export const removeRegionFromNotifications = createAsyncThunk<
  {
    region: string;
    alertType: string;
    regionTypeSuccess: boolean;
    perAlertSuccess: boolean;
  },
  { region: string; alertType: string },
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('notifications/remove_region', async ({ region, alertType }, thunkApi) => {
  const { notifications } = thunkApi.getState();
  // Get region cut code
  const cutCode = regionCutCodes[region as RegionCode];
  // Get arns from cut code
  const topics = notifications.staticTopics.filter((topic: StaticTopicType) => {
    return (
      topic.regionCut === cutCode &&
      alertType2name[alertType as AlertType] === topic.alertStatus
    );
  });
  const arns = topics.map((topic: StaticTopicType) => topic.arn) ?? [];

  if (arns.length == 0)
    return {
      region,
      alertType,
      regionTypeSuccess: false,
      perAlertSuccess: false,
    };

  // * ###########################################################################
  // * Unsubscribe from region-type specific static topic
  const regionTypeTopicResponse = await thunkApi.dispatch(
    CDAlertasApi.endpoints.snsUnsubscribeTopic.initiate({
      topicARN: arns,
      endpoint: notifications.endpointARN,
    })
  );

  // * ###########################################################################
  // * Unsubscribe and unmark as favorite all active alerts in given region and type

  // Retrieve all alerts in the given region-type
  const alertsInRegionType = await thunkApi.dispatch(
    CDAlertasApi.endpoints.getAllActiveAlertsByRegionAndType.initiate({
      cutRegion: cutCode,
      alertType: alertType2name[alertType as AlertType],
    })
  );

  // Compute per-alert ARNs
  const alertIdsForRegionType = (alertsInRegionType?.data?.data || [])
    .map((a: any) => a?.sinapredId)
    .filter((x: any) => x);

  const arnsForAlertsInRegionType = (alertsInRegionType?.data?.data || [])
    .map((a: any) => a?.topicData?.arn)
    .filter((x: any) => x);

  // Perform bulk unsuscription to alerts
  const perAlertResponse = await thunkApi.dispatch(
    CDAlertasApi.endpoints.snsUnsubscribeTopic.initiate({
      topicARN: arnsForAlertsInRegionType,
      endpoint: notifications.endpointARN,
    })
  );

  // Unmark all alert ids as favorites by dispatching to the current store
  await thunkApi.dispatch(
    removeCardFromFavoritesBulkRaw(alertIdsForRegionType)
  );

  const regionTypeSuccess =
    (regionTypeTopicResponse as any)?.data &&
    !('error' in (regionTypeTopicResponse as any));

  const perAlertSuccess =
    (perAlertResponse as any)?.data && !('error' in (perAlertResponse as any));

  return {
    region,
    alertType,
    regionTypeSuccess,
    perAlertSuccess,
  };
});

export const addAlertLifecycleToNotifications = createAsyncThunk<
  Array<string>,
  Array<string>,
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(
  'notifications/add_alert_lifecycle',
  async (alertLifecycles: Array<string>, thunkApi) => {
    const { notifications, favorites } = thunkApi.getState();
    const { lifecycle } = notifications;
    // Check if we should subscribe
    const shouldSubscribe = notifications.notifyFollowing;
    if (!shouldSubscribe) return alertLifecycles;

    // Get enabled lifecycles
    const oldEnabledLifecycles = Object.entries(lifecycle)
      .filter((l) => l[1])
      .map((l) => l[0]);
    const enabledLifecycles = [...alertLifecycles, ...oldEnabledLifecycles];
    const lifecycleCodes = enabledLifecycles.map(
      (l) => alertLifecycleCodes[l as AlertLifecycle]
    );
    // Get alert ids
    const alertIDs = (favorites.currentFavorites ?? []).filter(
      (a: string) => a !== ''
    );
    if (alertIDs.length === 0) return alertLifecycles;
    // Get current arns
    const alerts = await thunkApi.dispatch(
      CDAlertasApi.endpoints.getAlertsWithIds.initiate({
        idsToCheck: alertIDs,
      })
    );
    const arns = alerts?.data?.data
      .map((a: any) => a?.topicData?.arn)
      .filter((a: any) => a !== undefined);
    if (arns.length == 0) return alertLifecycles;

    // Sub to new ones
    if (lifecycleCodes.length > 0) {
      const resp = await snsSubscribeTopicChunked({
        topicARN: arns,
        endpoint: notifications.endpointARN,
        filters: {
          actionType: lifecycleCodes,
        },
        thunkApi,
      });
      return (resp as any)?.data && !('error' in (resp as any))
        ? alertLifecycles
        : [''];
    } else {
      // Unsub from old ones
      await thunkApi.dispatch(
        CDAlertasApi.endpoints.snsUnsubscribeTopic.initiate({
          topicARN: arns,
          endpoint: notifications.endpointARN,
        })
      );
    }
    return alertLifecycles;
  }
);

export const removeAlertLifecycleFromNotifications = createAsyncThunk<
  Array<string>,
  Array<string>,
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(
  'notifications/remove_alert_lifecycle',
  async (alertLifecycles: Array<string>, thunkApi) => {
    const { notifications, favorites } = thunkApi.getState();
    const { lifecycle } = notifications;
    // Check if we should subscribe
    const shouldSubscribe = notifications.notifyFollowing;
    if (!shouldSubscribe) return alertLifecycles;
    // Get enabled lifecycles
    const oldEnabledLifecycles = Object.entries(lifecycle)
      .filter((l) => l[1])
      .map((l) => l[0]);
    const enabledLifecycles = oldEnabledLifecycles.filter(
      (l) => !alertLifecycles.includes(l)
    );
    const lifecycleCodes = enabledLifecycles.map(
      (l) => alertLifecycleCodes[l as AlertLifecycle]
    );
    // Get alert ids
    const alertIDs = (favorites.currentFavorites ?? []).filter(
      (a: string) => a !== ''
    );
    if (alertIDs.length === 0) return alertLifecycles;
    // Get current arns
    const alerts = await thunkApi.dispatch(
      CDAlertasApi.endpoints.getAlertsWithIds.initiate({
        idsToCheck: alertIDs,
      })
    );
    const arns = alerts?.data?.data
      .map((a: any) => a?.topicData?.arn)
      .filter((a: any) => a !== undefined);
    if (arns.length == 0) return alertLifecycles;
    // Sub to new ones
    if (lifecycleCodes.length > 0) {
      const resp = await snsSubscribeTopicChunked({
        topicARN: arns,
        endpoint: notifications.endpointARN,
        filters: {
          actionType: lifecycleCodes,
        },
        thunkApi,
      });

      return (resp as any)?.data && !('error' in (resp as any))
        ? alertLifecycles
        : [''];
    } else {
      // Unsub from old ones
      await thunkApi.dispatch(
        CDAlertasApi.endpoints.snsUnsubscribeTopic.initiate({
          topicARN: arns,
          endpoint: notifications.endpointARN,
        })
      );
    }
    return alertLifecycles;
  }
);

export const enableNotifyFollowing = createAsyncThunk<
  Array<string>,
  void,
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('notifications/enable_notify_following', async (_: void, thunkApi) => {
  const { notifications, favorites } = thunkApi.getState();
  const { lifecycle } = notifications;
  // Get enabled lifecycles
  const oldEnabledLifecycles = Object.entries(lifecycle)
    .filter((l) => l[1])
    .map((l) => l[0]);
  const lifecycleCodes = oldEnabledLifecycles.map(
    (l) => alertLifecycleCodes[l as AlertLifecycle]
  );
  // Get alert ids
  const alertIDs = (favorites.currentFavorites ?? []).filter(
    (a: string) => a !== ''
  );
  // Get current arns
  const alerts = await thunkApi.dispatch(
    CDAlertasApi.endpoints.getAlertsWithIds.initiate({ idsToCheck: alertIDs })
  );
  const arns = alerts?.data?.data
    .map((a: any) => a?.topicData?.arn)
    .filter((a: any) => a !== undefined);
  if (arns.length == 0) return oldEnabledLifecycles;
  // Sub to new ones
  if (lifecycleCodes.length > 0) {
    const resp = await snsSubscribeTopicChunked({
      topicARN: arns,
      endpoint: notifications.endpointARN,
      filters: { actionType: lifecycleCodes },
      thunkApi,
    });
    return (resp as any)?.data && !('error' in (resp as any))
      ? oldEnabledLifecycles
      : [];
  }
  return oldEnabledLifecycles;
});

export const disableNotifyFollowing = createAsyncThunk<
  Array<string>,
  void,
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('notifications/disable_notify_following', async (_: void, thunkApi) => {
  const { notifications, favorites } = thunkApi.getState();
  const { lifecycle } = notifications;
  // Get enabled lifecycles
  const oldEnabledLifecycles = Object.entries(lifecycle)
    .filter((l) => l[1])
    .map((l) => l[0]);
  // Get alert ids
  const alertIDs = (favorites.currentFavorites ?? []).filter(
    (a: string) => a !== ''
  );
  // Get current arns
  const alerts = await thunkApi.dispatch(
    CDAlertasApi.endpoints.getAlertsWithIds.initiate({ idsToCheck: alertIDs })
  );
  const arns = alerts?.data?.data
    .map((a: any) => a?.topicData?.arn)
    .filter((a: any) => a !== undefined);
  if (arns.length == 0) return oldEnabledLifecycles;
  // Unsub from old ones
  await thunkApi.dispatch(
    CDAlertasApi.endpoints.snsUnsubscribeTopic.initiate({
      topicARN: arns,
      endpoint: notifications.endpointARN,
    })
  );
  return oldEnabledLifecycles;
});

export const updateFirebaseOptionsAndARN = createAsyncThunk<
  FirebaseOptions & { arn?: string; vapid?: string },
  FirebaseOptions & { arn?: string; vapid?: string },
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
>(
  'notifications/update_firebase_options_arn',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (settings: FirebaseOptions & { arn?: string; vapid?: string }, _) => {
    return settings;
  }
);

export const setFirebaseInitialized = createAsyncThunk<
  boolean,
  void,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
>(
  'notifications/set_is_firebase_initialized',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (_) => {
    return true;
  }
);

export type SnsUpdateAction =
  | 'CREATE_ENDPOINT'
  | 'UPDATE_ENDPOINT'
  | 'KEEP_ENDPOINT';

export const updateSnsEndpoint = createAsyncThunk<
  { token?: string; endpointARN?: string; nextAction?: SnsUpdateAction },
  { new_fcm_token: string; user_id?: string; user_name?: string },
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(
  'notifications/update_notification_settings',
  async ({ new_fcm_token, user_id = 'TEST', user_name = 'TEST' }, thunkApi) => {
    const { notifications } = thunkApi.getState();
    const { platformApplicationARN, fcmToken, endpointARN } = notifications;

    const endpointStatusResponse = await thunkApi.dispatch(
      CDAlertasApi.endpoints.snsGetEndpointStatus.initiate({
        endpointARN,
      })
    );

    const {
      data: endpointStatusResponseData,
      isError,
      error,
    } = endpointStatusResponse;

    if (isError) {
      console.error('Error validating endpoint status', error);
    }

    const isCurrentEndpointEnabled =
      !isError &&
      endpointStatusResponseData &&
      'Enabled' in endpointStatusResponseData &&
      endpointStatusResponseData['Enabled'] === true;

    // * An endpoint with error means that the endpoint
    // * does not exist or has severe issues. Meanwhile,
    // * a disabled endpoint exists and might have subscriptions,
    // * but it just needs to be enabled again.
    const isCurrentEndpointWithError =
      isError ||
      (endpointStatusResponseData && 'error' in endpointStatusResponseData);

    const isNewTokenSameAsPrevious = new_fcm_token === fcmToken;

    // ****************************************************************
    // * Determine whether to keep, create, or update the SNS endpoint

    // * By default we assume we need to keep current endpoint as it is.
    let nextAction: SnsUpdateAction = 'KEEP_ENDPOINT';

    // fcmToken | endpointARN | isCurrentEndpointWithError
    //     0    |      0      |            0
    //     0    |      0      |            1
    //     0    |      1      |            0
    //     0    |      1      |            1
    //     1    |      0      |            0
    //     1    |      0      |            1
    //     1    |      1      |            0 <- this is the only case where we update
    //     1    |      1      |            1
    if (!fcmToken || !endpointARN || isCurrentEndpointWithError) {
      // * If:
      // * - there is no previous token, or
      // * - no previous endpoint, or
      // * - the previous endpoint is invalid
      // * Then:
      // * - we must create a new SNS endpoint.
      nextAction = 'CREATE_ENDPOINT';
    } else if (
      endpointARN &&
      (!isCurrentEndpointEnabled || !isNewTokenSameAsPrevious)
    ) {
      // * If:
      // * - the endpoint exists, and
      // *  - the endpoint is valid and disabled, or
      // *  - the new_fcm_token is different than current fcmToken
      // * Then:
      // * - update endpoint with new token, and also enable it.
      nextAction = 'UPDATE_ENDPOINT';
    }

    // *********************************************************
    // * Dispatch what to do, depending on `nextAction` value
    if (nextAction === 'CREATE_ENDPOINT') {
      const createEndpointResponse = await thunkApi.dispatch(
        CDAlertasApi.endpoints.snsCreateEndpoint.initiate({
          token: new_fcm_token,
          platformApplicationARN,
          overwrite: true,
          customUserData: {
            user_id: user_id,
            user_name: user_name,
          },
        })
      );

      if (
        (createEndpointResponse as any)?.data?.ResponseMetadata
          ?.HTTPStatusCode !== 200
      ) {
        console.error(
          'Non 200 response when creating new SNS endpoint',
          createEndpointResponse
        );
        throw thunkApi.rejectWithValue(JSON.stringify(createEndpointResponse));
      }

      /* ****************************************************** */

      if ('data' in createEndpointResponse) {
        const endpointARN = createEndpointResponse.data['EndpointArn'];

        // ! TODO
        // ! What happens if we need to create a new endpoint,
        // ! but there are already subscriptions configured?

        return { token: new_fcm_token, endpointARN, nextAction };
      } else if ('error' in createEndpointResponse) {
        console.error(
          'Error creating new SNS endpoint',
          createEndpointResponse
        );
        thunkApi.rejectWithValue(JSON.stringify(createEndpointResponse));
      }
    } else if (nextAction === 'UPDATE_ENDPOINT') {
      const configEndpointResponse = await thunkApi.dispatch(
        CDAlertasApi.endpoints.snsConfigEndpoint.initiate({
          endpointARN,
          token: new_fcm_token,
          enabled: true,
          customUserData: {
            user_id: user_id,
            user_name: user_name,
          },
        })
      );

      if (
        (configEndpointResponse as any)?.data?.ResponseMetadata
          ?.HTTPStatusCode !== 200
      ) {
        console.error(
          'Non 200 response when updating SNS endpoint',
          configEndpointResponse
        );
        throw thunkApi.rejectWithValue(JSON.stringify(configEndpointResponse));
      }

      return { token: new_fcm_token, endpointARN, nextAction };
    }

    return { token: new_fcm_token, endpointARN, nextAction };
  }
);

export const closeDeniedBanner = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('notifications/close_denied_banner', async () => {
  return;
});

export const openDeniedBannerAgain = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('notifications/open_denied_banner_again', async () => {
  return;
});

export const updateStaticTopics = createAsyncThunk<
  { staticTopics: Array<StaticTopicType> },
  void,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('notifications/update_static_topics', async (_, thunkApi) => {
  // Before creating SNS endpoint we get the list of static
  // topics and store their ARNs
  const topicsResponse = await thunkApi.dispatch(
    CDAlertasApi.endpoints.getStaticTopics.initiate({})
  );
  const topicsData = topicsResponse?.data?.data ?? ([] as Array<object>);

  const staticTopics = topicsData.map(
    (topic: { arn: string; regionCut: string; alertStatus: string }) => {
      return {
        arn: topic.arn,
        regionCut: topic.regionCut,
        alertStatus: topic.alertStatus,
      };
    }
  );

  return { staticTopics };
});

export interface AlertLike {
  sinapredId?: string;
  regions: { data: [{ cut_region: string }] };
}

/**
 * Compares received list of alerts with notification settings
 * and add individual alerts to favorites when necessary.
 *
 * This is required to maintain configuration consistency when subscribing
 * to specific regions and types. Indeed, upon subscription to a region-type
 * all then-active alerts are added to favorites, however when new alerts
 * are received either via push notifications or from API calls, this invariance
 * must be maintained.
 *
 * @param {data: AlertLike[]} alertData - List of alerts retrieved from Condor API.
 * @returns {string[]} - List of sinapredIds that were added to favorites.
 */
export const updateIndividualAlertsFromData = createAsyncThunk<
  string[],
  { data?: AlertLike[] },
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('notifications/update_individual_alerts', async (alertData, thunkApi) => {
  const { notifications, favorites } = thunkApi.getState();
  const { regionsByType } = notifications;

  const subscribedRegionsRoja: Record<RegionCode, boolean> = Object.fromEntries(
    regionCodes
      .map((rc) => [rc, regionsByType[alert_type_roja_str][rc]])
      .filter((e) => e[1] === true)
  );

  const subscribedRegionsAmarilla: Record<RegionCode, boolean> =
    Object.fromEntries(
      regionCodes
        .map((rc) => [rc, regionsByType[alert_type_amarilla_str][rc]])
        .filter((e) => e[1] === true)
    );

  const subscribedRegionsPreventiva: Record<RegionCode, boolean> =
    Object.fromEntries(
      regionCodes
        .map((rc) => [rc, regionsByType[alert_type_preventiva_str][rc]])
        .filter((e) => e[1] === true)
    );

  const allSubscribedRegions: Record<RegionCode, boolean> = {
    ...subscribedRegionsRoja,
    ...subscribedRegionsAmarilla,
    ...subscribedRegionsPreventiva,
  };

  const alertSinapredIdsToFollow = [];

  const followedAlerts: Record<string, boolean> = Object.fromEntries(
    favorites.currentFavorites.map((f) => [f, true])
  );

  for (const alert of alertData?.data || []) {
    const alertSinapredId = alert?.sinapredId;

    if (alertSinapredId && !(alertSinapredId in followedAlerts)) {
      const alertCutCodes = (alert?.regions?.data || []).map(
        (r: { cut_region: string }) => r.cut_region
      );

      for (const cutCode of alertCutCodes) {
        const regionCode: RegionCode = cutCodesToRegion[cutCode];
        if (regionCode in allSubscribedRegions) {
          alertSinapredIdsToFollow.push(alertSinapredId);
        }
      }
    }
  }

  // Mark all alert ids as favorites by dispatching to the current store
  if (alertSinapredIdsToFollow.length > 0) {
    await thunkApi.dispatch(addCardToFavoritesBulk(alertSinapredIdsToFollow));
  }

  return alertSinapredIdsToFollow;
});

export const appendTestNotificationData = createAsyncThunk<
  any,
  any,
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('notifications/append_test_notification_data', async (data: any, _) => {
  return data;
});

export const updateNotificationsRegionTypeSettings = createAsyncThunk<
  Record<AlertType, Record<RegionCode, boolean>>,
  Record<AlertType, Record<RegionCode, boolean>>,
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
>(
  'notifications/update_notifications_regions_type_settings',
  async (data: Record<AlertType, Record<RegionCode, boolean>>, _) => {
    return data;
  }
);

export const setCurrentNotificationPermissions = createAsyncThunk<
  [PermissionState, PermissionState?],
  [PermissionState, PermissionState?],
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('notifications/set_current_notification_permissions', async (permissions) => {
  return permissions;
});

export const isNotificationsInitializingNative = createAsyncThunk<
  any,
  any,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(
  'notifications/is_notifications_initializing_native',
  async (is: boolean, _) => {
    return is;
  }
);

export const isNotificationsInitializingWebpush = createAsyncThunk<
  any,
  any,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(
  'notifications/is_notifications_initializing_webpush',
  async (is: boolean, _) => {
    return is;
  }
);
