import './HealthCheck.css';

import React from 'react';

import { HealthCheckComponent } from '../components';

const HealthCheck: React.FC = () => {
  return (
    <>
      <HealthCheckComponent />
    </>
  );
};

export default HealthCheck;
