import { IonIcon } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import React from 'react';

const BottomAlertsBlock: React.FC<any> = ({
  id,
  sinapredId,
  status,
  level,
  goToAlertDetails,
  alertsFilteredLocations,
}) => {
  return (
    <div
      className="bottom-alerts-item"
      style={{
        backgroundColor: 'var(--itrend--blueGray-50)',
      }}
      onClick={() => goToAlertDetails(id)}
    >
      <div
        style={{
          width: '22px',
          height: '22px',
          borderRadius: level === 'Comunal' ? '50%' : '0',
          margin: '0 20px',
          border: '2px solid var(--itrend--white)',
          boxSizing: 'border-box',
        }}
        className={`bottom-alerts-item${
          status === 'Roja'
            ? '-roja'
            : status === 'Amarilla'
            ? '-amarilla'
            : '-temprana-preventiva'
        }`}
      ></div>

      <div
        style={{
          flex: '1',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <p
          className="alert-button-caption"
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {level}
          {': '}
          {alertsFilteredLocations.join(', ')}
        </p>
        <p key={id} className="alert-button-subcaption">
          {status}
          {' ID '}
          {sinapredId}
        </p>
      </div>

      <IonIcon
        icon={chevronForwardOutline}
        style={{
          height: '20px',
          width: '20px',
          margin: '0 20px',
          color: 'var(--itrend--gray-700)',
        }}
        aria-label={
          'Ver alerta regional ' +
          status +
          ' ' +
          sinapredId +
          ' de ' +
          alertsFilteredLocations.join(', ')
        }
      />
    </div>
  );
};

export default BottomAlertsBlock;
