/* eslint-disable @typescript-eslint/no-explicit-any */
import * as _ from 'underscore';

import { CDAlertasApi } from '../services/CDAlertasApi';

const SNS_SUBSCRIBE_CHUNK_SIZE = 20;

export const snsSubscribeTopicChunked = async ({
  topicARN,
  endpoint,
  filters,
  thunkApi,
}: {
  topicARN?: string[];
  endpoint?: string;
  filters?: any;
  thunkApi: any;
}) => {
  const chunkResponses: any[] = [];
  const response: { data: { created: any[]; failed: any[] }; error?: any[] } = {
    data: {
      created: [],
      failed: [],
    },
  };

  const arnChunks = _.chunk(topicARN || [], SNS_SUBSCRIBE_CHUNK_SIZE);
  for (const arns of arnChunks) {
    const resp: any = await thunkApi.dispatch(
      CDAlertasApi.endpoints.snsSubscribeTopic.initiate(
        filters
          ? {
              topicARN: arns,
              endpoint,
              filters,
            }
          : {
              topicARN: arns,
              endpoint,
            }
      )
    );
    chunkResponses.push(resp);
  }

  for (const chunkResponse of chunkResponses) {
    if ('data' in chunkResponse) {
      if ('created' in chunkResponse['data']) {
        response['data']['created'] = {
          ...response['data']['created'],
          ...chunkResponse['data']['created'],
        };
      }
      if ('failed' in chunkResponse['data']) {
        response['data']['failed'] = {
          ...response['data']['failed'],
          ...chunkResponse['data']['failed'],
        };
      }
    }
    if ('error' in chunkResponse) {
      response['error'] = [
        ...(response['error'] || []),
        chunkResponse['error'],
      ];
    }
  }

  return response;
};
