/* eslint-disable no-constant-condition */
import { IonContent } from '@ionic/react';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { regionPrefix, regions as regionsConst } from '../constants';
import FullPageLoader from './loaders/FullPageLoader';
import { sendActivityToRum } from '../App';

const MapModal: React.FC<{
  mapUrl: any;
  title: string;
  subTitle: any;
  len: number;
  active: any;
}> = ({ mapUrl, title, subTitle, len, active }) => {
  const { showBoundary } = useErrorBoundary();

  const mapDivRef = useRef<any>(null);
  const mapDivHeaderRef = useRef<any>(null);
  const mapImg = new Image();
  mapImg.src = mapUrl;
  const [isLoadingOpen, setIsLoadingOpen] = useState(true);

  const drawMap = () => {
    if (mapDivRef.current) {
      mapDivRef.current.src = mapImg.src;
      mapDivRef.current.style.display = 'block';
      setIsLoadingOpen(false);
    }
  };

  useLayoutEffect(() => {
    mapImg.onload = () => {
      drawMap();

      sendActivityToRum('map_svg', 'view');
    };

    mapImg.onerror = () => {
      showBoundary('Error al cargar el mapa');
    };
  }, []);

  return (
    <>
      <IonContent
        fullscreen
        className={`ion-content-custom-background`}
        style={{
          marginTop: 'var(--space-2)',
          marginBottom: 'var(--space-2)',
        }}
      >
        {isLoadingOpen ? <FullPageLoader /> : null}
        <div
          className={`alert-details-content-block${!active ? '-inactive' : ''}`}
          style={{
            maxHeight: '100%',
            width: '100%',
            display: isLoadingOpen ? 'none' : 'flex',
            flexDirection: 'column',

            marginTop: 'var(--space-2)',
            height: 'calc(100% - var(--space-2) - var(--space-2))',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            ref={mapDivHeaderRef}
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
              margin: 'var(--space-2)',
              marginTop: '0',
              borderBottom: '1px solid var(--itrend--blueGray-100)',
            }}
          >
            <h1
              style={{
                marginTop: '0',
                fontWeight: '700',
                fontSize: 'var(--headline-small)',
                color: 'var(--itrend--gray-800)',
              }}
            >
              Alerta {title.toLowerCase()}
            </h1>
            <p
              style={{
                marginTop: '0',
                fontWeight: '400',
                fontSize: 'var(--body-small)',
                color: 'var(--itrend--gray-500)',
              }}
            >
              <span style={{ fontWeight: '700' }}>
                {len === 1 ? 'En región ' : 'En regiones'}{' '}
              </span>
              {subTitle}
            </p>
          </div>

          {/* {regionPrefix[regionId as keyof typeof regionPrefix]} */}

          <div
            id="map-container"
            style={{
              height: '80%',
              width: '100%',
            }}
          >
            <img
              ref={mapDivRef}
              src={''}
              style={{
                display: 'none',
                objectFit: 'contain',
                width: '100%',
                height: '100%',
              }}
              alt={'Mapa de alerta ' + title.toLowerCase() + ' ' + subTitle}
            />
          </div>
        </div>
      </IonContent>
    </>
  );
};

export default MapModal;
