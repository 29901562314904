import { IonCardTitle, IonImg } from '@ionic/react';
import React from 'react';
import 'swiper/css';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import './SuscripcionesTutorialCard.scss';

const slide = (text: string, image: string) => {
  return (
    <div className={'tutorial-card'}>
      {/* <IonCardHeader> */}
      <IonCardTitle>{text}</IonCardTitle>
      {/* </IonCardHeader> */}
      {/* <IonCardContent> */}
      <IonImg src={'assets/images/' + image} />
      {/* </IonCardContent> */}
    </div>
  );
};

const SuscripcionesTutorialCard: React.FC = () => {
  return (
    <>
      <Swiper
        spaceBetween={10}
        slidesPerView={1.2}
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination, Autoplay, EffectFade]}
      >
        <SwiperSlide>
          {slide(
            'Puedes marcar las alertas para verlas en esta sección',
            'tut-favs.svg'
          )}
        </SwiperSlide>
        <SwiperSlide>
          {slide(
            'Puedes recibir notificaciones de los cambios de estado de las alertas suscritas',
            'tut-follow.svg'
          )}
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default SuscripcionesTutorialCard;
