import { sortByValues } from '../../constants';
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CLEAR_FILTERS_CANCELADAS,
  CLEAR_FILTERS_VIGENTES,
  SET_FILTER_BY_LEVEL_CANCELADAS,
  SET_FILTER_BY_LEVEL_VIGENTES,
  SET_FILTER_BY_REGION_CANCELADAS,
  SET_FILTER_BY_REGION_VIGENTES,
  SET_FILTER_BY_TYPE_CANCELADAS,
  SET_FILTER_BY_TYPE_VIGENTES,
  SET_SORT_BY_CANCELADAS,
  SET_SORT_BY_SUSCRIPCIONES,
  SET_SORT_BY_VIGENTES,
} from './settingsTypes';

export interface SettingsState {
  filterByRegionCanceladas: string[];
  filterByRegionVigentes: string[];
  filterByTypeCanceladas: string[];
  filterByTypeVigentes: string[];
  filterByLevelCanceladas: string[];
  filterByLevelVigentes: string[];
  sortByCanceladas: string;
  sortBySuscripciones: string;
  sortByVigentes: string;
}

const initialState: SettingsState = {
  filterByRegionCanceladas: [],
  filterByRegionVigentes: [],
  filterByTypeCanceladas: [],
  filterByTypeVigentes: [],
  filterByLevelCanceladas: [],
  filterByLevelVigentes: [],
  sortByCanceladas: sortByValues[0],
  sortBySuscripciones: sortByValues[0],
  sortByVigentes: sortByValues[0],
};

export default function settingsReducer(state = initialState, action: any) {
  switch (action.type) {
    case CLEAR_FILTERS_CANCELADAS:
      return {
        ...state,
        filterByTypeCanceladas: [],
        filterByLevelCanceladas: [],
        filterByRegionCanceladas: [],
      };

    case CLEAR_FILTERS_VIGENTES:
      return {
        ...state,
        filterByTypeVigentes: [],
        filterByLevelVigentes: [],
        filterByRegionVigentes: [],
      };

    case SET_FILTER_BY_REGION_CANCELADAS:
      return {
        ...state,
        filterByRegionCanceladas: action.payload,
      };

    case SET_FILTER_BY_REGION_VIGENTES:
      return {
        ...state,
        filterByRegionVigentes: action.payload,
      };

    case SET_FILTER_BY_TYPE_CANCELADAS:
      return {
        ...state,
        filterByTypeCanceladas: action.payload,
      };

    case SET_FILTER_BY_TYPE_VIGENTES:
      return {
        ...state,
        filterByTypeVigentes: action.payload,
      };

    case SET_FILTER_BY_LEVEL_CANCELADAS:
      return {
        ...state,
        filterByLevelCanceladas: action.payload,
      };

    case SET_FILTER_BY_LEVEL_VIGENTES:
      return {
        ...state,
        filterByLevelVigentes: action.payload,
      };

    case SET_SORT_BY_CANCELADAS:
      return {
        ...state,
        sortByCanceladas: action.payload,
      };

    case SET_SORT_BY_SUSCRIPCIONES:
      return {
        ...state,
        sortBySuscripciones: action.payload,
      };

    case SET_SORT_BY_VIGENTES:
      return {
        ...state,
        sortByVigentes: action.payload,
      };

    default:
      return state;
  }
}
