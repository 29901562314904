import './Types.scss';

import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import React from 'react';
import { ROUTES, makeGoBackUrl } from '../../constants';
import {
  AlertType,
  alertTypes,
  regionCodes,
} from '../../features/notifications/notifications';
import { useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { useSwipeable } from 'react-swipeable';
import { swipeConfig, maxPixelsNumberFromEdge } from '../../constants';

const Types: React.FC = () => {
  const { regionsByType } = useAppSelector(
    (state: RootState) => state.notifications
  );

  const alertType2name: Record<AlertType, string> = {
    alerta_roja: 'Alerta Roja',
    alerta_amarilla: 'Alerta Amarilla',
    alerta_temprana_preventiva: 'Alerta Temprana Preventiva',
  };

  const alertTypeRegionLink: Record<AlertType, string> = {
    alerta_roja: `${ROUTES.settings.path}/types/roja`,
    alerta_amarilla: `${ROUTES.settings.path}/types/amarilla`,
    alerta_temprana_preventiva: `${ROUTES.settings.path}/types/temprana`,
  };

  const router = useIonRouter();
  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventData) => {
      if (eventData.initial[0] > maxPixelsNumberFromEdge) {
        return;
      }

      router.push(makeGoBackUrl(window.location, 1));
    },
    ...swipeConfig,
  });

  return (
    <IonPage {...swipeHandlers}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={makeGoBackUrl(window.location, 1)} />
          </IonButtons>
          <IonTitle>Tipo de alerta por región</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-content-custom-background">
        <IonTitle className="ion-margin-bottom ion-margin-top types-title">
          Recibir notificaciones de
        </IonTitle>

        <div className="types-content-block">
          {alertTypes &&
            alertTypes.map((a: AlertType) => {
              const regionsOn = regionCodes.filter(
                (x) => regionsByType[a][x]
              ).length;
              return (
                <IonItem
                  lines="full"
                  key={a}
                  detail={true}
                  routerLink={alertTypeRegionLink[a]}
                >
                  <IonLabel>
                    <h3>{alertType2name[a]}</h3>
                    {regionsOn > 0 && (
                      <p>
                        En {regionsOn} {regionsOn > 1 ? 'regiones' : 'región'}
                      </p>
                    )}
                    {regionsOn == 0 && <p>En ninguna región</p>}
                  </IonLabel>
                </IonItem>
              );
            })}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Types;
