import { IonAccordion, IonItem, IonLabel, IonSkeletonText } from '@ionic/react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
// import { notPrefferedResourcesIds } from '../constants';
import { CDAlertasApi } from '../services/CDAlertasApi';
import './Resources.scss';

const Resources: React.FC<{
  resourcesNew: any;
  resources: any;
  eventsDetails: any;
  showInAlertDetails?: boolean;
}> = ({
  eventsDetails,
  resourcesNew,
  resources,
  showInAlertDetails = true,
}) => {
  const [
    resourcesGroupedByNamesGroupedByEventsData,
    setResourcesGroupedByNamesGroupedByEventsData,
  ] = useState([] as any);

  const {
    data: prefferedResourcesData,
    isLoading: prefferedResourcesIsLoading,
    isFetching: prefferedResourcesIsFetching,
    isSuccess: prefferedResourcesIsSuccess,
    isError: prefferedResourcesIsError,
    error: prefferedResourcesError,
  } = CDAlertasApi.useGetResourcesQuery(
    {
      filter: 'preferred',
      value: 1,
    },
    { skip: !eventsDetails }
  );

  const {
    data: otrosResourcesData,
    isLoading: otrosResourcesIsLoading,
    isFetching: otrosResourcesIsFetching,
    isSuccess: otrosResourcesIsSuccess,
    isError: otrosResourcesIsError,
    error: otrosResourcesError,
  } = CDAlertasApi.useGetResourcesQuery(
    {
      filter: 'code',
      value: 'otros,trayecto',
    },
    { skip: !eventsDetails }
  );

  const ids =
    prefferedResourcesData &&
    prefferedResourcesData.data
      .map((el: any) => +el.id)
      .sort((a: any, b: any) => {
        if (
          prefferedResourcesData.data.find((el: any) => +el.id === a).order >
          prefferedResourcesData.data.find((el: any) => +el.id === b).order
        ) {
          return 1;
        } else {
          return -1;
        }
      });

  const notPrefferedResourcesIds = otrosResourcesData && [
    +otrosResourcesData.data.find((el: any) => el.code === 'otros').id,
    +otrosResourcesData.data.find((el: any) => el.code === 'trayecto').id,
  ];

  const idsToSortData = prefferedResourcesData &&
    otrosResourcesData && [...ids, ...notPrefferedResourcesIds];

  // unique resources to group them by
  const resourcesGrouped =
    prefferedResourcesData &&
    otrosResourcesData &&
    resourcesNew &&
    resourcesNew
      .map((el: any) => {
        if (ids.includes(el.resource_id)) {
          return {
            ...el,
            typeData: {
              ...el.typedata,
              name: prefferedResourcesData.data.find(
                (e: any) => +e.id === el.resource_id
              ).name,
              singularName: prefferedResourcesData.data.find(
                (e: any) => +e.id === el.resource_id
              ).singularName,
            },
          };
        } else if (el.resource_id === notPrefferedResourcesIds[1]) {
          return {
            ...el,
            typeData: {
              ...el.typeData,
              name: otrosResourcesData.data.find(
                (e: any) => +e.id === el.resource_id
              ).name,
              singularName: otrosResourcesData.data.find(
                (e: any) => +e.id === el.resource_id
              ).singularName,
            },
          };
        } else {
          return {
            ...el,
            resource_id: notPrefferedResourcesIds[0],
          };
        }
      })
      .filter((value: any, index: any, array: any) => {
        return (
          array.map((el: any) => el.resource_id).indexOf(value.resource_id) ===
          index
        );
      });

  const resourcesGroupedByNames =
    resourcesGrouped &&
    resourcesGrouped.map((el: any) => {
      return {
        resource_id: el.resource_id,
        name:
          ids.includes(el.resource_id) ||
          el.resource_id === notPrefferedResourcesIds[1]
            ? el.typeData.name
            : otrosResourcesData.data[0].name,
        singularName:
          ids.includes(el.resource_id) ||
          el.resource_id === notPrefferedResourcesIds[1]
            ? el.typeData.singularName
            : otrosResourcesData.data[0].singularName,
        resources: resourcesNew
          .map((el: any) => {
            if (
              ids.includes(el.resource_id) ||
              el.resource_id === notPrefferedResourcesIds[1]
            ) {
              return el;
            } else {
              return {
                ...el,
                resource_id: notPrefferedResourcesIds[0],
                typeData: {
                  ...el.typeData,
                  name: otrosResourcesData.data[0].name,
                  singularName: otrosResourcesData.data[0].singularName,
                },
              };
            }
          })
          .filter((r: any) => r.resource_id === el.resource_id),
      };
    });

  const resourcesGroupedByNamesGroupedByEvents =
    resourcesGroupedByNames &&
    resourcesGroupedByNames
      .map((el: any) => {
        return {
          ...el,
          resourcesByEvents: eventsDetails?.map((e: any) => {
            return {
              event_id: e.id,
              eventName: e.name,
              eventSingularName: e.singularName,
              eventStartTime: e.startTime,
              resources: el.resources.filter((r: any) => {
                return r.eventData.event_id.toString() === e.id;
              }),
            };
          }),
        };
      })
      .sort((a: any, b: any) => {
        if (
          idsToSortData.indexOf(a.resource_id) >
          idsToSortData.indexOf(b.resource_id)
        ) {
          return 1;
        } else {
          return -1;
        }
      });

  useEffect(() => {
    if (
      resources &&
      resources.length &&
      resourcesGroupedByNamesGroupedByEvents &&
      !resourcesGroupedByNamesGroupedByEventsData.length
    ) {
      resourcesGroupedByNamesGroupedByEvents.forEach((el: any) => {
        if (el.resources[0].cuantification) {
          el.cuantification = el.resources.reduce(
            (acc: any, curr: any) => acc + curr.cuantification,
            0
          );
        } else {
          el.cuantification = el.resources.length;
        }
        el.resourcesByEvents.forEach((e: any) => {
          if (e.resources.length > 0 && e.resources[0].cuantification) {
            e.cuantification = e.resources.reduce(
              (acc: any, curr: any) => acc + curr.cuantification,
              0
            );
          } else {
            e.cuantification = e.resources.length;
          }
        });
      });

      setResourcesGroupedByNamesGroupedByEventsData(
        resourcesGroupedByNamesGroupedByEvents
      );
    }
  }, [resourcesGroupedByNamesGroupedByEvents]);

  return prefferedResourcesIsLoading ||
    prefferedResourcesIsFetching ||
    otrosResourcesIsLoading ||
    otrosResourcesIsFetching ? (
    <IonAccordion key={'accordion-skeleton'} className="resources-accordion">
      <IonItem slot="header" className="resources-header-item">
        <IonLabel className="resources-header">
          <IonSkeletonText animated style={{ width: '100%' }} />
        </IonLabel>
      </IonItem>
    </IonAccordion>
  ) : (
    resourcesGroupedByNamesGroupedByEventsData &&
      resourcesGroupedByNamesGroupedByEventsData.map((el: any, ind: any) => {
        return (
          <IonAccordion
            key={'accordion-' + el.resource_id}
            value={el.resource_id}
            className="resources-accordion"
          >
            <IonItem
              slot="header"
              className="resources-header-item"
              style={{
                borderBottom:
                  ind === resourcesGroupedByNamesGroupedByEventsData.length - 1
                    ? 'none'
                    : '1px solid var(--itrend--blueGray-100)',
              }}
            >
              <IonLabel className="resources-header">
                {notPrefferedResourcesIds.includes(el.resource_id)
                  ? ''
                  : el.cuantification}{' '}
                {el.cuantification > 1 ? el.name : el.singularName}
              </IonLabel>
            </IonItem>

            {el.resourcesByEvents.map((el2: any) => {
              return el2.resources.length ? (
                <div
                  className="ion-padding"
                  slot="content"
                  key={'accordion-' + el.resource_id + '-' + el2.event_id}
                  style={{
                    paddingTop: '0',
                    paddingBottom: '0',
                  }}
                >
                  {showInAlertDetails && (
                    <p className="resources-subheader">
                      {'Evento '}
                      {el2.eventName}
                      {', '}

                      {format(new Date(el2.eventStartTime), "dd ' de ' LLLL", {
                        locale: es,
                      })}
                    </p>
                  )}

                  <ul>
                    {el2.resources.map((el3: any, ind: any) => {
                      return (
                        <li
                          key={
                            'list-' +
                            el.resource_id +
                            '-' +
                            el2.event_id +
                            '-' +
                            ind
                          }
                          className="resources-text"
                        >
                          {el3.cuantification ? el3.cuantification + ' ' : ''}
                          {el3.primaryAttribute}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ''
              );
            })}
          </IonAccordion>
        );
      })
  );
};

export default Resources;
