export const CLEAR_FILTERS_CANCELADAS = 'CLEAR_FILTERS_CANCELADAS';
export const CLEAR_FILTERS_VIGENTES = 'CLEAR_FILTERS_VIGENTES';

export const SET_FILTER_BY_REGION_CANCELADAS =
  'SET_FILTER_BY_REGION_CANCELADAS';
export const SET_FILTER_BY_REGION_VIGENTES = 'SET_FILTER_BY_REGION_VIGENTES';

export const SET_FILTER_BY_TYPE_CANCELADAS = 'SET_FILTER_BY_TYPE_CANCELADAS';
export const SET_FILTER_BY_TYPE_VIGENTES = 'SET_FILTER_BY_TYPE_VIGENTES';

export const SET_FILTER_BY_LEVEL_CANCELADAS = 'SET_FILTER_BY_LEVEL_CANCELADAS';
export const SET_FILTER_BY_LEVEL_VIGENTES = 'SET_FILTER_BY_LEVEL_VIGENTES';

export const SET_SORT_BY_CANCELADAS = 'SET_SORT_BY_CANCELADAS';
export const SET_SORT_BY_SUSCRIPCIONES = 'SET_SORT_BY_SUSCRIPCIONES';
export const SET_SORT_BY_VIGENTES = 'SET_SORT_BY_VIGENTES';
