import { IonButton, IonIcon, IonRippleEffect } from '@ionic/react';
// import {
// chevronForwardOutline,
// play,
// triangle,
// alertCircle,
// } from 'ionicons/icons';
import { chevronForwardOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import { AlertSymbolsLegendModal } from '../../components';
import { ROUTES, regions } from '../../constants';
import {
  setFilterByLevelVigentes,
  setFilterByRegionVigentes,
  setFilterByTypeVigentes,
} from '../../features/settings/settingsActions';
import infoIcon from '../../theme/icons/info-2.svg';
import regionalAlertEmpty from '../../theme/icons/regional-alert-empty.svg';
import regionalAlertGreen from '../../theme/icons/regional-alert-green.svg';
import regionalAlertRed from '../../theme/icons/regional-alert-red.svg';
import regionalAlertYellow from '../../theme/icons/regional-alert-yellow.svg';
import activeAlert from '../../theme/icons/tabs-active.svg';
import FullPageLoader from '../loaders/FullPageLoader';
import './HomeWidgetResumen.scss';

const regionalAlertsIcons = {
  'temprana-preventiva': regionalAlertGreen,
  amarilla: regionalAlertYellow,
  roja: regionalAlertRed,
};

const HomeAlertsListHeader: React.FC<any> = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 20px',
        borderBottom: '1px solid var(--itrend--blueGray-100)',
        position: 'sticky',
        top: '0',
        backgroundColor: 'var(--itrend--white)',
        zIndex: 11,
      }}
    >
      <p
        style={{
          fontWeight: 'var(--font-weight-bold)',
          fontSize: 'var(--body-medium)',
        }}
      >
        Regiones con alertas vigentes
      </p>
      <IonButton
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        }}
        className="ion-activatable"
        id="open-legend-modal"
      >
        <p
          style={{
            fontWeight: 'var(--font-weight-regular)',
            fontSize: 'var(--body-small)',
            color: 'var(--itrend--secondary-500)',
          }}
        >
          Simbología
        </p>
        <IonIcon
          src={infoIcon}
          style={{
            width: '15px',
            height: '15px',
            marginLeft: 'var(--space-2)',
            // color: 'var(--itrend--secondary-500)',
          }}
          aria-label="Ver simbología de alertas"
        ></IonIcon>
        <IonRippleEffect></IonRippleEffect>
      </IonButton>
    </div>
  );
};

const HomeAlertsListFooter: React.FC<{ count: any }> = ({ count }) => {
  return (
    <p
      style={{
        // textAlign: 'center',
        color: 'var(--itrend--gray-500)',
        fontSize: 'var(--body-small)',
        fontWeight: 'var(--font-weight-regular)',
        marginLeft: '20px',
      }}
    >
      {`Total de alertas vigentes: ${count ? count : 'cargando...'}`}
    </p>
  );
};

const HomeAlertsListEmpty: React.FC<any> = () => {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: 'transparent',
        color: 'var(--itrend--gray-500)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '20%',
      }}
    >
      <IonIcon
        src={activeAlert}
        size="32px"
        style={{
          width: '32px',
          height: '32px',
        }}
      ></IonIcon>
      <p
        style={{
          fontWeight: 'var(--font-weight-bold)',
          fontSize: '28px',
          marginBottom: '0',
        }}
      >
        ¡Todo en orden!
      </p>
      <p
        style={{
          fontWeight: 'var(--font-weight-regular)',
          fontSize: 'var(--body-medium)',
        }}
      >
        No hay alertas vigentes.
      </p>
    </div>
  );
};

const HomeWidgetResumen: React.FC<any> = ({
  alertData,
  length,
  isLoading,
  isFetching,
  isSuccess,
  isHomeLegendModalOpen,
  setIsHomeLegendModalOpen,
}) => {
  const routerHistory = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const [regionsWithCounts, setRegionsWithCounts] = useState<any>();

  const getHighestLevel = (region: any, type: string) => {
    if (region['active' + type + 'RedAlerts'].meta.count > 0) {
      return 'roja';
    }
    if (region['active' + type + 'YellowAlerts'].meta.count > 0) {
      return 'amarilla';
    }
    if (region['active' + type + 'GreenAlerts'].meta.count > 0) {
      return 'temprana-preventiva';
    }

    return null;
  };

  const goToVigentesPage = (type: any, level: any, region: any) => {
    dispatch(setFilterByTypeVigentes(type));
    dispatch(setFilterByLevelVigentes(level));
    dispatch(setFilterByRegionVigentes(region));
    routerHistory.push(ROUTES.activeAlerts.path);
  };

  const checkIfEmpty = (region: any) => {
    if (
      getHighestLevel(region, 'Regional') ||
      getHighestLevel(region, 'Provincial') ||
      getHighestLevel(region, 'Comunal')
    ) {
      return false;
    }

    return true;
  };

  const modal = useRef<HTMLIonModalElement>(null);
  const [isLoadingOpen, setIsLoadingOpen] = useState(true);
  function dismiss() {
    modal.current?.dismiss();
  }

  return (
    <>
      {(isLoading || isFetching || !isSuccess) && <FullPageLoader />}

      {!isLoading &&
        !isFetching &&
        isSuccess &&
        alertData &&
        (alertData.filter((el: any) => !checkIfEmpty(el)).length > 0 || (
          <HomeAlertsListEmpty />
        ))}

      {!isLoading &&
        !isFetching &&
        isSuccess &&
        alertData &&
        alertData.filter((el: any) => !checkIfEmpty(el)).length > 0 && (
          <div className="map-container">
            <div className="map-container map-container-grid">
              <>
                {alertData.filter((el: any) => !checkIfEmpty(el)).length >
                  0 && <HomeAlertsListHeader />}

                {alertData.map(
                  (region: any) =>
                    !checkIfEmpty(region) && (
                      <div
                        key={region.cutRegion}
                        className="map-container-grid-row ion-activatable"
                        onClick={() => {
                          if (
                            getHighestLevel(region, 'Regional') ||
                            !checkIfEmpty(region)
                          ) {
                            goToVigentesPage([], [], [region.labelShort]);
                          }
                        }}
                      >
                        <div className="map-container-grid-col">
                          <div className="map-regions-list">
                            <div
                              className={`map-regions-list-label${
                                getHighestLevel(region, 'Regional')
                                  ? ' map-regions-list-label-active ' +
                                    getHighestLevel(region, 'Regional')
                                  : ''
                              }${
                                checkIfEmpty(region)
                                  ? ' map-regions-list-label-inactive '
                                  : ''
                              }`}
                            >
                              <p>{region.labelShort}</p>
                            </div>
                          </div>

                          <div className="map-counts-container">
                            <div className="map-provincies-counts">
                              {!getHighestLevel(region, 'Regional') ? (
                                <IonIcon
                                  src={regionalAlertEmpty}
                                  style={{
                                    width: '22px',
                                    height: '22px',
                                    marginRight: '5px',
                                  }}
                                  aria-label={
                                    region.labelShort +
                                    ' sin alertas regionales'
                                  }
                                ></IonIcon>
                              ) : (
                                <IonIcon
                                  src={
                                    regionalAlertsIcons[
                                      getHighestLevel(
                                        region,
                                        'Regional'
                                      ) as keyof typeof regionalAlertsIcons
                                    ]
                                  }
                                  style={{
                                    width: '22px',
                                    height: '22px',
                                    marginRight: '5px',
                                  }}
                                  aria-label={
                                    region.labelShort +
                                    ' con alertas regionales'
                                  }
                                ></IonIcon>
                              )}

                              <div
                                className={`map-provincies-counts-block${
                                  region.activeProvincialRedAlerts.meta.count >
                                  0
                                    ? ' active'
                                    : ''
                                }`}
                              >
                                {region.activeProvincialRedAlerts.meta.count >
                                  0 &&
                                  region.activeProvincialRedAlerts.meta.count}
                              </div>
                              <div
                                className={`map-provincies-counts-block${
                                  region.activeProvincialYellowAlerts.meta
                                    .count > 0
                                    ? ' active'
                                    : ''
                                }`}
                              >
                                {region.activeProvincialYellowAlerts.meta
                                  .count > 0 &&
                                  region.activeProvincialYellowAlerts.meta
                                    .count}
                              </div>
                              <div
                                className={`map-provincies-counts-block${
                                  region.activeProvincialGreenAlerts.meta
                                    .count > 0
                                    ? ' active'
                                    : ''
                                }`}
                              >
                                {region.activeProvincialGreenAlerts.meta.count >
                                  0 &&
                                  region.activeProvincialGreenAlerts.meta.count}
                              </div>
                              <div
                                className={`map-comunes-counts-block map-comunes-counts-block-circle${
                                  region.activeComunalRedAlerts.meta.count > 0
                                    ? ' active'
                                    : ''
                                }`}
                              >
                                {region.activeComunalRedAlerts.meta.count > 0 &&
                                  region.activeComunalRedAlerts.meta.count}
                              </div>
                              <div
                                className={`map-comunes-counts-block map-comunes-counts-block-circle${
                                  region.activeComunalYellowAlerts.meta.count >
                                  0
                                    ? ' active'
                                    : ''
                                }`}
                              >
                                {region.activeComunalYellowAlerts.meta.count >
                                  0 &&
                                  region.activeComunalYellowAlerts.meta.count}
                              </div>
                              <div
                                className={`map-comunes-counts-block map-comunes-counts-block-circle${
                                  region.activeComunalGreenAlerts.meta.count > 0
                                    ? ' active'
                                    : ''
                                }`}
                              >
                                {region.activeComunalGreenAlerts.meta.count >
                                  0 &&
                                  region.activeComunalGreenAlerts.meta.count}
                              </div>

                              <IonIcon
                                icon={chevronForwardOutline}
                                size="24px"
                                style={{
                                  color: '#79848B',
                                }}
                                aria-label={
                                  'Ver alertas vigentes de ' + region.labelShort
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {!checkIfEmpty(region) && (
                          <IonRippleEffect></IonRippleEffect>
                        )}
                      </div>
                    )
                )}
                {alertData.filter((el: any) => !checkIfEmpty(el)).length >
                  0 && <HomeAlertsListFooter count={length} />}
              </>
            </div>

            <AlertSymbolsLegendModal
              isOpen={isHomeLegendModalOpen}
              setIsOpen={setIsHomeLegendModalOpen}
              withCounts={true}
              trigger="open-legend-modal"
            />
          </div>
        )}
    </>
  );
};

export default HomeWidgetResumen;
