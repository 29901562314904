/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import region01 from './region01.json.zip';
// @ts-ignore
import region02 from './region02.json.zip';
// @ts-ignore
import region03 from './region03.json.zip';
// @ts-ignore
import region04 from './region04.json.zip';
// @ts-ignore
import region05 from './region05.json.zip';
// @ts-ignore
import region06 from './region06.json.zip';
// @ts-ignore
import region07 from './region07.json.zip';
// @ts-ignore
import region08 from './region08.json.zip';
// @ts-ignore
import region09 from './region09.json.zip';
// @ts-ignore
import region10 from './region10.json.zip';
// @ts-ignore
import region11 from './region11.json.zip';
// @ts-ignore
import region12 from './region12.json.zip';
// @ts-ignore
import region13 from './region13.json.zip';
// @ts-ignore
import region14 from './region14.json.zip';
// @ts-ignore
import region15 from './region15.json.zip';
// @ts-ignore
import region16 from './region16.json.zip';

export const regionsGeoJsons = {
  '01': region01,
  '02': region02,
  '03': region03,
  '04': region04,
  '05': region05,
  '06': region06,
  '07': region07,
  '08': region08,
  '09': region09,
  '10': region10,
  '11': region11,
  '12': region12,
  '13': region13,
  '14': region14,
  '15': region15,
  '16': region16,
};
