import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonText,
} from '@ionic/react';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { sortByValues } from '../constants';
import { SettingsState } from '../features/settings/settings';
import { sendActivityToRum } from '../App';

const OrderByModal: React.FC<{
  page: string;
  sortBy: string;
  setSortBy: (
    value: string
  ) => (dispatch: ThunkDispatch<SettingsState, void, Action>) => void;
}> = ({ page, sortBy, setSortBy }) => {
  const modal = useRef<HTMLIonModalElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: Dispatch<any> = useDispatch();

  const setSortByF = (sortBy: { value: string }) => {
    dispatch(setSortBy(sortBy.value));
    sendActivityToRum('sort_order', 'use');
    modal.current?.dismiss();
  };

  return (
    <IonModal
      ref={modal}
      trigger={page + '-order-by-trigger'}
      initialBreakpoint={0.3}
      breakpoints={[0, 0.3]}
      // initialBreakpoint={page === 'Vigentes' ? 0.25 : 0.3}
      // breakpoints={page === 'Vigentes' ? [0, 0.25] : [0, 0.3]}
    >
      <IonContent className="ion-padding">
        <IonList>
          <IonListHeader className="ion-no-margin ion-padding-horizontal">
            <IonText
              style={{ fontWeight: 700, fontSize: '18px', lineHeight: '21px' }}
            >
              Ordenar por
            </IonText>
          </IonListHeader>
          {/* ionchange here  */}
          <IonRadioGroup
            value={sortBy}
            onIonChange={(e) => setSortByF(e.detail)}
          >
            {sortByValues.map((sortByValue: any, index: any) => {
              if (
                // page === 'Vigentes' &&
                sortByValue === 'Mayor tiempo en vigencia'
              )
                return null;
              return (
                <IonItem key={index}>
                  <IonLabel className="body-medium-medium">
                    {sortByValue}
                  </IonLabel>
                  <IonRadio slot="start" value={sortByValue}></IonRadio>
                </IonItem>
              );
            })}
          </IonRadioGroup>
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default OrderByModal;
