import './NotificationSettings.scss';

import { repeatOutline } from 'ionicons/icons';
import React from 'react';
import bookmarkActive from '../theme/icons/bookmark-active.svg';
import bookmark from '../theme/icons/bookmark.svg';
import help from '../theme/icons/help.svg';

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import { ROUTES } from '../constants';

import {
  alert_type_amarilla_str,
  alert_type_preventiva_str,
  alert_type_roja_str,
  AlertLifecycle,
  RegionCode,
} from '../features/notifications/notifications';
import {
  disableNotifyFollowing,
  enableNotifyFollowing,
} from '../features/notifications/notificationsActions';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store';
import { Capacitor } from '@capacitor/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const NotificationSettings: React.FC<{ refMenu: any }> = () => {
  const dispatch = useAppDispatch();
  const {
    regionsByType,
    lifecycle,
    notifyFollowing,
    isNotificationsUpdating,
    isNotificationsInitializingNative,
    isNotificationsInitializingWebpush,
  } = useAppSelector((state: RootState) => state.notifications);

  const isNative = Capacitor.isNativePlatform();

  const regionsAlertTypeRoja = Object.keys(
    regionsByType[alert_type_roja_str]
  ).filter((k) => regionsByType[alert_type_roja_str][k as RegionCode]).length;

  const regionsAlertTypeAmarilla = Object.keys(
    regionsByType[alert_type_amarilla_str]
  ).filter(
    (k) => regionsByType[alert_type_amarilla_str][k as RegionCode]
  ).length;

  const regionsAlertTypePreventiva = Object.keys(
    regionsByType[alert_type_preventiva_str]
  ).filter(
    (k) => regionsByType[alert_type_preventiva_str][k as RegionCode]
  ).length;

  const lifecycleAmount = Object.keys(lifecycle).filter((l: string) => {
    return (
      lifecycle[l as AlertLifecycle] === true &&
      l !== 'alert_type_asc' &&
      l !== 'alert_type_desc'
    );
  }).length;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle
            style={{
              paddingLeft: '0px',
            }}
          >
            Configuración de Suscripciones
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-content-custom-background">
        <IonTitle className="ion-margin-bottom ion-margin-top settings-title">
          Suscripciones automáticas
        </IonTitle>

        <div className="settings-content-block">
          <IonItem
            lines="full"
            routerLink={`${ROUTES.settings.path}/types`}
            detail={true}
            disabled={
              (isNative && isNotificationsInitializingNative) ||
              (!isNative && isNotificationsInitializingWebpush)
            }
          >
            <IonIcon
              src={bookmark}
              slot="start"
              size="large"
              aria-label="Tipos de alerta por región"
            ></IonIcon>
            <IonLabel>
              <h2>Tipos de alerta por región</h2>
              {(isNative && isNotificationsInitializingNative) ||
              (!isNative && isNotificationsInitializingWebpush) ? (
                <p>Cargando datos sobre tus suscripciones...</p>
              ) : (
                <>
                  {regionsAlertTypeRoja > 0 && regionsAlertTypeAmarilla > 0 && (
                    <p>
                      Roja en {regionsAlertTypeRoja}{' '}
                      {regionsAlertTypeRoja > 1 ? 'regiones' : 'región'},
                      Amarilla en {regionsAlertTypeAmarilla}{' '}
                      {regionsAlertTypeAmarilla > 1 ? 'regiones' : 'región'}
                    </p>
                  )}

                  {regionsAlertTypeRoja > 0 &&
                    regionsAlertTypeAmarilla == 0 && (
                      <p>
                        Roja en {regionsAlertTypeRoja}{' '}
                        {regionsAlertTypeRoja > 1 ? 'regiones' : 'región'}
                      </p>
                    )}

                  {regionsAlertTypeRoja == 0 &&
                    regionsAlertTypeAmarilla > 0 && (
                      <p>
                        Amarilla en {regionsAlertTypeAmarilla}{' '}
                        {regionsAlertTypeAmarilla > 1 ? 'regiones' : 'región'}
                      </p>
                    )}

                  {regionsAlertTypePreventiva > 0 && (
                    <p>
                      Preventiva en {regionsAlertTypePreventiva}{' '}
                      {regionsAlertTypePreventiva > 1 ? 'regiones' : 'región'}
                    </p>
                  )}

                  {regionsAlertTypeRoja == 0 &&
                    regionsAlertTypeAmarilla == 0 &&
                    regionsAlertTypePreventiva == 0 && <p>En ninguna región</p>}
                </>
              )}
            </IonLabel>
          </IonItem>
        </div>

        <IonTitle className="ion-margin-bottom ion-margin-top settings-title">
          Notificaciones
        </IonTitle>

        <div className="settings-content-block">
          <IonItem lines="full">
            <IonIcon
              src={bookmarkActive}
              slot="start"
              size="large"
              aria-label="Notificar alertas suscritas"
            ></IonIcon>
            <IonLabel>
              <h2>Notificar alertas suscritas</h2>
              <p style={{ whiteSpace: 'normal' }}>
                Envía una notificación de los cambios de estado de las alertas
                en la sección “Suscripciones”
              </p>
            </IonLabel>
            {isNotificationsUpdating && (
              <IonSpinner
                className="alert-card-buttons-fav fav-spinner"
                style={{
                  width: 'var(--headline-medium)',
                  heigth: 'var(--headline-medium)',
                }}
                name="circular"
              ></IonSpinner>
            )}
            {!isNotificationsUpdating && (
              <IonToggle
                slot="end"
                disabled={isNotificationsUpdating}
                checked={notifyFollowing}
                onIonChange={async (evt) => {
                  if (evt.detail.checked) {
                    await dispatch(enableNotifyFollowing());
                  } else {
                    await dispatch(disableNotifyFollowing());
                  }
                }}
              ></IonToggle>
            )}
          </IonItem>
          <IonItem
            lines="full"
            routerLink={`${ROUTES.settings.path}/lifecycle`}
            detail={true}
          >
            <IonIcon
              icon={repeatOutline}
              slot="start"
              size="large"
              aria-label="Ciclo de vida de la alerta"
            ></IonIcon>
            <IonLabel>
              <h2>Ciclo de vida de la alerta</h2>
              <p>
                {lifecycleAmount === 0
                  ? 'Ninguna'
                  : `${lifecycleAmount} tipos de cambios de estado`}{' '}
              </p>
            </IonLabel>
          </IonItem>

          <IonItem
            lines="full"
            routerLink={`${ROUTES.settings.path}/guide`}
            detail={true}
          >
            <IonIcon
              icon={help}
              slot="start"
              size="medium"
              aria-label="Cómo activar las notificaciones"
            ></IonIcon>
            <IonLabel>
              <h2>Cómo activar las notificaciones</h2>
            </IonLabel>
          </IonItem>
        </div>
        {/* <div>
          <IonItem routerLink={`${ROUTES.healthCheckNotifications.path}`}>
            <IonLabel>Healthcheck notificaciones</IonLabel>
          </IonItem>
        </div> */}
      </IonContent>
    </IonPage>
  );
};

export default NotificationSettings;
