import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { SettingsState } from './settings';
import {
  CLEAR_FILTERS_CANCELADAS,
  CLEAR_FILTERS_VIGENTES,
  SET_FILTER_BY_LEVEL_CANCELADAS,
  SET_FILTER_BY_LEVEL_VIGENTES,
  SET_FILTER_BY_REGION_CANCELADAS,
  SET_FILTER_BY_REGION_VIGENTES,
  SET_FILTER_BY_TYPE_CANCELADAS,
  SET_FILTER_BY_TYPE_VIGENTES,
  SET_SORT_BY_CANCELADAS,
  SET_SORT_BY_SUSCRIPCIONES,
  SET_SORT_BY_VIGENTES,
} from './settingsTypes';

export const setFilterByLevelVigentes =
  (value: string[]) =>
  (dispatch: ThunkDispatch<SettingsState, void, Action>) => {
    dispatch({
      type: SET_FILTER_BY_LEVEL_VIGENTES,
      payload: value,
    });
  };

export const setFilterByLevelCanceladas =
  (value: string[]) =>
  (dispatch: ThunkDispatch<SettingsState, void, Action>) => {
    dispatch({
      type: SET_FILTER_BY_LEVEL_CANCELADAS,
      payload: value,
    });
  };

export const setFilterByTypeVigentes =
  (value: string[]) =>
  (dispatch: ThunkDispatch<SettingsState, void, Action>) => {
    dispatch({
      type: SET_FILTER_BY_TYPE_VIGENTES,
      payload: value,
    });
  };

export const setFilterByTypeCanceladas =
  (value: string[]) =>
  (dispatch: ThunkDispatch<SettingsState, void, Action>) => {
    dispatch({
      type: SET_FILTER_BY_TYPE_CANCELADAS,
      payload: value,
    });
  };

export const setFilterByRegionVigentes =
  (value: string[]) =>
  (dispatch: ThunkDispatch<SettingsState, void, Action>) => {
    dispatch({
      type: SET_FILTER_BY_REGION_VIGENTES,
      payload: value,
    });
  };

export const setFilterByRegionCanceladas =
  (value: string[]) =>
  (dispatch: ThunkDispatch<SettingsState, void, Action>) => {
    dispatch({
      type: SET_FILTER_BY_REGION_CANCELADAS,
      payload: value,
    });
  };

export const clearFiltersVigentes =
  () => (dispatch: ThunkDispatch<SettingsState, void, Action>) => {
    dispatch({
      type: CLEAR_FILTERS_VIGENTES,
    });
  };

export const clearFiltersCanceladas =
  () => (dispatch: ThunkDispatch<SettingsState, void, Action>) => {
    dispatch({
      type: CLEAR_FILTERS_CANCELADAS,
    });
  };

export const setSortByVigentes =
  (value: string) => (dispatch: ThunkDispatch<SettingsState, void, Action>) => {
    dispatch({
      type: SET_SORT_BY_VIGENTES,
      payload: value,
    });
  };

export const setSortBySuscripciones =
  (value: string) => (dispatch: ThunkDispatch<SettingsState, void, Action>) => {
    dispatch({
      type: SET_SORT_BY_SUSCRIPCIONES,
      payload: value,
    });
  };

export const setSortByCanceladas =
  (value: string) => (dispatch: ThunkDispatch<SettingsState, void, Action>) => {
    dispatch({
      type: SET_SORT_BY_CANCELADAS,
      payload: value,
    });
  };
