import { Mutex } from 'async-mutex';
import { BaseQueryApi, retry } from '@reduxjs/toolkit/query';
import {
  logoutUser,
  tokenReceived,
  TokenRefreshData,
} from '../features/user/userActions';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { errorsRoutes, ROUTES } from '../constants';

export const defaultPrepareHeadersAuth = async (headers: Headers) => {
  const idTokenData = await Auth.currentSession();
  const idToken = idTokenData.getIdToken().getJwtToken();

  headers.set('Authorization', `Bearer ${idToken}`);
};

export const BACKEND_API_BASE_PATH =
  process.env.REACT_APP_BACKEND_API_BASE_PATH || '';

export let BACKEND_BASE_URL = `${process.env.REACT_APP_BACKEND_PROTOCOL}://`;
BACKEND_BASE_URL += `${process.env.REACT_APP_BACKEND_URL}`;

export const API_BASE_URL = `${BACKEND_BASE_URL}/${BACKEND_API_BASE_PATH}`;

const refreshBaseQuery = async () => {
  const idTokenData = await Auth.currentSession();

  return idTokenData;
};

const mutex = new Mutex();

const checkRefreshResult = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refreshResult: any
): refreshResult is { data: TokenRefreshData } => {
  return (
    !!refreshResult &&
    typeof refreshResult === 'object' &&
    'data' in refreshResult &&
    'access' in refreshResult['data']
  );
};

export const makeBaseQueryWithReauthAndRetry = (
  targetQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
  maxRetries = 3
) => {
  return retry(makeBaseQueryWithReauth(targetQuery), {
    maxRetries: maxRetries,
  });
};

export const makeBaseQueryWithReauth = (
  targetQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>
) => {
  return async (
    args: string | FetchArgs,
    api: BaseQueryApi,
    // eslint-disable-next-line @typescript-eslint/ban-types
    extraOptions: {}
  ) => {
    const result = await targetQuery(args, api, extraOptions);

    if (
      result.error
      //   &&
      //   (result.error?.status === 401 ||
      //     result.error?.status === 403 ||
      //     result.error?.status === 'PARSING_ERROR')
    ) {
      const error =
        result.error.status === 'PARSING_ERROR'
          ? (result.error as any).originalStatus
          : result.error.status;

      if (Object.keys(errorsRoutes).includes(error.toString())) {
        window.location.href = `/tabs/${error}`;
      } else {
        window.location.href = ROUTES.error.path;
      }
    }

    return result;
  };
};

export const globalApiTimeout =
  process.env.REACT_APP_GLOBAL_API_TIMEOUT || '30000';
