import React from 'react';
import './GlobalLoader.scss';
import { useAppSelector } from '../hooks';
import { RootState } from '../store';

const GlobalLoader: React.FC = () => {
  const {
    isNotificationsInitializingNative,
    isNotificationsInitializingWebpush,
  } = useAppSelector((state: RootState) => state.notifications);

  return isNotificationsInitializingNative ||
    isNotificationsInitializingWebpush ? (
    <>
      <div className="global-loader-container">
        <div className="global-loader"></div>
      </div>
    </>
  ) : null;
};

export default GlobalLoader;
