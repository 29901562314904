import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, defaultPrepareHeadersAuth, globalApiTimeout } from './common';

const makeTestNotificationPayload = (topicARN: string, testId: string) => ({
  topicARN,
  subject: `Condor test notification ${testId} on topic ${topicARN}`,
  message: {
    default: `Test notification ${testId} on topic ${topicARN}`,
    GCM: {
      notification: {
        title: `Test notification ${testId} on topic ${topicARN}`,
        body: `Sent at ${Date.now()}`,
      },
      data: {
        title: `Test notification ${testId} on topic ${topicARN}`,
        body: `Sent at ${Date.now()}`,
        url_suffix: `/tabs/shared/alerts/${testId}`,
        click_action: 'http://localhost:5000',
        icon: 'itrend.png',
        image: 'firebase-logo2.png',
        isTestAlert: 'true',
        alertId: testId,
        alertSinapredId: '0',
        sourceTopicARN: topicARN,
      },
    },
  },
  attributes: {
    minRegion: 1,
    alert_id: testId,
    coverage_level: 0,
    action_type: 'Declara',
    regions_cut: ['01'],
  },
});

export const CDTestNotificationsApi = createApi({
  reducerPath: 'CDNotificationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: defaultPrepareHeadersAuth,
    timeout: parseInt(globalApiTimeout),
  }),
  endpoints: (builder) => ({
    requestTestPushNotificationWithId: builder.mutation({
      query: ({ topicARN, testId }: { topicARN: string; testId: string }) => ({
        url: '/notification/test_push_notification',
        method: 'POST',
        body: makeTestNotificationPayload(topicARN, testId),
      }),
      extraOptions: { maxRetries: 0 },
    }),
  }),
});
