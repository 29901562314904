import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { APIsToHealthCheck, ROUTES } from '../constants';
import { CDAlertasApi } from '../services/CDAlertasApi';
import './HealthCheck.scss';

const HealthCheckComponent: React.FC = () => {
  const [checkedAPis, setCheckedAPis] = useState(
    APIsToHealthCheck.map((el: any) => ({
      ...el,
      status: 'checking...',
    }))
  );

  useEffect(() => {
    Promise.all(
      APIsToHealthCheck.map((el: any) =>
        checkApi(
          el.name,
          el.url +
            el.API +
            (el.testParams.length === 1
              ? '?' + el.testParams[0]
              : el.testParams.length > 1
              ? '?' + el.testParams.join('&')
              : '')
        )
      )
    );
  }, []);

  const checkApi = async (name: string, url: string) => {
    try {
      const res = await axios.options(url);

      setCheckedAPis((prev) => {
        const newCheckedApis = [...prev];
        const index = newCheckedApis.findIndex((el) => el.name === name);
        newCheckedApis[index].status =
          res.status.toString() === newCheckedApis[index].OkResponse
            ? 'success'
            : 'error';
        return newCheckedApis;
      });
    } catch (error) {
      setCheckedAPis((prev) => {
        const newCheckedApis = [...prev];
        const index = newCheckedApis.findIndex((el) => el.name === name);
        newCheckedApis[index].status = 'error';
        return newCheckedApis;
      });
    }
  };

  const {
    data: regionsData,
    isLoading: isRegionsLoading,
    isFetching: isRegionsFetching,
    isSuccess: isRegionsSuccess,
    isError: isRegionsError,
    error: regionsError,
  } = CDAlertasApi.useGetAllRegionsQuery({});

  const regsNew =
    regionsData &&
    [...regionsData.data]
      .sort((a: any, b: any) => {
        return b.order > a.order ? -1 : 1;
      })
      .map((el: any) => {
        return {
          [el.label]: el.cutRegion,
        };
      });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="ion-padding">Health Checking Page</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent
        scrollEvents={true}
        fullscreen
        className="ion-content-custom-background"
        style={{
          boxSizing: 'border-box',
        }}
      >
        <div
          className="home-content-block"
          style={{
            height: 'calc(100% - var(--space-2))',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              fontWeight: 'var(--font-weight-bold)',
              color: 'var(--itrend--primary-500)',
              fontSize: 'var(--headline-large)',
              textAlign: 'center',
            }}
          >
            {/* {ROUTES[code as keyof typeof ROUTES].header} */}
          </p>

          {/* {ROUTES[code as keyof typeof ROUTES].message} */}
          {checkedAPis.map((el: any) => (
            <p
              key={el.name}
              style={{
                fontSize: 'var(--body-large)',
                textAlign: 'center',
                color:
                  el.status === 'success'
                    ? 'green'
                    : el.status === 'error'
                    ? 'red'
                    : 'black',
              }}
            >
              {el.name}
              {' : '} {el.status}
            </p>
          ))}

          <IonButton
            fill="outline"
            style={{
              marginTop: 'var(--space-4)',
            }}
            href={ROUTES.home.path}
          >
            Regresar al inicio
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default HealthCheckComponent;
