import './About.scss';
import React, { useState, useEffect } from 'react';
import {
  IonMenuButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { iframeDataPostProcessing } from '../constants';
import { arrowBackOutline } from 'ionicons/icons';

const About: React.FC = () => {
  const protectedLocation = window.location.href.includes('/tabs/');

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {protectedLocation ? (
              <IonMenuButton></IonMenuButton>
            ) : (
              <IonButton href="/">
                <IonIcon
                  style={{
                    color: 'var(--itrend--white)',
                    fontSize: '24px',
                  }}
                  icon={arrowBackOutline}
                  aria-label="Ir atrás"
                ></IonIcon>
              </IonButton>
            )}
          </IonButtons>
          <IonTitle
            style={{
              padding: '0',
              fontWeight: 'var(--font-weight-regular)',
              fontSize: 'var(--body-large)',
              color: 'var(--itrend--white)',
            }}
          >
            Acerca de
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        className={`ion-content-custom-background event-details-container ${
          !protectedLocation ? 'not-protected-content' : ''
        }`}
      >
        <div className="iframe-container">
          <iframe
            onLoad={(ev) => iframeDataPostProcessing(ev)}
            src="docs/acercade/index.html"
            id="acercade-iframe"
          ></iframe>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default About;
