import { deserialise } from 'kitsu-core';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  API_BASE_URL,
  defaultPrepareHeadersAuth,
  globalApiTimeout,
  makeBaseQueryWithReauthAndRetry,
} from './common';

const jsonApiDeserialiserTransform = (
  baseQueryReturnValue: unknown,
  _meta: unknown,
  _arg: unknown
) => {
  // ! NOTE: The 'deserialize' function mutates its parameter,
  // ! which is why we create a copy of the original return value
  // ! in case it is needed for debugging purposes.
  const baseCopy = { ...(baseQueryReturnValue as object) };
  const deserializedData = deserialise(baseCopy);
  return deserializedData;
};

export const API_ALERTS_PAGE_SIZE = parseInt(
  process.env.REACT_APP_API_ALERTS_PAGE_SIZE || '10'
);

const alertsCount =
  process.env.REACT_APP_MAX_ALERTS_NUMBER_IN_RESPONSE || '100';

export const NOTIFICATIONS_PLATFORM_KEY =
  process.env.REACT_APP_NOTIFICATIONS_PLATFORM_KEY || 'ms_notification-fcm';

export const CDAlertasApi = createApi({
  reducerPath: 'CDAlertasApi',
  baseQuery: makeBaseQueryWithReauthAndRetry(
    fetchBaseQuery({
      baseUrl: API_BASE_URL,
      prepareHeaders: defaultPrepareHeadersAuth,
      timeout: parseInt(globalApiTimeout),
    })
  ),
  tagTypes: [
    'Alert',
    'AlertUpdate',
    'EventDetail',
    'Topic',
    'Resources',
    'Sae',
    'Regions',
  ],
  endpoints: (builder) => ({
    getAllAlerts: builder.query({
      query: ({ filter, pageNumber = 1, pageSize = API_ALERTS_PAGE_SIZE }) =>
        `/alerts?include=regions,lastUpdate,lastUpdate.locations,lastUpdate.events${
          filter ? '&' + filter : ''
        }&page[number]=${pageNumber}&page[size]=${pageSize}`,
      providesTags: ['Alert'],
      transformResponse: jsonApiDeserialiserTransform,
      extraOptions: { maxRetries: 1 },
    }),

    getAllAlertsInRegion: builder.query({
      query: ({ region }: { region: string }) =>
        `/alerts?include=lastUpdate,regions,comunes,provinces&filter[isActive]=1&filter[lastUpdate][locations][region][cutRegion]=${region}&sort=-updatedAt`,
      providesTags: ['Alert'],
      transformResponse: jsonApiDeserialiserTransform,
      extraOptions: { maxRetries: 1 },
    }),

    getSaeMessages: builder.query({
      query: ({ region }: { region: string }) =>
        `/data/senapred_uat_sae?regionCut=${region}`,
      providesTags: ['Sae'],
      transformResponse: jsonApiDeserialiserTransform,
      extraOptions: { maxRetries: 1 },
    }),

    getAllRegions: builder.query({
      query: () => `/regions`,
      providesTags: ['Regions'],
      transformResponse: jsonApiDeserialiserTransform,
      extraOptions: { maxRetries: 1 },
    }),

    getAllRegionsSummaries: builder.query({
      query: () =>
        `/regions?withCount=activeAlerts,activeRedAlerts,activeYellowAlerts,activeGreenAlerts,activeComunalRedAlerts,activeProvincialRedAlerts,activeRegionalRedAlerts,activeNationalRedAlerts,activeComunalYellowAlerts,activeProvincialYellowAlerts,activeRegionalYellowAlerts,activeNationalYellowAlerts,activeComunalGreenAlerts,activeProvincialGreenAlerts,activeRegionalGreenAlerts,activeNationalGreenAlerts`,
      providesTags: ['Regions'],
      transformResponse: jsonApiDeserialiserTransform,
      extraOptions: { maxRetries: 1 },
    }),

    getAllAlertsNoPagination: builder.query({
      query: () =>
        `/alerts?include=regions,comunes&filter[isActive]=1&page[size]=${alertsCount}`,
      providesTags: ['Alert'],
      transformResponse: jsonApiDeserialiserTransform,
      extraOptions: { maxRetries: 1 },
    }),

    getAllCancelledAlertsWithIds: builder.query({
      query: ({ idsToCheck }: { idsToCheck: string[] }) => {
        const sinapredIds = idsToCheck.join(',');
        return `/alerts?filter[isActive]=0&filter[sinapredId]=${sinapredIds}`;
      },
      providesTags: ['Alert'],
      transformResponse: jsonApiDeserialiserTransform,
      extraOptions: { maxRetries: 1 },
    }),

    getAlertsWithIds: builder.query({
      query: ({ idsToCheck }: { idsToCheck: string[] }) => {
        const sinapredIds = idsToCheck.join(',');
        return `/alerts?filter[sinapredId]=${sinapredIds}`;
      },
      providesTags: ['Alert'],
      transformResponse: jsonApiDeserialiserTransform,
      extraOptions: { maxRetries: 1 },
    }),

    getAlertById: builder.query({
      query: (alertId) =>
        `/alerts/${alertId}?include=lastUpdate,lastUpdate.events,lastUpdate.events.lastIncident,lastUpdate.events.lastIncident.affectations,lastUpdate.events.lastIncident.forestFireIncidentResources`,
      transformResponse: jsonApiDeserialiserTransform,
      providesTags: ['Alert'],
    }),

    getAllAlertsByStatus: builder.query({
      query: () => '/alert-status?withCount=activeAlerts',
      transformResponse: jsonApiDeserialiserTransform,
      providesTags: ['Alert'],
    }),

    getAllAlertsByZone: builder.query({
      query: () => '/macrozones?withCount=activeAlerts',
      transformResponse: jsonApiDeserialiserTransform,
      providesTags: ['Alert'],
    }),

    getAllActiveAlertsByRegionAndType: builder.query({
      query: ({ cutRegion, alertType }) =>
        cutRegion
          ? `alerts?filter[isActive]=1&filter[lastUpdate][locations][region][cutRegion]=${cutRegion}&filter[lastUpdate][status][name]=${alertType}`
          : `alerts?filter[isActive]=1&filter[lastUpdate][status][name]=${alertType}`,
      transformResponse: jsonApiDeserialiserTransform,
      providesTags: ['Alert'],
    }),

    getResources: builder.query({
      query: ({ filter, value }) => `/resources?filter[${filter}]=${value}`,
      transformResponse: jsonApiDeserialiserTransform,
      providesTags: ['Resources'],
    }),

    getAllAlertLocations: builder.query({
      query: (id) => `/alert-updates/${id}/locations?include=region`,
      providesTags: ['Alert'],
      transformResponse: jsonApiDeserialiserTransform,
    }),

    getAlertEvents: builder.query({
      query: (alertId) => `/alerts/${alertId}?include=lastUpdate.events`,
      transformResponse: jsonApiDeserialiserTransform,
      providesTags: ['Alert'],
    }),

    getAlertEventsDetails: builder.query({
      query: (alertId) =>
        `/alerts/${alertId}/events?include=lastIncident.comunes,lastIncident.forestFireIncidentResources,eventable.incidents`,
      transformResponse: jsonApiDeserialiserTransform,
      providesTags: ['EventDetail'],
    }),

    getAlertUpdates: builder.query({
      query: (alertId) => `/alerts/${alertId}/updates`,
      transformResponse: jsonApiDeserialiserTransform,
      providesTags: ['AlertUpdate'],
    }),

    getEventById: builder.query({
      query: (eventId) =>
        `/events/${eventId}?include=lastIncident.comunes,lastIncident.affectations,lastIncident.forestFireIncidentResources,eventable.incidents`,
      transformResponse: jsonApiDeserialiserTransform,
      providesTags: ['EventDetail'],
    }),

    getNotificationPlatforms: builder.query({
      query: () => ({
        url: '/notification/list_platforms',
        method: 'POST',
      }),
      transformResponse: (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        baseQueryReturnValue: any,
        _meta: unknown,
        _arg: unknown
      ) => {
        return baseQueryReturnValue[NOTIFICATIONS_PLATFORM_KEY];
      },
      extraOptions: { maxRetries: 0 },
    }),

    snsCreateEndpoint: builder.mutation({
      query: ({
        token,
        platformApplicationARN,
        customUserData = {
          user_id: 'test',
          user_name: 'test',
        },
        overwrite = false,
      }) => ({
        url: '/notification/create_endpoint',
        method: 'POST',
        body: { token, platformApplicationARN, customUserData, overwrite },
      }),
      extraOptions: { maxRetries: 0 },
    }),

    snsGetEndpointStatus: builder.query({
      query: ({ endpointARN }) => ({
        url: '/notification/get_endpoint_status',
        method: endpointARN ? 'POST' : 'OPTIONS',
        body: {
          endpointARN,
        },
      }),
    }),

    snsConfigEndpoint: builder.mutation({
      query: ({ endpointARN, token, enabled = true, customUserData }) => ({
        url: '/notification/config_endpoint',
        method: 'POST',
        body: {
          endpointARN,
          token,
          enabled,
          customUserData,
        },
      }),
    }),

    snsSubscribeTopic: builder.mutation({
      query: ({ topicARN, endpoint, filters }) => ({
        url: '/notification/subscribe_topic ',
        method: 'POST',
        body: { topicARN, endpoint, filters },
      }),
      extraOptions: { maxRetries: 0 },
    }),

    snsUnsubscribeTopic: builder.mutation({
      query: ({ topicARN, endpoint }) => ({
        url: '/notification/unsubscribe_topic ',
        method: 'POST',
        body: { topicARN, endpoint },
      }),
      extraOptions: { maxRetries: 0 },
    }),

    snsGetSubscriptions: builder.query({
      query: ({ endpointARN }) => ({
        url: '/notification/get_subscriptions',
        method: 'POST',
        body: { endpointARN },
      }),
      extraOptions: { maxRetries: 0 },
    }),

    getStaticTopics: builder.query({
      query: () => '/topics?filter[type]=1&page[size]=50',
      transformResponse: jsonApiDeserialiserTransform,
      providesTags: ['Topic'],
    }),

    getTestTopics: builder.query({
      query: ({ n = 1 }) => ({
        url: '/notification/test_topics',
        method: 'POST',
        body: {
          n: n,
        },
      }),
      transformResponse: jsonApiDeserialiserTransform,
      providesTags: ['Topic'],
    }),
  }),
});
