import './Politica.scss';
import React, { useState, useEffect } from 'react';
import {
  IonMenuButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonButton,
} from '@ionic/react';
import { iframeDataPostProcessing } from '../constants';
import { arrowBackOutline } from 'ionicons/icons';

const Politica: React.FC = () => {
  // const iframe = document.getElementById('politica-iframe');

  // useEffect(() => {
  //   iframe?.addEventListener('load', (ev) => {
  //     iframeDataPostProcessing(ev, iframe);
  //   });
  // }, [iframe]);

  const protectedLocation = window.location.href.includes('/tabs/');

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {protectedLocation ? (
              <IonMenuButton></IonMenuButton>
            ) : (
              <IonButton href="/">
                <IonIcon
                  style={{
                    color: 'var(--itrend--white)',
                    fontSize: '24px',
                  }}
                  icon={arrowBackOutline}
                  aria-label="Ir atrás"
                ></IonIcon>
              </IonButton>
            )}{' '}
          </IonButtons>
          <IonTitle
            style={{
              padding: '0',
              fontWeight: 'var(--font-weight-regular)',
              fontSize: 'var(--body-large)',
              color: 'var(--itrend--white)',
            }}
          >
            Política de privacidad
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        className={`ion-content-custom-background event-details-container ${
          !protectedLocation ? 'not-protected-content' : ''
        }`}
      >
        <div className="iframe-container">
          <iframe
            onLoad={(ev) => iframeDataPostProcessing(ev)}
            src="docs/politica/index.html"
            id="politica-iframe"
          ></iframe>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Politica;
