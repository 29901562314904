/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ADD_ALERT_TO_LIST,
  CHANGE_DATE_TIME_OF_ALERT,
  REMOVE_ALERT_FROM_LIST,
} from './readNewInfoTypes';

export interface ReadNewInfoState {
  readNewInfoAlertList: any;
}

const initialState: ReadNewInfoState = {
  readNewInfoAlertList: {},
};

export default function readNewInfoReducer(state = initialState, action: any) {
  switch (action.type) {
    case ADD_ALERT_TO_LIST:
      return {
        ...state,
        readNewInfoAlertList: {
          ...state.readNewInfoAlertList,
          ...action.payload,
        },
      };

    case CHANGE_DATE_TIME_OF_ALERT:
      return {
        ...state,
        readNewInfoAlertList: action.payload,
      };

    case REMOVE_ALERT_FROM_LIST:
      return {
        ...state,
        readNewInfoAlertList: action.payload,
      };

    default:
      return state;
  }
}
