/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import JSZip from 'jszip';
import L from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import {
  attribution,
  initialMapCoords,
  mapGreenColor,
  mapRedColor,
  mapRegionBorderColorGreen,
  mapRegionBorderColorRed,
  mapRegionBorderColorYellow,
  mapRegionFillOpacity,
  mapRegionWeight,
  mapYellowColor,
  maxZoom,
  minZoom,
  regionsRings,
  tileLayer,
} from '../../constants';
import { regionsGeoJsons } from '../../constants/regions';
import {
  mapInitTimeOut,
  mapTooltipsInitTimeOut,
  ROUTES,
} from '../../constants';
import 'leaflet/dist/leaflet.css';
// @ts-ignore
import jsziputils from 'jszip-utils';
import { useHistory, useParams } from 'react-router-dom';

const MapRegional: React.FC<any> = ({
  notRegionalAlerts,
  redsComunal,
  yellowsComunal,
  greensComunal,
  redsProvincial,
  yellowsProvincial,
  greensProvincial,
  setChosenCommunaCode,
  chosenCommunaCode,
  regionalAlertColor,
  setIsMapLoaded,
  mapRef,
}) => {
  const geoJsonToAddRef = useRef<any>(null);

  const regionId =
    window.location.pathname.includes(ROUTES.region.path.split('/')[1]) &&
    window.location.pathname.split('/')[4];

  const createNewMap = () => {
    return new Promise(function (fulfilled, rejected) {
      const currentMapContainerId = 'map-regional-container-' + regionId;

      if (document.getElementById(currentMapContainerId)) {
        const container = L.DomUtil.get(currentMapContainerId);
        if (container != null) {
          /* @ts-ignore */
          container._leaflet_id = null;
        }

        const map = L.map('map-regional-container-' + regionId, {
          zoomSnap: 0.25,
        })
          .setView(initialMapCoords as any, minZoom)
          .whenReady(() => setIsMapLoaded(true));

        map.zoomControl.remove();

        L.tileLayer(tileLayer, {
          maxZoom: maxZoom,
          minZoom: minZoom,
          attribution: attribution,
        }).addTo(map);

        const elements = Array.from(
          document.getElementsByClassName('leaflet-attribution-flag')
        );
        elements.forEach((el: any) => el.remove());

        map.on('zoomend', function () {
          // hideOverlappingTooltips();

          setTimeout(() => {
            hideOverlappingTooltips(), mapTooltipsInitTimeOut;
          });
        });

        fulfilled(map);
      }
    });
  };

  function urlToPromise(url: any) {
    return new Promise(function (resolve, reject) {
      jsziputils.getBinaryContent(url, function (err: any, data: any) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  const setMapData = () => {
    geoJsonLayerRef.current.clearLayers();
    geoJsonLayerRef.current.addData(geoJsonToAddRef.current as any);

    geoJsonLayerRef.current.addTo(mapRef.current);
    mapRef.current.fitBounds(
      regionId === '05'
        ? (regionsRings[regionId.toString() as keyof object] as any)
        : geoJsonLayerRef.current.getBounds()
    );
    // map.on('moveend', () => {
    //   console.log(map.getBounds());
    //   console.log(map.getZoom());
    // });
    // }
  };

  const mapInit = () => {
    const mapInitInner = async () => {
      mapRef.current = await createNewMap().then((map) => map);
      const inputFile = await urlToPromise(
        regionsGeoJsons[regionId as keyof typeof regionsGeoJsons] as any
      ).then((inputFile) => inputFile);

      const zip = new JSZip();

      zip.loadAsync(inputFile as any).then(function (zip: any) {
        zip.files[`region${regionId}.json`]
          .async('text')
          .then(function (filedata: any) {
            geoJsonToAddRef.current = JSON.parse(filedata).features.map(
              (el: any) => {
                return {
                  ...el,
                  properties: {
                    ...el.properties,
                    R: notRegionalAlerts
                      .filter((el2: any) => {
                        return el2.comunes
                          .map((el3: any) => {
                            return el3.cutComune;
                          })
                          .includes(el.properties['it-geo:comuna_cut_code']);
                      })
                      .filter((el4: any) => {
                        return el4.lastUpdateData.status === 'Roja';
                      }).length,
                    Y: notRegionalAlerts
                      .filter((el2: any) => {
                        return el2.comunes
                          .map((el3: any) => {
                            return el3.cutComune;
                          })
                          .includes(el.properties['it-geo:comuna_cut_code']);
                      })
                      .filter((el4: any) => {
                        return el4.lastUpdateData.status === 'Amarilla';
                      }).length,
                    G: notRegionalAlerts
                      .filter((el2: any) => {
                        return el2.comunes
                          .map((el3: any) => {
                            return el3.cutComune;
                          })
                          .includes(el.properties['it-geo:comuna_cut_code']);
                      })
                      .filter((el4: any) => {
                        return (
                          el4.lastUpdateData.status === 'Temprana Preventiva'
                        );
                      }).length,

                    Rprov: notRegionalAlerts
                      .filter((el2: any) => {
                        return el2.comunes
                          .map((el3: any) => {
                            return el3.cutComune;
                          })
                          .includes(el.properties['it-geo:comuna_cut_code']);
                      })
                      .filter((el4: any) => {
                        return (
                          el4.lastUpdateData.status === 'Roja' &&
                          el4.lastUpdateData.level === 'Provincial'
                        );
                      }).length,
                    Yprov: notRegionalAlerts
                      .filter((el2: any) => {
                        return el2.comunes
                          .map((el3: any) => {
                            return el3.cutComune;
                          })
                          .includes(el.properties['it-geo:comuna_cut_code']);
                      })
                      .filter((el4: any) => {
                        return (
                          el4.lastUpdateData.status === 'Amarilla' &&
                          el4.lastUpdateData.level === 'Provincial'
                        );
                      }).length,
                    Gprov: notRegionalAlerts
                      .filter((el2: any) => {
                        return el2.comunes
                          .map((el3: any) => {
                            return el3.cutComune;
                          })
                          .includes(el.properties['it-geo:comuna_cut_code']);
                      })
                      .filter((el4: any) => {
                        return (
                          el4.lastUpdateData.status === 'Temprana Preventiva' &&
                          el4.lastUpdateData.level === 'Provincial'
                        );
                      }).length,
                  },
                };
              }
            );

            setMapData();
          });
      });
    };

    setTimeout(() => {
      mapInitInner();
    }, mapInitTimeOut);
  };

  const mapRemove = async () => {
    if (mapRef.current) {
      mapRef.current.off();
      mapRef.current.remove();
      mapRef.current = null;

      setIsMapLoaded(false);
      setChosenCommunaCode('');
    }
  };

  const [currentClickedComune, setCurrentClickedComune] = useState('');

  const chosenCommunaCodeRef = useRef('');

  let defFillColor = 'rgba(236, 239, 241, 1)';
  let defColor = 'rgba(168, 178, 185, 1)';

  switch (regionalAlertColor) {
    case 'Roja':
      defFillColor = mapRedColor;
      defColor = mapRegionBorderColorRed;
      break;
    case 'Amarilla':
      defFillColor = mapYellowColor;
      defColor = mapRegionBorderColorYellow;
      break;
    case 'Temprana Preventiva':
      defFillColor = mapGreenColor;
      defColor = mapRegionBorderColorGreen;
      break;
    default:
      break;
  }

  const geoJsonLayer = L.geoJSON([], {
    style: function (feature: any) {
      const layerStyles = {
        fillColor: defFillColor,
        color: 'rgba(168, 178, 185, 1)',
        weight: mapRegionWeight,
        fillOpacity: mapRegionFillOpacity,
      };

      if (!regionalAlertColor) {
        if (
          greensProvincial.includes(
            feature.properties['it-geo:provincia_cut_code']
          )
        ) {
          layerStyles.fillColor = mapGreenColor;
          layerStyles.color = mapRegionBorderColorGreen;
        }
        if (
          greensComunal.includes(feature.properties['it-geo:comuna_cut_code'])
        ) {
          layerStyles.fillColor = mapGreenColor;
          layerStyles.color = mapRegionBorderColorGreen;
        }
      }

      if (!regionalAlertColor || regionalAlertColor === 'Temprana Preventiva') {
        if (
          yellowsProvincial.includes(
            feature.properties['it-geo:provincia_cut_code']
          )
        ) {
          layerStyles.fillColor = mapYellowColor;
          layerStyles.color = mapRegionBorderColorYellow;
        }
        if (
          yellowsComunal.includes(feature.properties['it-geo:comuna_cut_code'])
        ) {
          layerStyles.fillColor = mapYellowColor;
          layerStyles.color = mapRegionBorderColorYellow;
        }
      }

      if (
        !regionalAlertColor ||
        regionalAlertColor === 'Temprana Preventiva' ||
        regionalAlertColor === 'Amarilla'
      ) {
        if (
          redsProvincial.includes(
            feature.properties['it-geo:provincia_cut_code']
          )
        ) {
          layerStyles.fillColor = mapRedColor;
          layerStyles.color = mapRegionBorderColorRed;
        }
        if (
          redsComunal.includes(feature.properties['it-geo:comuna_cut_code'])
        ) {
          layerStyles.fillColor = mapRedColor;
          layerStyles.color = mapRegionBorderColorRed;
        }
      }

      return { ...layerStyles };
    },

    onEachFeature: function (feature: any, layer: any) {
      layer.on('click', function (e: any) {
        if (!checkIfAnyAlerts(e.sourceTarget.feature.properties)) {
          return;
        }

        if (
          chosenCommunaCodeRef.current !==
          e.sourceTarget.feature.properties['it-geo:comuna_cut_code']
        ) {
          geoJsonLayer.resetStyle();

          layer.setStyle({
            fillOpacity: 1,
            weight: 3,
            // color: 'black',
            zIndex: 999,
          });

          chosenCommunaCodeRef.current =
            e.sourceTarget.feature.properties['it-geo:comuna_cut_code'];
          setChosenCommunaCode(
            e.sourceTarget.feature.properties['it-geo:comuna_cut_code']
          );
          // setCurrentClickedComune(
          //   e.sourceTarget.feature.properties['it-geo:comuna_cut_code']
          // );
        } else {
          geoJsonLayer.resetStyle();
          chosenCommunaCodeRef.current = '';
          setChosenCommunaCode('');
          // setCurrentClickedComune('');
        }
      });

      const { Rprov, Yprov, Gprov } = feature.properties;
      const Rcom = feature.properties.R - Rprov;
      const Ycom = feature.properties.Y - Yprov;
      const Gcom = feature.properties.G - Gprov;

      // layer.bindPopup(
      //   '<div>' +
      //     '<p style="font-weight: 700;">' +
      //     feature.properties['it-geo:comuna_label'] +
      //     '</p>' +
      //     (Rprov === 0 && Yprov === 0 && Gprov === 0
      //       ? ''
      //       : '<div style="display: flex;">' +
      //         (Rprov === 0
      //           ? '<div style="margin-right: 5px; border: 1px solid #C3CACF; border-radius: 4px; background-color: #ECEFF1; color: white; width: 20px; height: 20px; display: flex; justify-content: center; align-items: center;"></div>'
      //           : '<div style="margin-right: 5px; border-radius: 4px; background-color: #E22C2C; color: white; width: 20px; height: 20px; display: flex; justify-content: center; align-items: center;">' +
      //             Rprov +
      //             '</div>') +
      //         (Yprov === 0
      //           ? '<div style="margin-right: 5px; border: 1px solid #C3CACF; border-radius: 4px; background-color: #ECEFF1; color: white; width: 20px; height: 20px; display: flex; justify-content: center; align-items: center;"></div>'
      //           : '<div style="margin-right: 5px; border-radius: 4px; background-color: #EBBC46; color: #815025; width: 20px; height: 20px; display: flex; justify-content: center; align-items: center;">' +
      //             Yprov +
      //             '</div>') +
      //         (Gprov === 0
      //           ? '<div style="margin-right: 5px; border: 1px solid #C3CACF; border-radius: 4px; background-color: #ECEFF1; color: white; width: 20px; height: 20px; display: flex; justify-content: center; align-items: center;"></div>'
      //           : '<div style="border-radius: 4px; background-color: #30B677; color: white; width: 20px; height: 20px; display: flex; justify-content: center; align-items: center;">' +
      //             Gprov +
      //             '</div>') +
      //         '</div>') +
      //     (Rcom === 0 && Ycom === 0 && Gcom === 0
      //       ? ''
      //       : '<div style="display: flex; margin-top: 10px;">' +
      //         (Rcom === 0
      //           ? '<div style="margin-right: 5px; border: 1px solid #C3CACF; border-radius: 50%; background-color: #ECEFF1; color: white; width: 20px; height: 20px; display: flex; justify-content: center; align-items: center;"></div>'
      //           : '<div style="margin-right: 5px; border-radius: 50%; background-color: #E22C2C; color: white; width: 20px; height: 20px; display: flex; justify-content: center; align-items: center;">' +
      //             Rcom +
      //             '</div>') +
      //         (Ycom === 0
      //           ? '<div style="margin-right: 5px; border: 1px solid #C3CACF; border-radius: 50%; background-color: #ECEFF1; color: white; width: 20px; height: 20px; display: flex; justify-content: center; align-items: center;"></div>'
      //           : '<div style="margin-right: 5px; border-radius: 50%; background-color: #EBBC46; color: #815025; width: 20px; height: 20px; display: flex; justify-content: center; align-items: center;">' +
      //             Ycom +
      //             '</div>') +
      //         (Gcom === 0
      //           ? '<div style="margin-right: 5px; border: 1px solid #C3CACF; border-radius: 50%; background-color: #ECEFF1; color: white; width: 20px; height: 20px; display: flex; justify-content: center; align-items: center;"></div>'
      //           : '<div style="border-radius: 50%; background-color: #30B677; color: white; width: 20px; height: 20px; display: flex; justify-content: center; align-items: center;">' +
      //             Gcom +
      //             '</div>') +
      //         '</div>') +
      //     '</div>'
      // );
      layer
        .bindTooltip(feature.properties['it-geo:comuna_label'], {
          permanent: true,
          direction: 'center',
          backgroundColor: 'transparent',
          className: 'labelstyle map-region-tooltip',
        })
        .openTooltip();
    },
  });

  const checkIfAnyAlerts = (feature: any) => {
    const { Rprov, Yprov, Gprov, R, Y, G } = feature;

    if (G || Y || R || Gprov || Yprov || Rprov) {
      return true;
    }

    return false;
  };

  const geoJsonLayerRef = useRef(geoJsonLayer);

  function overlap(rect1: any, rect2: any) {
    return !(
      rect1.right < rect2.left ||
      rect1.left > rect2.right ||
      rect1.bottom < rect2.top ||
      rect1.top > rect2.bottom
    );
  }

  const tooltips = useRef<HTMLCollectionOf<HTMLElement>>();

  function hideOverlappingTooltips() {
    const rects = [];
    tooltips.current = document.getElementsByClassName(
      'map-region-tooltip'
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < tooltips.current.length; i++) {
      tooltips.current[i].style.visibility = '';
      rects[i] = tooltips.current[i].getBoundingClientRect();
    }
    for (let i = 0; i < tooltips.current.length; i++) {
      if (tooltips.current[i].style.visibility != 'hidden') {
        for (let j = i + 1; j < tooltips.current.length; j++) {
          if (overlap(rects[i], rects[j]))
            tooltips.current[j].style.visibility = 'hidden';
        }
      }
    }
  }

  useEffect(() => {
    mapInit();

    return () => {
      mapRemove();
    };
  }, []);

  return null;
};

export default MapRegional;
