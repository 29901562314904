/* eslint-disable @typescript-eslint/no-explicit-any */
import './HealthCheck.scss';

import React, { useEffect, useRef, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
// import { useOidcAccessToken } from '@axa-fr/react-oidc';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonLoading
} from '@ionic/react';

import { ROUTES } from '../constants';
import {
  SnsUpdateAction,
  updateSnsEndpoint
} from '../features/notifications/notificationsActions';
import { getPermissions, getToken, getTopicFromSubscription } from '../firebaseUtils';
import { useAppDispatch, useAppSelector } from '../hooks';
import { CDAlertasApi } from '../services/CDAlertasApi';
import { CDTestNotificationsApi } from '../services/CDNotificationsApi';
import { RootState } from '../store';

const testNotificationTimeout = parseInt(
  process.env.REACT_APP_TEST_NOTIFICATION_TIMEOUT || '20000'
);

const HealthCheckNotificationsComponent: React.FC = () => {
  const {
    firebaseOptions,
    vapid,
    isFirebaseInitialized,
    endpointARN,
    fcmToken,
    testNotificationsData,
  } = useAppSelector((state: RootState) => state.notifications);
  const { user } = useAuthenticator((context) => [context.user]);

  // * Timer reference and cleanup function
  const timerRef = useRef<any>(null);
  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const [requestTestPushNotification] =
    CDTestNotificationsApi.useRequestTestPushNotificationWithIdMutation();

  const [presentLoading, dismissLoading] = useIonLoading();
  const [didUnsubscribeAllTestFailed, setUnsubscribeAllTestFailed] =
    useState(false);
  const [didUnsubscribeAllFailed, setUnsubscribeAllFailed] = useState(false);
  const [tokenTestStatus, setTokenTestStatus] = useState<
    'success' | 'fail' | 'clear'
  >('clear');

  const [nextAction, setNextAction] = useState<SnsUpdateAction | undefined>();

  const dispatch = useAppDispatch();

  const { data: endpointStatusData, isLoading: isLoadingEndpointStatus } =
    CDAlertasApi.useSnsGetEndpointStatusQuery(
      { endpointARN },
      { skip: !endpointARN }
    );

  const {
    data: currentSubscriptionsData,
    refetch: refetchCurrentSubscriptions,
  } = CDAlertasApi.useSnsGetSubscriptionsQuery(
    { endpointARN },
    { skip: !endpointARN }
  );

  const [didTimeout, setDidTimeout] = useState<boolean | undefined>();
  const [didMatchExpected, setDidMatchExpected] = useState<
    boolean | 'timeout' | undefined
  >();
  const [expectedNotificationData, setExpectedNotificationData] = useState<{
    testId: string;
    sourceTopicARN: string;
  }>();

  useEffect(() => {
    clearTimeout(timerRef.current);
    if (expectedNotificationData) {
      const matchExpectedData =
        testNotificationsData.findIndex(
          (x: any) =>
            x['testId'] === expectedNotificationData['testId'] &&
            x['sourceTopicARN'] === expectedNotificationData['sourceTopicARN']
        ) >= 0;

      setDidMatchExpected(matchExpectedData);
    }
  }, [testNotificationsData]);

  const testSubscriptions =
    (currentSubscriptionsData &&
      'Subscriptions' in currentSubscriptionsData &&
      currentSubscriptionsData['Subscriptions'].filter((s: string) =>
        s.includes('__itrend_test')
      )) ||
    [];

  const nonTestSubscriptions =
    (currentSubscriptionsData &&
      'Subscriptions' in currentSubscriptionsData &&
      currentSubscriptionsData['Subscriptions'].filter(
        (s: string) => !s.includes('__itrend_test')
      )) ||
    [];

  const [snsSubscribeTopic] = CDAlertasApi.useSnsSubscribeTopicMutation();
  const [snsUnsubscribeTopic] = CDAlertasApi.useSnsUnsubscribeTopicMutation();

  const { data: testTopicsData } = CDAlertasApi.useGetTestTopicsQuery({ n: 5 });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="ion-padding">
            Notifications Health Checking Page
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent
        scrollEvents={true}
        fullscreen
        className="ion-content-custom-background"
        style={{
          boxSizing: 'border-box',
        }}
      >
        <div className="home-content-block">
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol>
                <IonTitle className="ion-no-padding">FCM Token</IonTitle>
                <IonGrid>
                  <IonRow>
                    {fcmToken && (
                      <IonCol>
                        <IonText
                          style={{
                            fontSize: 'normal',
                            textAlign: 'center',
                            color: 'green',
                          }}
                        >
                          Token Detected
                        </IonText>
                      </IonCol>
                    )}
                    {!fcmToken && (
                      <IonCol>
                        <IonText
                          style={{
                            fontSize: 'normal',
                            textAlign: 'center',
                            color: 'red',
                          }}
                        >
                          No token detected
                        </IonText>
                      </IonCol>
                    )}
                  </IonRow>
                  {fcmToken && (
                    <IonRow>
                      <IonCol>
                        <IonTextarea
                          autoGrow={true}
                          readonly={true}
                          style={{ fontSize: 'smaller' }}
                        >
                          {fcmToken}
                        </IonTextarea>
                      </IonCol>
                    </IonRow>
                  )}
                  <IonRow>
                    <IonCol>
                      <IonButton
                        onClick={async () => {
                          presentLoading();
                          setTokenTestStatus('clear');
                          setNextAction(undefined);

                          if (isFirebaseInitialized && firebaseOptions) {
                            const permissionsState = await getPermissions();

                            const registration =
                              'serviceWorker' in navigator
                                ? await navigator.serviceWorker.getRegistration()
                                : await Promise.resolve(null);

                            if (permissionsState === 'granted' && vapid) {
                              const token = await getToken(vapid, registration);
                              const user_id = user?.attributes?.sub;
                              const user_name = user?.username;

                              const { meta, payload } = await dispatch(
                                updateSnsEndpoint({
                                  new_fcm_token: token,
                                  user_id: user_id,
                                  user_name: user_name,
                                })
                              );

                              if (typeof payload === 'object') {
                                setNextAction(payload?.nextAction);
                              }
                              const requestStatus = meta?.requestStatus;

                              if (
                                !requestStatus ||
                                (requestStatus && requestStatus !== 'fulfilled')
                              ) {
                                setTokenTestStatus('fail');
                              } else if (
                                requestStatus &&
                                requestStatus === 'fulfilled'
                              ) {
                                setTokenTestStatus('success');
                              }
                            } else {
                              setTokenTestStatus('fail');
                            }
                          } else {
                            setTokenTestStatus('fail');
                          }
                          dismissLoading();
                        }}
                      >
                        Test token update
                      </IonButton>
                    </IonCol>

                    <IonCol>
                      {tokenTestStatus && tokenTestStatus === 'fail' && (
                        <IonText
                          style={{
                            fontSize: 'normal',
                            textAlign: 'center',
                            color: 'red',
                          }}
                        >
                          Token test failed
                        </IonText>
                      )}
                      {tokenTestStatus && tokenTestStatus === 'success' && (
                        <IonText
                          style={{
                            fontSize: 'normal',
                            textAlign: 'center',
                            color: 'green',
                          }}
                        >
                          Token test successful{' '}
                          {nextAction && <>({nextAction})</>}
                        </IonText>
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonTitle className="ion-no-padding">Endpoint ARN</IonTitle>
                <IonGrid className="ion-no-padding">
                  <IonRow>
                    {endpointARN && (
                      <>
                        <IonCol>
                          <IonText
                            style={{
                              fontSize: 'normal',
                              textAlign: 'center',
                              color: 'green',
                            }}
                          >
                            Endpoint detected
                          </IonText>
                        </IonCol>
                        {isLoadingEndpointStatus && (
                          <IonCol>
                            <IonSpinner></IonSpinner>
                          </IonCol>
                        )}
                        {endpointStatusData &&
                          !isLoadingEndpointStatus &&
                          !('error' in endpointStatusData) && (
                            <IonCol>
                              {endpointStatusData['Enabled'] ? (
                                <IonText
                                  style={{
                                    fontSize: 'normal',
                                    textAlign: 'center',
                                    color: 'green',
                                  }}
                                >
                                  Endpoint Enabled
                                </IonText>
                              ) : (
                                <IonText
                                  style={{
                                    fontSize: 'normal',
                                    textAlign: 'center',
                                    color: 'red',
                                  }}
                                >
                                  Endpoint Disabled
                                </IonText>
                              )}
                            </IonCol>
                          )}
                        {endpointStatusData &&
                          !isLoadingEndpointStatus &&
                          'error' in endpointStatusData && (
                            <IonCol>
                              <IonText
                                style={{
                                  fontSize: 'normal',
                                  textAlign: 'center',
                                  color: 'red',
                                }}
                              >
                                Error: {endpointStatusData['error']}
                              </IonText>
                            </IonCol>
                          )}
                      </>
                    )}
                    {!endpointARN && (
                      <IonCol>
                        <IonText
                          style={{
                            fontSize: 'normal',
                            textAlign: 'center',
                            color: 'red',
                          }}
                        >
                          No endpoint detected
                        </IonText>
                      </IonCol>
                    )}
                  </IonRow>
                  {endpointARN && (
                    <IonRow>
                      <IonCol>
                        <IonTextarea
                          readonly={true}
                          style={{ fontSize: 'smaller' }}
                        >
                          {endpointARN}
                        </IonTextarea>
                      </IonCol>
                    </IonRow>
                  )}
                </IonGrid>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                Test topics available:{' '}
                {testTopicsData && 'created' in testTopicsData
                  ? (testTopicsData['created'] || []).length
                  : 'n/a'}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonTitle className="ion-no-padding">
                  Current test subscriptions ({testSubscriptions.length})
                </IonTitle>
                <div className="ion-no-padding">
                  <ul>
                    {testSubscriptions.map((t: string, index: number) => (
                      <li
                        key={index}
                        style={{
                          fontSize: 'smaller',
                        }}
                      >
                        {t}
                      </li>
                    ))}
                  </ul>
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonTitle className="ion-no-padding">
                  Test topics console
                </IonTitle>
                <IonGrid className="ion-no-padding">
                  <IonRow style={{ padding: '1em' }}>
                    {expectedNotificationData && (
                      <>
                        <IonCol>
                          Expecting notification with id:
                          <b>{expectedNotificationData['testId']}</b> from topic{' '}
                          <b>{expectedNotificationData['sourceTopicARN']}</b>
                        </IonCol>
                        <IonCol>
                          {didMatchExpected === true && (
                            <IonText style={{ color: 'green' }}>
                              Received expected data
                            </IonText>
                          )}
                          {didMatchExpected === false && (
                            <IonText style={{ color: 'red' }}>
                              Received incorrect data
                            </IonText>
                          )}
                        </IonCol>
                      </>
                    )}
                    <IonCol>
                      {didTimeout === true && (
                        <IonText style={{ color: 'red' }}>Timeout</IonText>
                      )}
                    </IonCol>
                  </IonRow>

                  {endpointARN &&
                    testTopicsData &&
                    testTopicsData['created'].length > 0 &&
                    testTopicsData['created'].map(
                      (t: string, index: number) => (
                        <IonRow
                          key={`tt_${index}`}
                          className="ion-align-items-center"
                        >
                          <IonCol>
                            <IonText style={{ fontSize: 'x-small' }}>
                              # {index + 1}
                            </IonText>
                          </IonCol>
                          <IonCol>
                            <IonText style={{ fontSize: 'x-small' }}>
                              {testSubscriptions
                                .map((t: string) => getTopicFromSubscription(t))
                                .includes(t)
                                ? 'Subscribed'
                                : 'Not subscribed'}
                            </IonText>
                          </IonCol>
                          <IonCol>
                            <IonButton
                              style={{ fontSize: 'smaller' }}
                              onClick={async () => {
                                presentLoading();
                                snsSubscribeTopic({
                                  topicARN: t,
                                  endpoint: endpointARN,
                                })
                                  .then(() => refetchCurrentSubscriptions())
                                  .finally(() => dismissLoading());
                              }}
                            >
                              Subscribe
                            </IonButton>
                          </IonCol>
                          <IonCol>
                            <IonButton
                              style={{ fontSize: 'smaller' }}
                              onClick={async () => {
                                presentLoading();
                                snsUnsubscribeTopic({
                                  topicARN: t,
                                  endpoint: endpointARN,
                                })
                                  .then(() => refetchCurrentSubscriptions())
                                  .finally(() => dismissLoading());
                              }}
                            >
                              Unsubscribe
                            </IonButton>
                          </IonCol>
                          <IonCol>
                            <IonButton
                              style={{ fontSize: 'smaller' }}
                              onClick={async () => {
                                const expectedId = Math.floor(
                                  Math.random() * 10000
                                );

                                setDidMatchExpected(undefined);
                                setDidTimeout(undefined);

                                setExpectedNotificationData({
                                  testId: `${expectedId}`,
                                  sourceTopicARN: t,
                                });

                                requestTestPushNotification({
                                  testId: `${expectedId}`,
                                  topicARN: t,
                                });

                                clearTimeout(timerRef.current);
                                timerRef.current = setTimeout(() => {
                                  setDidTimeout(true);
                                }, testNotificationTimeout);
                              }}
                            >
                              Notify
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      )
                    )}
                  <IonRow>
                    <IonCol size="12">
                      <IonButton
                        expand="full"
                        onClick={async () => {
                          if (
                            endpointARN &&
                            testTopicsData &&
                            (testTopicsData['created'] || []).length > 0
                          ) {
                            snsSubscribeTopic({
                              topicARN: testTopicsData['created'],
                              endpoint: endpointARN,
                            }).then((r) => {
                              if ('data' in r && 'created' in r['data']) {
                                refetchCurrentSubscriptions();
                              }
                            });
                          }
                        }}
                      >
                        Subscribe to all test topics
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12">
                      <IonButton
                        expand="full"
                        onClick={async () => {
                          if (
                            endpointARN &&
                            testSubscriptions &&
                            (testSubscriptions || []).length > 0
                          ) {
                            snsUnsubscribeTopic({
                              topicARN: testSubscriptions.map((t: string) =>
                                getTopicFromSubscription(t)
                              ),
                              endpoint: endpointARN,
                            }).then((r) => {
                              refetchCurrentSubscriptions();
                              if ('data' in r && 'failed' in r['data']) {
                                const failedUnsubscriptions =
                                  r['data']['failed'];
                                if (failedUnsubscriptions.length > 0) {
                                  setUnsubscribeAllTestFailed(true);
                                } else {
                                  setUnsubscribeAllTestFailed(false);
                                }
                              }
                            });
                          }
                        }}
                      >
                        Unsubscribe from all subscribed test topics
                      </IonButton>
                      {didUnsubscribeAllTestFailed && (
                        <IonText
                          style={{
                            fontSize: 'small',
                            textAlign: 'center',
                            color: 'red',
                          }}
                        >
                          Failure in unsubscribe all
                        </IonText>
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonTitle className="ion-no-padding">
                  Current non-test subscriptions ({nonTestSubscriptions.length})
                </IonTitle>
                <IonGrid className="ion-no-padding">
                  <IonRow>
                    <IonCol>
                      <ul>
                        {nonTestSubscriptions.map(
                          (s: string, index: number) => (
                            <li
                              key={index}
                              style={{
                                fontSize: 'smaller',
                              }}
                            >
                              {s}
                            </li>
                          )
                        )}
                      </ul>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12">
                      <IonButton
                        expand="full"
                        onClick={async () => {
                          if (
                            endpointARN &&
                            nonTestSubscriptions &&
                            (nonTestSubscriptions || []).length > 0
                          ) {
                            snsUnsubscribeTopic({
                              topicARN: nonTestSubscriptions.map((t: string) =>
                                getTopicFromSubscription(t)
                              ),
                              endpoint: endpointARN,
                            }).then((r) => {
                              refetchCurrentSubscriptions();
                              if ('data' in r && 'failed' in r['data']) {
                                const failedUnsubscriptions =
                                  r['data']['failed'];
                                if (failedUnsubscriptions.length > 0) {
                                  setUnsubscribeAllFailed(true);
                                } else {
                                  setUnsubscribeAllFailed(false);
                                }
                              }
                            });
                          }
                        }}
                      >
                        Unsubscribe from all subscribed topics
                      </IonButton>
                      {didUnsubscribeAllFailed && (
                        <IonText
                          style={{
                            fontSize: 'small',
                            textAlign: 'center',
                            color: 'red',
                          }}
                        >
                          Failure in unsubscribe all
                        </IonText>
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonButton
            fill="outline"
            style={{
              marginTop: 'var(--space-4)',
            }}
            href={ROUTES.home.path}
          >
            Regresar al inicio
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default HealthCheckNotificationsComponent;
