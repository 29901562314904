import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonRippleEffect,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { MapContainer } from 'react-leaflet';
import { useHistory } from 'react-router-dom';
import { MapComponent } from '../components';
import FullPageLoader from '../components/loaders/FullPageLoader';
import MapLegendModal from '../components/map/MapLegendModal';
import { ROUTES, regions } from '../constants';
import { CDAlertasApi } from '../services/CDAlertasApi';
import infoIcon from '../theme/icons/info-3.svg';
import activeActIcon from '../theme/icons/tabs-active-act.svg';
import './Map.scss';
import { useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import { mapInitTimeOut } from '../constants';
import ReactResizeDetector from 'react-resize-detector';

export const maxAlertStatus = (alerts: any) => {
  if (alerts.roja > 0) {
    return 'roja';
  }

  if (alerts.amarilla > 0) {
    return 'amarilla';
  }

  if (alerts['temprana preventiva'] > 0) {
    return 'temprana preventiva';
  }

  return '';
};

export const reagionHasNotNationalAlerts = (region: any) => {
  return (
    region.activeAlerts.meta.count -
      region.activeNationalRedAlerts.meta.count -
      region.activeNationalYellowAlerts.meta.count -
      region.activeNationalGreenAlerts.meta.count >
    0
  );
};

const Map: React.FC = () => {
  const history = useHistory();

  const initialMapCoords = [-38.437954004569356, -70.65932705697341];
  const minZoom = 4;
  const maxZoom = 12;

  const [renderMap, setRenderMap] = useState(false);

  const [currentRegion, setCurrentRegion] = useState<any>(null);

  const router = useIonRouter();

  const mapComponentRef = useRef<any>(null);

  const regionClickHandler = (el: any) => {
    setRenderMap(true);
    setCurrentRegion(el);
    history.push(ROUTES.map.path + '/regions/' + el.cutRegion);
  };

  const [isMapLegendModalOpen, setIsMapLegendModalOpen] = useState(false);

  const {
    data: alertDataSummaries,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error,
  } = CDAlertasApi.useGetAllRegionsSummariesQuery({});

  const alertDataSorted =
    alertDataSummaries &&
    [...alertDataSummaries.data]
      .map((el: any) => {
        return {
          ...el,
          labelShort: Object.keys(
            ((regions || [{}]).find(
              (el2: any) => Object.values(el2 || {})[0] === el.cutRegion
            ) as any) || {}
          )[0],
        };
      })
      // .filter((el: any) => el.activeAlerts.meta.count > 0)
      .sort((a: any, b: any) => a.order - b.order);

  useIonViewDidEnter(() => {
    setTimeout(() => {
      setRenderMap(true), mapInitTimeOut;
    });
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonIcon
            slot="end"
            src={infoIcon}
            style={{
              marginRight: '10px',
              width: '30px',
              height: '30px',
            }}
            onClick={() => setIsMapLegendModalOpen(true)}
            aria-label="Abrir leyenda del mapa"
          >
            <IonRippleEffect></IonRippleEffect>
          </IonIcon>
          <IonTitle className="ion-no-padding">
            <span role="heading" aria-level={1}>
              Mapa de Alertas
            </span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent
        scrollEvents={true}
        fullscreen
        className="ion-content-custom-background"
      >
        {renderMap && alertDataSummaries && isSuccess ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              position: 'relative',
              backgroundColor: 'var(--itrend--blueGray-50)',
            }}
          >
            <ReactResizeDetector
              handleWidth
              handleHeight
              targetRef={mapComponentRef}
            >
              {({ width, height }) => (
                <div
                  style={{
                    height: '100%',
                    width: '40%',
                  }}
                  ref={mapComponentRef}
                >
                  <MapContainer
                    // center={initialMapCoords as any}
                    // zoom={minZoom}
                    zoomSnap={0.25}
                    scrollWheelZoom={false}
                    zoomControl={false}
                    // minZoom={minZoom}
                    // maxZoom={minZoom}
                    dragging={false}
                    touchZoom={false}
                    doubleClickZoom={false}
                    boxZoom={false}
                    keyboard={false}
                    style={{
                      // width: '40%',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <MapComponent
                      regionsWithCounts={alertDataSummaries?.data}
                      width={width}
                      height={height}
                    />
                  </MapContainer>
                </div>
              )}
            </ReactResizeDetector>

            <div
              style={{
                width: '60%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                backgroundColor: 'white',
                paddingLeft: 'var(--space-3)',
                paddingRight: 'var(--space-3)',
                paddingTop: 'var(--space-2)',
                overflowY: 'scroll',
              }}
            >
              {alertDataSorted.map((el: any, ind: any) => {
                return (
                  <IonButton
                    key={el.labelShort}
                    className="button-outline map-regions-list-button"
                    style={{
                      '--border-style': 'none',
                      borderBottom: '1px solid var(--itrend--blueGray-50)',
                      borderBottomWidth:
                        ind === alertDataSorted.length - 1 ? '0px' : '1px',
                      backgroundColor: 'transparent',
                      pointerEvents: reagionHasNotNationalAlerts(el)
                        ? 'auto'
                        : 'none',
                    }}
                    onClick={() => {
                      if (reagionHasNotNationalAlerts(el)) {
                        regionClickHandler(el);
                      }
                    }}
                    // href={ROUTES.map.path + '/regions/' + el.code}
                    // disabled={!el.maxLevel}
                  >
                    <div className="map-regions-list-button-inner">
                      {reagionHasNotNationalAlerts(el) && (
                        <IonIcon
                          src={activeActIcon}
                          style={{
                            height: '20px',
                            width: '20px',
                          }}
                          aria-label={el.labelShort + ' tiene alertas vigentes'}
                        />
                      )}
                      <div className="map-regions-list-button-inner-label">
                        {el.labelShort}
                      </div>
                      <IonIcon
                        icon={chevronForwardOutline}
                        style={{
                          height: '20px',
                          width: '20px',
                          color: 'var(--itrend--gray-500)',
                        }}
                        aria-label={'Ir a mapa regional de ' + el.labelShort}
                      />
                    </div>
                  </IonButton>
                );
              })}
            </div>
          </div>
        ) : (
          <FullPageLoader />
        )}
        <MapLegendModal
          isOpen={isMapLegendModalOpen}
          setIsOpen={setIsMapLegendModalOpen}
        />
      </IonContent>
    </IonPage>
  );
};

export default Map;
