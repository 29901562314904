import './Lifecycle.scss';

import React from 'react';

import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';

import { makeGoBackUrl } from '../../constants';
import { AlertLifecycle } from '../../features/notifications/notifications';
import {
  addAlertLifecycleToNotifications,
  removeAlertLifecycleFromNotifications,
} from '../../features/notifications/notificationsActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { useSwipeable } from 'react-swipeable';
import { swipeConfig, maxPixelsNumberFromEdge } from '../../constants';

const Lifecycle: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    lifecycle,
    isNotificationsUpdating,
    notificationUpdatingLifecycleList,
  } = useAppSelector((state: RootState) => state.notifications);

  const [localLifecycle, setLocalLifecycle] = React.useState(lifecycle);

  const lifecycle2name: Record<string, [string, string | null]> = {
    alert_declaration: ['Declaración de alerta', null],
    alert_cancellation: ['Cancelación de alerta', null],
    alert_type_escalate: [
      'Cambios de estado ascendentes',
      'Ejemplo: pasa de Amarilla a Roja',
    ],
    alert_type_deescalate: [
      'Cambios de estado descendentes',
      'Ejemplo: pasa de Roja a Amarilla',
    ],
    alert_update: ['Cambio de cobertura', null],
    // alert_monitor: ['Cambio de atributos menores', null],
  };

  const router = useIonRouter();
  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventData) => {
      if (eventData.initial[0] > maxPixelsNumberFromEdge) {
        return;
      }

      router.push(makeGoBackUrl(window.location, 1));
    },
    ...swipeConfig,
  });

  return (
    <IonPage {...swipeHandlers}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={makeGoBackUrl(window.location, 1)} />
          </IonButtons>
          <IonTitle>Ciclo de vida de la alerta</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-content-custom-background">
        <div className="types-content-block">
          <IonItem>
            <IonLabel>
              <p style={{ whiteSpace: 'normal' }}>
                Infórmate sólo de alertas nuevas (declaradas) o también de sus
                cambios de estado hasta que son canceladas
              </p>
            </IonLabel>
          </IonItem>
        </div>

        <IonTitle className="ion-margin-bottom ion-margin-top types-title">
          Recibir notificaciones de
        </IonTitle>

        <div className="types-content-block">
          {lifecycle2name &&
            Object.keys(lifecycle2name).map((l: string) => {
              const [label, help] = lifecycle2name[l];
              const lifecycleList =
                l !== 'alert_update'
                  ? [l]
                  : ['alert_update', 'alert_type_asc', 'alert_type_desc'];
              return (
                <IonItem lines="full" key={l}>
                  <IonLabel>
                    {label}
                    {help && <p>{help}</p>}
                  </IonLabel>

                  {isNotificationsUpdating &&
                    (notificationUpdatingLifecycleList ?? []).includes(
                      l as AlertLifecycle
                    ) && (
                      <IonSpinner
                        className="alert-card-buttons-fav fav-spinner"
                        style={{
                          width: 'var(--headline-medium)',
                          heigth: 'var(--headline-medium)',
                        }}
                        name="circular"
                      ></IonSpinner>
                    )}
                  {!(notificationUpdatingLifecycleList ?? []).includes(
                    l as AlertLifecycle
                  ) && (
                    <IonToggle
                      slot="end"
                      disabled={isNotificationsUpdating}
                      checked={localLifecycle[l as AlertLifecycle]}
                      onIonChange={async (evt) => {
                        if (
                          localLifecycle[l as AlertLifecycle] ===
                          evt.detail.checked
                        )
                          return;
                        setLocalLifecycle({
                          ...localLifecycle,
                          [l as AlertLifecycle]: evt.detail.checked,
                        });
                        if (evt.detail.checked) {
                          await dispatch(
                            addAlertLifecycleToNotifications(lifecycleList)
                          );
                        } else {
                          await dispatch(
                            removeAlertLifecycleFromNotifications(lifecycleList)
                          );
                        }
                      }}
                    ></IonToggle>
                  )}
                </IonItem>
              );
            })}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Lifecycle;
