import './AlertCard.scss';

import { Clipboard } from '@capacitor/clipboard';
import { Share } from '@capacitor/share';
import { format, isAfter, isBefore, subSeconds } from 'date-fns';
import { es } from 'date-fns/locale';
import { addIcons } from 'ionicons';
import {
  documentTextOutline,
  linkOutline,
  mail,
  shareOutline,
  shareSocial,
} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { removeAlertFromList } from '../features/readNewInfo/readNewInfoActions';
import bookmarkFilled from '../theme/icons/bookmark-filled.svg';
import bookmarkOutline from '../theme/icons/bookmark-outline.svg';
import bookmarkFilledGray from '../theme/icons/bookmark-filled-gray.svg';
import bookmarkOutlineGray from '../theme/icons/bookmark-outline-gray.svg';
/* eslint-disable no-constant-condition */
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItemDivider,
  IonRow,
  IonSkeletonText,
  IonSpinner,
  IonText,
  useIonAlert,
  useIonModal,
} from '@ionic/react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { ROUTES, newAlertThresholdTest } from '../constants';
import {
  addCardToFavorites,
  disableUnfollowMessages,
  removeCardFromFavorites,
  setAlertIdToRemoveFromList,
  toggleAlertsListUpdate,
} from '../features/favorites/favoritesActions';
import { openDeniedBannerAgain } from '../features/notifications/notificationsActions';
import { useAppDispatch, useAppSelector } from '../hooks';
import { CDAlertasApi } from '../services/CDAlertasApi';
import { RootState } from '../store';
import CardLocation, { CardLocationSkeleton } from './AlertCardLocation';

addIcons({
  'fav-a': 'assets/icon/fav-a.svg',
  'fav-ia': 'assets/icon/fav-ia.svg',
  share: 'assets/icon/share.svg',
});

const copyFormatted = async (html: any) => {
  // Create an iframe (isolated container) for the HTML
  const container = document.createElement('div');
  container.innerHTML = html;

  // Hide element
  container.style.position = 'fixed';
  container.style.pointerEvents = 'none';
  container.style.opacity = '0';

  // Detect all style sheets of the page
  const activeSheets = Array.prototype.slice
    .call(document.styleSheets)
    .filter(function (sheet) {
      return !sheet.disabled;
    });

  // Mount the iframe to the DOM to make `contentWindow` available
  document.body.appendChild(container);

  // Copy to clipboard
  window.getSelection()?.removeAllRanges();

  const range = document.createRange();
  range.selectNode(container);
  window.getSelection()?.addRange(range);

  document.execCommand('copy');
  for (let i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = true;
  document.execCommand('copy');
  for (let i = 0; i < activeSheets.length; i++)
    activeSheets[i].disabled = false;

  // Remove the iframe
  document.body.removeChild(container);
};

const prepareShareTextForMessanger = (text: any) => {
  const newText = text
    .replaceAll('<b>', '*')
    .replaceAll('</b>', '*')
    .replaceAll('<ul>', '')
    .replaceAll('</ul>', '\n')
    .replaceAll('<li>', '\n - ')
    .replaceAll('</li>', '')
    .replaceAll('<br>', '\n');
  const newTextWithLinks = newText + '';

  return newTextWithLinks;
};

const prepareShareTextForEmail = (text: any) => {
  const newText = text
    .replaceAll('</ul>', '\r')
    .replaceAll('<li>', '\r - ')
    .replaceAll('<br>', '\r')
    .replace(/<[^>]*>?/gm, '');

  return newText;
};

export const ShareModal = ({
  title,
  text,
  info,
  location,
  suffix,
  idIntParent,
  isEventsDetailsSuccess,
  isShared,
  cardType,
}: {
  title: string;
  text: string;
  info: string;
  location: Location;
  suffix: string;
  idIntParent: string;
  isEventsDetailsSuccess: any;
  isShared: any;
  cardType: any;
}) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const [infoCopied, setInfoCopied] = useState(false);
  const sharePageName = ROUTES.shared.path;

  const parentAlertUrl = idIntParent ? 'alerts/' + idIntParent : '';

  const shareUrl =
    location.protocol +
    '//' +
    location.host +
    sharePageName +
    '/' +
    (cardType === 'Event' && isShared && !location.pathname.includes('events/')
      ? 'alerts/'
      : '') +
    (parentAlertUrl && location.pathname.includes('/events')
      ? parentAlertUrl + '/'
      : '') +
    suffix;

  return (
    <IonContent className="alert-card-share">
      <IonGrid className="ion-padding ion-align-items-center ion-text-center">
        <IonRow
          className="ion-text-center ion-padding"
          style={{
            fontWeight: 'var(--font-weight-bold)',
            fontSize: 'var(--body-large)',
          }}
        >
          <IonCol>
            <IonText>Compartir</IonText>
          </IonCol>
        </IonRow>

        <IonRow className="ion-padding-top">
          <IonCol>
            <IonButton
              className="circle"
              fill="outline"
              onClick={async () => {
                await copyFormatted(info);
                setInfoCopied(true);
              }}
            >
              <IonIcon
                slot="icon-only"
                icon={documentTextOutline}
                color="var(--itrend--secondary-500)"
              ></IonIcon>
            </IonButton>
          </IonCol>
          <IonCol size="4">
            <IonButton
              className="circle"
              fill="outline"
              onClick={() => {
                const cleanedInfo = prepareShareTextForEmail(info);
                const encodedBody = encodeURIComponent(cleanedInfo);
                const encodedTitle = encodeURIComponent(title);
                window.open(
                  `mailto:?body=${encodedBody}&subject=${encodedTitle}`
                );
              }}
            >
              <IonIcon
                slot="icon-only"
                icon={mail}
                color="var(--itrend--secondary-500)"
              ></IonIcon>
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton
              className="circle"
              fill="outline"
              onClick={async () =>
                await Share.share({
                  title: title,
                  text: prepareShareTextForMessanger(info),
                })
              }
            >
              <IonIcon
                slot="icon-only"
                icon={shareOutline}
                color="var(--itrend--secondary-500)"
              ></IonIcon>
            </IonButton>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            {((!infoCopied && isEventsDetailsSuccess) ||
              (!infoCopied && cardType === 'Sae')) && (
              <IonText>Copiar info</IonText>
            )}
            {!infoCopied && !isEventsDetailsSuccess && cardType !== 'Sae' && (
              <IonText>Cargando...</IonText>
            )}
            {infoCopied && <IonText>Copiado</IonText>}
          </IonCol>
          <IonCol>
            <IonText>Email</IonText>
          </IonCol>
          <IonCol>
            <IonText>Compartir por...</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export interface CardProps {
  filterListOfAlerts?: any;
  id: string;
  alert: string;
  type: string;
  lastUpdateId?: number;
  idInt?: number;
  active: boolean;
  level: string;
  date: Date;
  dateUpd: Date;
  pageName?: string;
  alertLocationsData: any;
}

export const CardFooter: React.FC<any> = ({
  filterListOfAlerts,
  type = '',
  level = '',
  idInt = '',
  id = '',
  alert = '',
  buttonTitle = '',
  buttonLink = '',
  active,
  cardType = 'Alert',
  eventName = '',
  eventStatus = '',
  toggleFunc = null,
  idIntParent = '',
  shareData = '',
}) => {
  const title =
    cardType === 'Alert'
      ? `Alerta ${type} ${level} por ${alert}`
      : cardType === 'Event'
      ? `Evento ${eventName} de ${alert} ${eventStatus}`
      : 'Mensaje SAE: ' + shareData.Event;
  const text =
    cardType === 'Alert'
      ? `Alerta ${type} ${level} por ${alert}`
      : cardType === 'Event'
      ? `Evento ${eventName} de ${alert} ${eventStatus}`
      : 'Mensaje SAE: ' + shareData.Message;

  const regionId =
    cardType !== 'Sae' &&
    window.location.pathname.split('/').includes('regions') &&
    window.location.pathname.split('/').slice(4, 5);
  const [info, setInfo] = useState('');

  useEffect(() => {
    if (shareData) {
      if (cardType === 'Alert') {
        setInfo(
          '<b>Alerta ' +
            type +
            ' ' +
            level +
            ' por ' +
            alert +
            '</b><br>' +
            'ID: ' +
            shareData.id +
            '<br>' +
            'Declarada: ' +
            format(shareData.date, "dd LLLL yyyy 'a las' HH:mm", {
              locale: es,
            }) +
            '<br>' +
            (shareData.active === 1 ? 'Actualizada: ' : 'Cancelada: ') +
            format(shareData.dateUpd, "dd LLLL yyyy 'a las' HH:mm", {
              locale: es,
            }) +
            '<br>' +
            '<br>' +
            (shareData.locations
              ? shareData.locations.length > 1
                ? shareData.locationsNum + ':<br>'
                : ''
              : '') +
            (shareData.locations
              ? shareData.locations.length > 1
                ? shareData.locations
                    .map(
                      (el: any) =>
                        el.title.trim() + '<b>' + el.text + '</b> ' + '<br>'
                    )
                    .join('')
                : shareData.locations[0].title.trim() +
                  '<br>' +
                  '<b>' +
                  shareData.locations[0].text +
                  '</b>' +
                  '<br>'
              : '')
        );
      }
      if (cardType === 'Event') {
        setInfo(`<b>Evento ${eventName} de ${alert} ${eventStatus}</b>`);
      }
      if (cardType === 'Sae') {
        setInfo(
          '<b>Mensaje SAE: ' +
            shareData.Event +
            '</b><br>' +
            '<b>Fecha:</b> ' +
            format(
              new Date(shareData.DateTime),
              "eeee dd LLLL yyyy '-' HH:mm",
              {
                locale: es,
              }
            ) +
            '<br>' +
            '<b>Región:</b> ' +
            shareData.Region +
            '<br>' +
            '<b>Comuna:</b> ' +
            shareData.Polygon +
            '<br>' +
            '<b>Mensaje:</b> ' +
            shareData.Message
        );
      }
    }
  }, [shareData]);

  const location = window.location;
  const isShared = location.pathname.includes(ROUTES.shared.path);
  const suffix = buttonLink;

  const idIntParentShared =
    isShared &&
    location.pathname.includes('/events') &&
    location.pathname.split('/').slice(4, 5);
  const pageName = location.pathname
    .split('/')
    .slice(0, isShared ? 4 : 3)
    .join('/');

  const [loadEventsDetails, setLoadEventsDetails] = useState(false);

  const {
    data: eventsDetails,
    isSuccess: isEventsDetailsSuccess,
    isError: isEventsDetailsError,
    error: eventsDetailsError,
  } = CDAlertasApi.useGetAlertEventsDetailsQuery(
    cardType === 'Alert' ? idInt : idIntParent,
    {
      skip: !loadEventsDetails,
    }
  );

  const [presentShareModal, dismissShareModal] = useIonModal(ShareModal, {
    dismiss: (data: unknown, role: string | undefined) =>
      dismissShareModal(data, role),
    title: title,
    text: text,
    info: info,
    location: location,
    isEventsDetailsSuccess: isEventsDetailsSuccess,
    suffix: suffix,
    idIntParent: idIntParent || idIntParentShared,
    isShared: isShared,
    cardType: cardType,
  });
  const [presentAlert] = useIonAlert();
  const dispatch = useAppDispatch();
  const { currentFavorites, historyFavorites, unfollowMessagesEnabled } =
    useAppSelector((state: RootState) => state.favorites);

  const isFav = currentFavorites.includes(id);
  const isFirstFav = historyFavorites.length === 0;
  const [favLoading, setFavLoading] = useState(false);

  const {
    isNotificationsInitializingNative,
    isNotificationsInitializingWebpush,
    currentNotificationPermissions,
  } = useAppSelector((state: RootState) => state.notifications);

  useEffect(() => {
    const makeInfo = (eventsDetails: any) => {
      if (isEventsDetailsSuccess && eventsDetails.length > 0) {
        const infoEv = eventsDetails
          .map((el: any) => {
            return (
              'Nombre: ' +
              '<b>' +
              el.name +
              '</b> ' +
              '<ul>' +
              '<li>' +
              'Tipo: ' +
              'Incendio Forestal' +
              '</li>' +
              '<li>' +
              'Inicio: ' +
              format(new Date(el.startTime), "dd LLLL yyyy 'a las' HH:mm", {
                locale: es,
              }) +
              '</li>' +
              '<li>' +
              'Superficie: ' +
              el.lastIncident.data.surface +
              ' ha' +
              '<li>' +
              'Estado: ' +
              el.lastIncident.data.statusData.name +
              '</li>' +
              '</ul>' +
              '<br>'
            );
          })
          .join('');

        const eventsHeader =
          cardType === 'Alert' ? '<br>Eventos asociados:<br>' : '<br>';

        setInfo(info + eventsHeader + infoEv);
      }
    };

    if (eventsDetails) {
      if (cardType === 'Alert') {
        makeInfo(eventsDetails.data);
      } else {
        makeInfo(eventsDetails.data.filter((el: any) => el.id === idInt));
      }
    }
  }, [isEventsDetailsSuccess]);

  const getFavIcon = () => {
    if (
      currentNotificationPermissions &&
      currentNotificationPermissions.some((el: any) => el === 'granted')
    ) {
      return isFav ? bookmarkFilled : bookmarkOutline;
    } else {
      return isFav ? bookmarkFilledGray : bookmarkOutlineGray;
    }
  };

  return (
    <div
      className={`alert-card-buttons ${
        !buttonTitle && 'alert-card-buttons-no-paddings'
      }`}
    >
      <div style={{ margin: '10px' }}>
        <IonIcon
          icon={shareSocial}
          size="large"
          className="alert-card-buttons-share"
          onClick={async () => {
            if (cardType !== 'Sae') {
              setLoadEventsDetails(true);
            }

            presentShareModal({
              breakpoints: [0, 0.25, 0.5],
              initialBreakpoint: 0.5,
            });
          }}
          aria-label={
            'Compartir alerta ' + level + ' ' + type + ' ' + id + ' de ' + alert
          }
        ></IonIcon>

        {((cardType === 'Alert' && favLoading) ||
          ((isNotificationsInitializingNative ||
            isNotificationsInitializingWebpush) &&
            pageName !== ROUTES.subscriptions.path)) && (
          <IonSpinner
            className="alert-card-buttons-fav fav-spinner"
            style={{
              width: 'var(--headline-medium)',
              heigth: 'var(--headline-medium)',
            }}
            name="circular"
          ></IonSpinner>
        )}

        {cardType === 'Alert' &&
          !favLoading &&
          ((!isNotificationsInitializingNative &&
            !isNotificationsInitializingWebpush) ||
            pageName === ROUTES.subscriptions.path) && (
            <IonIcon
              aria-label={
                'Suscribir a la alerta ' +
                level +
                ' ' +
                type +
                ' ' +
                id +
                ' de ' +
                alert
              }
              className={`alert-card-buttons-fav ${
                !isFav && 'alert-card-buttons-fav-inactive'
              }`}
              src={getFavIcon()}
              size="large"
              onClick={async () => {
                if (
                  currentNotificationPermissions &&
                  !currentNotificationPermissions.some(
                    (el: any) => el === 'granted'
                  )
                ) {
                  dispatch(openDeniedBannerAgain());
                } else {
                  setFavLoading(true);
                  if (isFav && unfollowMessagesEnabled) {
                    presentAlert({
                      onDidDismiss: async (evt) => {
                        // Did the user check the 'No mostrar más este mensaje' checkbox?
                        if (
                          evt?.detail?.data?.values &&
                          evt.detail.data.values.length > 0
                        ) {
                          if (
                            evt.detail.data.values.includes(
                              'skip_unfollow_message'
                            )
                          ) {
                            // TO DO Dispatch setting to store
                            await dispatch(disableUnfollowMessages());
                          }
                        }
                      },
                      cssClass: 'alert-unfollow',
                      header: '¿Desuscribirte de esta alerta?',
                      message: 'Ya no la verás en esta sección.',
                      inputs: [
                        {
                          label: 'No mostrar más este mensaje',
                          type: 'checkbox',
                          value: 'skip_unfollow_message',
                        },
                      ],
                      buttons: [
                        {
                          text: 'Volver',
                          role: 'cancel',
                        },
                        {
                          text: 'Desuscribirse',
                          role: 'confirm',
                          handler: async () => {
                            await dispatch(removeCardFromFavorites(id));
                            await dispatch(
                              toggleAlertsListUpdate(
                                pageName !== ROUTES.subscriptions.path
                              )
                            );
                            await dispatch(setAlertIdToRemoveFromList(id));
                          },
                        },
                      ],
                    });
                  } else if (isFav && !unfollowMessagesEnabled) {
                    await dispatch(removeCardFromFavorites(id));
                    await dispatch(
                      toggleAlertsListUpdate(
                        pageName !== ROUTES.subscriptions.path
                      )
                    );
                    await dispatch(setAlertIdToRemoveFromList(id));
                  } else if (!isFav) {
                    await dispatch(addCardToFavorites(id));
                    await dispatch(
                      toggleAlertsListUpdate(
                        pageName !== ROUTES.subscriptions.path
                      )
                    );

                    if (isFirstFav) {
                      presentAlert({
                        header: 'Te suscribirás a esta alerta',
                        message: 'Podrás verla en la sección "Suscripciones"',
                      });
                    }
                  }
                  if (isFav) {
                    setTimeout(() => {
                      setFavLoading(false);
                    }, 1000);
                  } else {
                    setTimeout(() => setFavLoading(false), 500);
                  }
                }
              }}
            ></IonIcon>
          )}
      </div>

      {buttonTitle &&
        (toggleFunc ? (
          <IonButton
            className="alert-card-buttons-seemore"
            fill="clear"
            onClick={() => toggleFunc(true)}
          >
            {buttonTitle}
          </IonButton>
        ) : (
          <Link
            to={{
              pathname:
                pageName +
                (regionId ? '/regions/' + regionId : '') +
                '/' +
                buttonLink,
              state: {
                id: id,
                type: type,
                active: active,
                idIntParent: idIntParent,
              },
            }}
          >
            <IonButton className="alert-card-buttons-seemore" fill="clear">
              {buttonTitle}
            </IonButton>
          </Link>
        ))}
    </div>
  );
};

const Card: React.FC<CardProps> = ({
  filterListOfAlerts,
  id,
  alert,
  type,
  lastUpdateId,
  idInt,
  active,
  level,
  date,
  dateUpd,
  alertLocationsData,
}) => {
  const dispatch: Dispatch<any> = useDispatch();

  const [shareData, setShareData] = useState({
    id,
    alert,
    type,
    active,
    level,
    date,
    dateUpd,
  });

  const { readNewInfoAlertList } = useAppSelector(
    (state: RootState) => state.readNewInfo
  );

  const newAlertThreshold =
    (process.env.REACT_APP_SMTH_NEW_TIME_THRESHOLD_SECS as unknown as number) !=
    0
      ? process.env.REACT_APP_SMTH_NEW_TIME_THRESHOLD_SECS
      : newAlertThresholdTest;
  const limit = subSeconds(new Date(), newAlertThreshold as unknown as number);

  useEffect(() => {
    // delete from read list = mark as new / updated
    // when it is in time limit and was updated
    // or outside of time limit - to not collect a lot of old alerts in the list
    ((isAfter(new Date(dateUpd), limit) &&
      isAfter(new Date(dateUpd), new Date(readNewInfoAlertList[id]))) ||
      isBefore(new Date(dateUpd), limit)) &&
      dispatch(removeAlertFromList(id));
  }, []);

  const checkIfNew = (id: any) => {
    // if alert is in time limit
    if (isAfter(new Date(dateUpd), limit)) {
      // check if alert id is on the list

      return !Object.keys(readNewInfoAlertList).includes(id);
    }

    // othewise - it is not marked
    return false;
  };

  const [locationsNum, setLocationNum] = useState(
    level === 'Nacional' ? 'Nacional' : ''
  );

  return (
    <IonCard
      className={`ion-no-padding ion-no-margin alert-card ${
        !active && 'alert-card-inactive'
      }`}
    >
      <IonCardHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <div>
            <IonChip
              className={`no-click alert-card-chip ${
                !active && 'alert-card-chip-inactive'
              } alert-card-chip-${type.replace(/\s/g, '').toLocaleLowerCase()}`}
            >
              {type}
            </IonChip>
            <IonChip
              outline={true}
              className={`no-click alert-card-chip ${
                !active && 'alert-card-chip-inactive'
              }`}
              style={{
                backgroundColor: 'var(--itrend--secondary-50)',
                border: 'none',
                color: 'var(--itrend--secondary-700)',
              }}
            >
              {locationsNum || (
                <IonSkeletonText
                  style={{
                    width: '60px',
                  }}
                  animated={true}
                ></IonSkeletonText>
              )}
            </IonChip>
          </div>

          {checkIfNew(id) && <div className="smth-new-indicator"></div>}

          <IonCardSubtitle className="alert-card-id">ID {id}</IonCardSubtitle>
        </div>

        <IonCardSubtitle
          // first class is used to calc max width of the location text
          className={`alert-card-oneline-text-container alert-card-title ${
            !active && 'alert-card-title-inactive'
          }`}
          id={'alert ' + idInt}
          aria-label={'alert ' + idInt + ' heading'}
        >
          {alert}
        </IonCardSubtitle>

        {level !== 'Nacional' && (
          <CardLocation
            lastUpdateId={lastUpdateId}
            level={level}
            active={active}
            setLocationNum={setLocationNum}
            setShareData={setShareData}
            shareData={shareData}
            alertLocationsData={alertLocationsData}
          />
        )}
      </IonCardHeader>
      <IonCardContent style={{ paddingBottom: '0' }}>
        <IonGrid className="alert-card-datetime">
          <IonRow>
            <IonCol size="4">{'Declarada:'}</IonCol>
            <IonCol>
              {format(date, "dd LLLL yyyy 'a las' HH:mm", { locale: es })}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="4">{active ? 'Actualizada:' : 'Cancelada:'}</IonCol>
            <IonCol>
              {format(dateUpd, "dd LLLL yyyy 'a las' HH:mm", {
                locale: es,
              })}
            </IonCol>
          </IonRow>{' '}
        </IonGrid>
      </IonCardContent>

      <CardFooter
        filterListOfAlerts={filterListOfAlerts}
        type={type}
        shareData={shareData}
        level={level}
        idInt={idInt}
        id={id}
        buttonTitle={'Ver Detalle'}
        buttonLink={`alerts/${idInt}`}
        alert={alert}
        active={active}
        // pageName={pageName}
      />
    </IonCard>
  );
};

export const SkeletonCard: React.FC<any> = ({ isFav = false }) => {
  return (
    <IonCard className={'ion-no-padding ion-no-margin alert-card'}>
      <IonCardHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <IonChip className={'no-click alert-card-chip'}>
              <IonSkeletonText
                style={{
                  width: '60px',
                }}
                animated={true}
              ></IonSkeletonText>
            </IonChip>
            <IonChip
              outline={true}
              className={'no-click alert-card-chip'}
              style={{
                backgroundColor: 'var(--itrend--secondary-50)',
                border: 'none',
                color: 'var(--itrend--secondary-700)',
              }}
            >
              <IonSkeletonText
                style={{
                  width: '60px',
                }}
                animated={true}
              ></IonSkeletonText>
            </IonChip>
          </div>

          <IonCardSubtitle className="alert-card-id">
            <IonSkeletonText
              style={{ width: '40px' }}
              animated={true}
            ></IonSkeletonText>
          </IonCardSubtitle>
        </div>

        <IonCardSubtitle
          // first class is used to calc max width of the location text
          className={'alert-card-oneline-text-container alert-card-title'}
        >
          <IonSkeletonText animated={true}></IonSkeletonText>
        </IonCardSubtitle>

        <CardLocationSkeleton />
      </IonCardHeader>
      <IonCardContent style={{ paddingBottom: '0' }}>
        <IonGrid className="alert-card-datetime">
          <IonRow>
            <IonCol size="4">{'Declarada'}</IonCol>
            <IonCol>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="4">
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonCol>
            <IonCol>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>

      {/* Skeleton Card Footer */}
      <div className={'alert-card-buttons'}>
        <div style={{ margin: '10px' }}>
          <IonIcon
            icon={shareSocial}
            size="large"
            className="alert-card-buttons-share"
          ></IonIcon>

          {isFav ? (
            <IonIcon
              className={
                'alert-card-buttons-fav alert-card-buttons-fav-inactive'
              }
              icon={isFav ? bookmarkFilled : bookmarkOutline}
              size="large"
            ></IonIcon>
          ) : (
            <IonSpinner
              className="alert-card-buttons-fav fav-spinner"
              style={{
                width: 'var(--headline-medium)',
                heigth: 'var(--headline-medium)',
              }}
              name="circular"
            ></IonSpinner>
          )}
        </div>

        <IonButton className="alert-card-buttons-seemore" fill="clear">
          Ver Detalle
        </IonButton>
      </div>
      {/* End Skeleton Card Footer */}
    </IonCard>
  );
};

export default Card;
