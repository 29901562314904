import {
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AlertSymbolsLegendModal, NotIonBackButton } from '../../components';
import { ROUTES, regionPrefix, regions } from '../../constants';
import { CDAlertasApi } from '../../services/CDAlertasApi';
import infoIcon from '../../theme/icons/info-3.svg';
import FullPageLoader from '../loaders/FullPageLoader';
import BottomAlertsBlock from './BottomAlertsBlock';
import MapRegional from './MapRegional';
import RegionalAlertBlock from './RegionalAlertBlock';
import 'leaflet/dist/leaflet.css';
import { useSwipeable } from 'react-swipeable';
import { goBackHandler } from '../NotIonBackButton';
import { swipeConfig, maxPixelsNumberFromEdge } from '../../constants';

export const MapRegionalContainer: React.FC<any> = () => {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const mapRef = useRef<any>(null);

  const history = useHistory();

  const regionId =
    window.location.pathname.includes('regions') &&
    window.location.pathname.split('/')[4];

  const label = Object.keys(
    ((regions || [{}]).find(
      (el: any) => Object.values(el || {})[0] === regionId
    ) as any) || {}
  )[0];

  const currentRegion = {
    code: regionId,
    label,
  };

  const [chosenCommunaCode, setChosenCommunaCode] = useState('');
  const [isLegendModalOpen, setIsLegendModalOpen] = useState(false);

  const {
    data: alertData,
    isLoading,
    isFetching,
    isSuccess,
    isError: isAlertError,
    error: alertError,
  } = CDAlertasApi.useGetAllAlertsInRegionQuery({
    region: currentRegion.code,
  });

  const alertsFilteredLocations =
    alertData &&
    alertData.data.map((el: any) => {
      return {
        ...el,
        comunes: el.comunes.data.filter((el2: any) => {
          return el2.regionData.cutRegion === regionId;
        }),
        provinces: el.provinces.data.filter((el3: any) => {
          return el3.regionData.cutRegion === regionId;
        }),
      };
    });

  const notRegionalAlerts =
    alertsFilteredLocations &&
    alertsFilteredLocations.filter(
      (el: any) =>
        el.lastUpdateData.level !== 'Nacional' &&
        el.lastUpdateData.level !== 'Regional'
    );

  const redsComunal =
    notRegionalAlerts &&
    notRegionalAlerts
      .filter(
        (el: any) =>
          el.lastUpdateData.level === 'Comunal' &&
          el.lastUpdateData.status === 'Roja'
      )
      .map((el2: any) => {
        return el2.comunes.map((el3: any) => el3.cutComune);
      })
      .flat();

  const yellowsComunal =
    notRegionalAlerts &&
    notRegionalAlerts
      .filter(
        (el: any) =>
          el.lastUpdateData.level === 'Comunal' &&
          el.lastUpdateData.status === 'Amarilla'
      )
      .map((el2: any) => {
        return el2.comunes.map((el3: any) => el3.cutComune);
      })
      .flat();

  const greensComunal =
    notRegionalAlerts &&
    notRegionalAlerts
      .filter(
        (el: any) =>
          el.lastUpdateData.level === 'Comunal' &&
          el.lastUpdateData.status === 'Temprana Preventiva'
      )
      .map((el2: any) => {
        return el2.comunes.map((el3: any) => el3.cutComune);
      })
      .flat();

  const redsProvincial =
    notRegionalAlerts &&
    notRegionalAlerts
      .filter(
        (el: any) =>
          el.lastUpdateData.level === 'Provincial' &&
          el.lastUpdateData.status === 'Roja'
      )
      .map((el2: any) => {
        return el2.provinces.map((el3: any) => el3.cutProvince);
      })
      .flat();

  const yellowsProvincial =
    notRegionalAlerts &&
    notRegionalAlerts
      .filter(
        (el: any) =>
          el.lastUpdateData.level === 'Provincial' &&
          el.lastUpdateData.status === 'Amarilla'
      )
      .map((el2: any) => {
        return el2.provinces.map((el3: any) => el3.cutProvince);
      })
      .flat();

  const greensProvincial =
    notRegionalAlerts &&
    notRegionalAlerts
      .filter(
        (el: any) =>
          el.lastUpdateData.level === 'Provincial' &&
          el.lastUpdateData.status === 'Temprana Preventiva'
      )
      .map((el2: any) => {
        return el2.provinces.map((el3: any) => el3.cutProvince);
      })
      .flat();

  const regionalAlerts =
    alertData &&
    alertData.data.filter((el: any) => el.lastUpdateData.level === 'Regional');

  const getMaxRegionalAlert = (regionalAlerts: any) => {
    if (regionalAlerts.find((el: any) => el.lastUpdateData.status === 'Roja')) {
      return regionalAlerts.find(
        (el: any) => el.lastUpdateData.status === 'Roja'
      );
    }

    if (
      regionalAlerts.find((el: any) => el.lastUpdateData.status === 'Amarilla')
    ) {
      return regionalAlerts.find(
        (el: any) => el.lastUpdateData.status === 'Amarilla'
      );
    }

    if (
      regionalAlerts.find(
        (el: any) => el.lastUpdateData.status === 'Temprana Preventiva'
      )
    ) {
      return regionalAlerts.find(
        (el: any) => el.lastUpdateData.status === 'Temprana Preventiva'
      );
    }
  };

  const regionalAlert =
    regionalAlerts && alertData && getMaxRegionalAlert(regionalAlerts);

  const goToAlertDetails = (id: any = regionalAlert.id) => {
    history.push(
      ROUTES.map.path + '/regions/' + currentRegion.code + '/alerts/' + id
    );
  };

  const mapRemove = async () => {
    if (mapRef.current) {
      mapRef.current.off();
      mapRef.current.remove();
      mapRef.current = null;

      setIsMapLoaded(false);
      setChosenCommunaCode('');
    }
  };

  const router = useIonRouter();
  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventData) => {
      if (eventData.initial[0] > maxPixelsNumberFromEdge) {
        return;
      }
      goBackHandler(router, mapRemove);
    },
    ...swipeConfig,
  });

  return (
    <IonPage {...swipeHandlers}>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="ion-no-padding">
            <span role="heading" aria-level={1}>
              Región{' '}
              {regionPrefix[currentRegion.code as keyof typeof regionPrefix]}
              {currentRegion.label}
            </span>
          </IonTitle>
          <NotIonBackButton active={isMapLoaded} mapRemove={mapRemove} />
          <IonIcon
            slot="end"
            src={infoIcon}
            style={{
              marginRight: '10px',
              width: '30px',
              height: '30px',
            }}
            onClick={() => setIsLegendModalOpen(true)}
            aria-label="Abrir leyenda del mapa"
          ></IonIcon>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        style={{
          height: '100% !important',
          overflowY: 'hide',
          boxSizing: 'border-box',
        }}
      >
        {!(isLoading || isFetching) ? (
          <div className="modal-container">
            {regionalAlert && (
              <div
                className={`regional-alert-block regional-alert-block${
                  regionalAlert.lastUpdateData.status === 'Roja'
                    ? '-roja'
                    : regionalAlert.lastUpdateData.status === 'Amarilla'
                    ? '-amarilla'
                    : '-temprana-preventiva'
                }`}
                onClick={() => goToAlertDetails()}
              >
                <RegionalAlertBlock
                  label={currentRegion.label}
                  id={regionalAlert.id}
                  sinapredId={regionalAlert.sinapredId}
                  status={regionalAlert.lastUpdateData.status}
                  level={regionalAlert.lastUpdateData.level}
                />
              </div>
            )}

            {window.location.pathname.includes('regions') && (
              <div className="map-container-block">
                <div
                  style={{
                    width: '100%',
                  }}
                  id={'map-regional-container-' + regionId}
                  // id={'map-regional-container'}
                ></div>
                {!isLoading && !isFetching && isSuccess && (
                  <MapRegional
                    regionalAlertColor={
                      regionalAlert && regionalAlert.lastUpdateData.status
                    }
                    // regionId={regionId}
                    notRegionalAlerts={notRegionalAlerts}
                    redsComunal={redsComunal}
                    yellowsComunal={yellowsComunal}
                    greensComunal={greensComunal}
                    redsProvincial={redsProvincial}
                    yellowsProvincial={yellowsProvincial}
                    greensProvincial={greensProvincial}
                    setChosenCommunaCode={setChosenCommunaCode}
                    chosenCommunaCode={chosenCommunaCode}
                    setIsMapLoaded={setIsMapLoaded}
                    mapRef={mapRef}
                  />
                )}
              </div>
            )}

            {notRegionalAlerts.length && alertsFilteredLocations ? (
              <div
                className="bottom-alerts-block"
                style={{
                  backgroundColor: 'var(--itrend--gray-50)',
                  borderTop: '1px solid var(--itrend--blueGray-300)',
                }}
              >
                {(chosenCommunaCode
                  ? notRegionalAlerts.filter((el: any) => {
                      return el.comunes.filter((el2: any) => {
                        return el2.cutComune === chosenCommunaCode;
                      }).length;
                    })
                  : notRegionalAlerts
                ).map((el: any, ind: any) => {
                  return (
                    <BottomAlertsBlock
                      key={el.id}
                      id={el.id}
                      sinapredId={el.sinapredId}
                      status={el.lastUpdateData.status}
                      level={el.lastUpdateData.level}
                      goToAlertDetails={goToAlertDetails}
                      alertsFilteredLocations={
                        el.lastUpdateData.level === 'Comunal'
                          ? alertsFilteredLocations
                              .find((el2: any) => el2.id === el.id)
                              .comunes.map((el: any) => {
                                return el.label;
                              })
                          : alertsFilteredLocations
                              .find((el2: any) => el2.id === el.id)
                              .provinces.map((el: any) => {
                                return el.label;
                              })
                      }
                    />
                  );
                })}
              </div>
            ) : null}

            <AlertSymbolsLegendModal
              isOpen={isLegendModalOpen}
              setIsOpen={setIsLegendModalOpen}
              withCounts={false}
            />
          </div>
        ) : (
          <FullPageLoader />
        )}
      </IonContent>
    </IonPage>
  );
};

export default MapRegionalContainer;
