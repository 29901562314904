import { IonIcon } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import React from 'react';
import regionalAlertGreenWBorder from '../../theme/icons/regional-alert-green-wborder.svg';
import regionalAlertRedWBorder from '../../theme/icons/regional-alert-red-wborder.svg';
import regionalAlertYellowWBorder from '../../theme/icons/regional-alert-yellow-wborder.svg';

const regionalIcons = {
  roja: regionalAlertRedWBorder,
  amarilla: regionalAlertYellowWBorder,
  'temprana preventiva': regionalAlertGreenWBorder,
};

const RegionalAlertBlock: React.FC<any> = ({
  id,
  sinapredId,
  status,
  level,
  label,
}) => {
  return (
    <>
      {/* <div
        style={{
          width: '35px',
          height: '15px',
          border: '1px solid var(--success-dark)',
          margin: '0 20px',
        }}
      ></div> */}

      {/* <div
        className={`map-regions-counts-block map-regions-counts-block-active ${status.toLowerCase()}`}
        style={{
          position: 'relative',
          marginLeft: '25px',
          marginRight: '25px',
        }}
      ></div> */}

      <IonIcon
        src={regionalIcons[status.toLowerCase() as keyof typeof regionalIcons]}
        style={{
          width: '22px',
          height: '22px',
          marginLeft: '20px',
          marginRight: '20px',
        }}
        aria-label={
          'Alerta regional ' + status + ' ' + sinapredId + ' de ' + label
        }
      ></IonIcon>

      <div
        style={{
          flex: '1',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <p className="alert-button-caption">
          {level}
          {': '}
          {label}
        </p>
        <p key={id} className="alert-button-subcaption">
          {status}
          {' ID '}
          {sinapredId}
        </p>
      </div>

      <IonIcon
        icon={chevronForwardOutline}
        style={{
          height: '20px',
          width: '20px',
          margin: '0 20px',
          color: 'var(--itrend--gray-700)',
        }}
        aria-label={
          'Ver alerta regional ' + status + ' ' + sinapredId + ' de ' + label
        }
      />
    </>
  );
};

export default RegionalAlertBlock;
