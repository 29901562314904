/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router';

import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
  useIonViewDidEnter,
} from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import { NotIonBackButton, SaeDetailsModal } from '../components';
import AlertWrapper from '../components/Alert';
import CompleteListModal from '../components/CompleteListModal';
import MapModal from '../components/MapModalContent';
import { mapColors } from '../constants';
import { useSwipeable } from 'react-swipeable';
import { goBackHandler } from '../components/NotIonBackButton';
import { swipeConfig } from '../constants';
import {
  regionPrefix,
  regions as regionsConst,
  maxPixelsNumberFromEdge,
} from '../constants';
import { sendActivityToRum } from '../App';

const AlertDetails: React.FC<{ location: any }> = ({ location }) => {
  const { alertId } = useParams<{ alertId: string }>();
  const isFromNotification = location.state?.isFromNotification;

  const [headerData, setHeaderData] = useState({
    id: '',
    type: '',
    active: '',
    page: '',
  });
  const { id, type, active, page } = headerData;

  useEffect(() => {
    if (type) {
      const typeToSend = type.toLowerCase().replace(' ', '_');

      sendActivityToRum('alert', 'view', typeToSend);
    }
  }, [type]);

  const router = useIonRouter();

  // useIonViewDidEnter(() => {
  //   const urlsToGoToAlert = ['vigentes', 'suscripciones', 'canceladas'];
  //   let value = 'directly';
  //   if (router.routeInfo?.lastPathname) {
  //     const lastPathname = router.routeInfo?.lastPathname.split('/');

  //     if (lastPathname[lastPathname.length - 2] === 'regions') {
  //       value = 'mapa_regional';
  //     } else if (
  //       urlsToGoToAlert.includes(lastPathname[lastPathname.length - 1])
  //     ) {
  //       value = lastPathname[lastPathname.length - 1];
  //     }
  //   }
  //   console.log('alert', 'view', value);
  //   sendActivityToRum('alert', 'reached_from', value);
  // });

  useEffect(() => {
    const urlsToGoToAlert = ['vigentes', 'suscripciones', 'canceladas'];
    let value = isFromNotification ? 'notification' : 'directly';

    if (router.routeInfo?.lastPathname) {
      const lastPathname = router.routeInfo?.lastPathname.split('/');

      if (lastPathname[lastPathname.length - 2] === 'regions') {
        value = 'mapa_regional';
      } else if (
        urlsToGoToAlert.includes(lastPathname[lastPathname.length - 1])
      ) {
        value = lastPathname[lastPathname.length - 1];
      }
    }

    sendActivityToRum('alert', 'reached_from', value);
  }, []);

  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [mapModalProps, setMapModalProps] = useState<any>({});

  const [isCompleteListOpen, setIsCompleteListOpen] = useState(false);
  const [isMessageDetailsOpen, setIsMessageDetailsOpen] = useState(undefined);
  const [completeListProps, setCompleteListProps] = useState<any>([]);

  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventData) => {
      if (eventData.initial[0] > maxPixelsNumberFromEdge) {
        return;
      }

      if (isMapModalOpen) {
        setIsMapModalOpen(false);
        return;
      }
      if (isCompleteListOpen) {
        setIsCompleteListOpen(false);
        return;
      }
      if (isMessageDetailsOpen) {
        setIsMessageDetailsOpen(undefined);
        return;
      }

      goBackHandler(router);
    },
    ...swipeConfig,
  });

  const subTitle =
    mapModalProps.regions &&
    mapModalProps.mapUrl &&
    mapModalProps?.regions
      .map(
        (el2: any) =>
          regionPrefix[el2 as keyof typeof regionPrefix] +
          Object.keys(
            regionsConst.find(
              (el: any) => el[Object.keys(el)[0]] === el2
            ) as object
          )[0]
      )
      .join(', ');

  return (
    <IonPage {...swipeHandlers}>
      <IonHeader>
        <IonToolbar>
          {isMapModalOpen || isCompleteListOpen || isMessageDetailsOpen ? (
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  if (isMapModalOpen) {
                    return setIsMapModalOpen(false);
                  }
                  if (isCompleteListOpen) {
                    return setIsCompleteListOpen(false);
                  }
                  if (isMessageDetailsOpen) {
                    return setIsMessageDetailsOpen(undefined);
                  }
                }}
              >
                <IonIcon
                  style={{
                    color: 'var(--itrend--white)',
                    fontSize: '24px',
                  }}
                  icon={arrowBackOutline}
                  aria-label="Volver a las detalles de alerta"
                ></IonIcon>
              </IonButton>
            </IonButtons>
          ) : (
            <NotIonBackButton />
          )}

          <IonTitle className="ion-no-padding">
            {isMapModalOpen
              ? 'Detalle del mapa'
              : isCompleteListOpen
              ? 'Cobertura: listado completo'
              : isMessageDetailsOpen
              ? 'Mensaje SAE'
              : type + ' ' + id}
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      {mapModalProps.regions &&
        mapModalProps.mapUrl &&
        isMapModalOpen &&
        !isCompleteListOpen &&
        isMessageDetailsOpen === undefined && (
          <MapModal
            mapUrl={`${mapModalProps?.mapUrl}&sc=${
              active
                ? mapColors[mapModalProps?.status as keyof typeof Object]
                : mapColors['Inactive' as keyof typeof Object]
            }`}
            title={mapModalProps?.level as string}
            // subTitle={regions?.join(', ') || ''}
            subTitle={subTitle}
            len={mapModalProps?.regions?.length || 0}
            active={active}
          />
        )}

      {isCompleteListOpen &&
        !isMapModalOpen &&
        completeListProps.length &&
        isMessageDetailsOpen === undefined && (
          <CompleteListModal
            completeListProps={completeListProps}
            active={active}
          />
        )}

      {isMessageDetailsOpen && !isCompleteListOpen && !isMapModalOpen && (
        <SaeDetailsModal
          massageDetails={isMessageDetailsOpen}
          active={active}
        />
      )}

      {!isMapModalOpen &&
        !isCompleteListOpen &&
        isMessageDetailsOpen === undefined && (
          <AlertWrapper
            alertId={alertId}
            page={page}
            setHeaderData={setHeaderData}
            setIsMapModalOpen={setIsMapModalOpen}
            setMapModalProps={setMapModalProps}
            setCompleteListProps={setCompleteListProps}
            setIsCompleteListOpen={setIsCompleteListOpen}
            subTitle={subTitle}
            len={mapModalProps?.regions?.length || 0}
            setIsMessageDetailsOpen={setIsMessageDetailsOpen}
          />
        )}
    </IonPage>
  );
};

export default withRouter(AlertDetails);
