import './Vigentes.css';

import React from 'react';

import { AlertsList } from '../components';
import { setSortByVigentes } from '../features/settings/settingsActions';
import { useAppSelector } from '../hooks';
import { RootState } from '../store';

const Vigentes: React.FC<{ refMenu: any; setIsFilterMenuOpen: any }> = ({
  refMenu,
  setIsFilterMenuOpen,
}) => {
  const { sortByVigentes } = useAppSelector(
    (state: RootState) => state.settings
  );

  return (
    <>
      <AlertsList
        setIsFilterMenuOpen={setIsFilterMenuOpen}
        refMenu={refMenu}
        pageName={'Vigentes'}
        setSortBy={setSortByVigentes}
        sortBy={sortByVigentes}
      />
    </>
  );
};

export default Vigentes;
