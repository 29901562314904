import { IonContent, IonModal } from '@ionic/react';
import React from 'react';

const AlertSymbolsLegendModal: React.FC<any> = ({
  isOpen,
  setIsOpen,
  withCounts,
  trigger = '',
}) => {
  return (
    <IonModal
      id="legend-modal"
      isOpen={isOpen}
      onDidDismiss={() => setIsOpen(false)}
      trigger={trigger}
    >
      {/* <IonContent className="legend-modal-container"> */}
      <div
        style={{
          paddingLeft: '25px',
          paddingRight: '25px',
        }}
      >
        <p className="legend-modal-header">
          Niveles de cobertura de una alerta
        </p>
      </div>

      <div
        style={{
          paddingLeft: '25px',
          paddingRight: '25px',
        }}
        className="legend-modal-elements"
      >
        <div className="legend-modal-elements-element">
          <div
            style={{
              // position: 'relative',
              width: '90px',
              height: '40px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              paddingLeft: '8px',
            }}
          >
            <div className="map-regions-counts-block map-regions-counts-block-active roja"></div>
            <div className="map-regions-counts-block map-regions-counts-block-active amarilla"></div>
            <div className="map-regions-counts-block map-regions-counts-block-active temprana-preventiva"></div>
            {/* <div className="legend-modal-elements-element-chip legend-modal-elements-element-chip-big">
              <p style={{ width: '100%' }}>Región</p>
            </div>
            <div className="legend-modal-elements-element-chip legend-modal-elements-element-chip-big"></div>
            <div className="legend-modal-elements-element-chip legend-modal-elements-element-chip-big"></div>
            <div className="legend-modal-elements-element-chip legend-modal-elements-element-chip-big"></div> */}
          </div>

          <div className="legend-modal-elements-element-arrow">
            <span className="arrow-right"></span>
          </div>

          <div
            style={{
              width: '50%',
            }}
          >
            Alerta regional
          </div>
        </div>
        <div className="legend-modal-elements-element">
          <div
            style={{
              width: '90px',
              paddingLeft: 'var(--space-3)',
              display: 'flex',
            }}
          >
            <div className="legend-modal-elements-element-chip">
              {withCounts && '#'}
            </div>
            <div className="legend-modal-elements-element-chip">
              {withCounts && '#'}
            </div>
            <div className="legend-modal-elements-element-chip">
              {withCounts && '#'}
            </div>
          </div>

          <div className="legend-modal-elements-element-arrow">
            <span className="arrow-right"></span>
          </div>

          <div
            style={{
              width: '50%',
            }}
          >
            {withCounts
              ? 'Cantidad de alertas provinciales'
              : 'Alerta provincial'}
          </div>
        </div>
        <div className="legend-modal-elements-element">
          <div
            style={{
              width: '90px',
              paddingLeft: 'var(--space-3)',
              display: 'flex',
            }}
          >
            {['#', '#', '#'].map((el: any, ind: any) => {
              return (
                <div
                  key={ind}
                  className="legend-modal-elements-element-chip"
                  style={{
                    borderRadius: '50%',
                  }}
                >
                  {withCounts && el}
                </div>
              );
            })}
          </div>

          <div className="legend-modal-elements-element-arrow">
            <span className="arrow-right"></span>
          </div>

          <div
            style={{
              width: '50%',
            }}
          >
            {withCounts ? 'Cantidad de alertas comunales' : 'Alerta comunal'}
          </div>
        </div>
      </div>
      {/* </IonContent> */}
    </IonModal>
  );
};

export default AlertSymbolsLegendModal;
