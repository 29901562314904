import { IonButton, IonButtons, IonIcon, useIonRouter } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import React from 'react';
import { useLocation } from 'react-router';
import { ROUTES, makeGoBackUrl } from '../constants';

export const goBackHandler = (router: any, mapRemove: any = undefined) => {
  const isShared = location.pathname.includes(ROUTES.shared.path);
  const isEvent = location.pathname.includes(
    ROUTES.eventDetails.path.split('/')[1]
  );

  if (isShared && !isEvent) {
    return router.push('/');
  }

  if (mapRemove) mapRemove();

  return router.push(makeGoBackUrl(location as unknown as Location, 2));
};

const NotIonBackButton: React.FC<any> = ({
  active = true,
  mapRemove = undefined,
}) => {
  const router = useIonRouter();
  const location = useLocation();

  // const isRegionMap = location.pathname.includes(ROUTES.map.path);

  return (
    <IonButtons slot="start">
      <IonButton
        disabled={!active}
        onClick={() => goBackHandler(router, mapRemove)}
      >
        <IonIcon
          style={{
            color: 'var(--itrend--white)',
            fontSize: '24px',
          }}
          icon={arrowBackOutline}
          aria-label="Ir atrás en esta página"
        ></IonIcon>
      </IonButton>
    </IonButtons>
  );
};

export default NotIonBackButton;
