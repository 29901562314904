/* eslint-disable @typescript-eslint/ban-types */
// /* eslint-disable @typescript-eslint/ban-ts-comment */
import './AlertCard.scss';

// // @ts-nocheck
import { IonSkeletonText } from '@ionic/react';
import React, { useEffect, useRef } from 'react';
import { regionPrefix, regions as regionsConst } from '../constants';

export const makeLocationsTitleAndText = (
  level: any,
  alertLocationsData: any,
  region: any,
  regions: any
) => {
  const regionName =
    region &&
    Object.keys(
      regionsConst.find(
        (el: any) => el[Object.keys(el)[0]] === region
      ) as object
    )[0];

  // 2 step
  const regionsNames =
    regions &&
    regions.map(
      (el2: any) =>
        Object.keys(
          regionsConst.find(
            (el: any) => el[Object.keys(el)[0]] === el2
          ) as object
        )[0]
    );

  const provincesInRegion =
    alertLocationsData &&
    Array.from(
      new Set(
        alertLocationsData.data
          .filter((el: any) => el.regionData.cutRegion === region)
          .map((el: any) => el.provinceData.label)
      )
    );
  const comunesInRegion =
    alertLocationsData &&
    Array.from(
      new Set(
        alertLocationsData.data
          .filter((el: any) => el.regionData.cutRegion === region)
          .map((el: any) => el.comuneData.label)
      )
    );

  const plainTitle =
    level === 'Comunal'
      ? comunesInRegion.length +
        ' Comuna' +
        (comunesInRegion.length > 1 ? 's' : '') +
        ` en Región ${regionPrefix[region as keyof Object]}${regionName}: `
      : level === 'Provincial'
      ? provincesInRegion.length +
        ' Provincia' +
        (provincesInRegion.length > 1 ? 's' : '') +
        ` en Región ${regionPrefix[region as keyof Object]}${regionName}: `
      : regions &&
        regions.length + (regions.length > 1 ? ' regiones' : ' región');

  const title =
    level === 'Comunal' ? (
      <>
        <span style={{ fontWeight: 'var(--font-weight-bold)' }}>
          {comunesInRegion.length +
            ' Comuna' +
            (comunesInRegion.length > 1 ? 's' : '')}
        </span>
        {` en Región ${regionPrefix[region as keyof Object]}${regionName}: `}
      </>
    ) : level === 'Provincial' ? (
      <>
        <span style={{ fontWeight: 'var(--font-weight-bold)' }}>
          {provincesInRegion.length +
            ' Provincia' +
            (provincesInRegion.length > 1 ? 's' : '')}{' '}
        </span>
        {` en Región ${regionPrefix[region as keyof Object]}${regionName}: `}
      </>
    ) : (
      <>
        <span style={{ fontWeight: 'var(--font-weight-bold)' }}>
          {regions &&
            regions.length + (regions.length > 1 ? ' regiones' : ' región')}
        </span>
      </>
    );

  const text =
    regions &&
    (level === 'Regional'
      ? regionsNames.join(', ')
      : level === 'Provincial'
      ? provincesInRegion.join(', ')
      : comunesInRegion.join(', '));

  return { plainTitle, title, text };
};

export const CardLocationView: React.FC<any> = ({
  alertLocationsData,
  textClamped,
  regions,
  region,
  level,
  linesToCut = 1, // dafult value is applied to alert card in a list of alerts
  active,
  source = '',
  shareData,
  setShareData,
}) => {
  const { title, text } = makeLocationsTitleAndText(
    level,
    alertLocationsData,
    region,
    regions
  );

  useEffect(() => {
    if (level === 'Regional' && shareData) {
      const locations: any[] = [];
      const title =
        regions &&
        regions.length + (regions.length > 1 ? 'regiones' : ' región');
      const text =
        regions &&
        regions
          .map(
            (el2: any) =>
              Object.keys(
                regionsConst.find(
                  (el: any) => el[Object.keys(el)[0]] === el2
                ) as object
              )[0]
          )
          .join(', ');

      locations.push({ title, text });

      setShareData({
        ...shareData,
        locationsNum: regions && regions.length,
        locations,
      });
    }

    if (alertLocationsData && shareData) {
      const locations = [];

      if (level === 'Regional') {
        const { plainTitle, text } = makeLocationsTitleAndText(
          level,
          alertLocationsData,
          '',
          regions
        );

        locations.push({ title: plainTitle, text });
      } else {
        locations.push(
          ...regions.map((region: any, index: any) => {
            const { plainTitle, text } = makeLocationsTitleAndText(
              level,
              alertLocationsData,
              region,
              regions
            );
            return { title: plainTitle, text };
          })
        );
      }

      setShareData({
        ...shareData,
        locationsNum: alertLocationsData.length,
        locations,
      });
    }
  }, [alertLocationsData]);

  return (
    <div className="alert-card-locations">
      <p
        className={`alert-card-locations-caption ${
          !active &&
          source !== 'details' &&
          'alert-card-locations-caption-inactive'
        }`}
      >
        {title}
      </p>
      <p
        ref={textClamped}
        className={`text-cut-${linesToCut}-line alert-card-locations-text ${
          !active &&
          source !== 'details' &&
          'alert-card-locations-text-inactive'
        }`}
        style={{
          marginTop: '0px',
        }}
      >
        {text}
      </p>
    </div>
  );
};

export const CardLocationSkeleton: React.FC = () => {
  return (
    <>
      <div className="alert-card-locations">
        <p className={'alert-card-locations-caption'}>
          <IonSkeletonText animated={true}></IonSkeletonText>
        </p>
        <p
          className={'alert-card-locations-text'}
          style={{
            marginTop: '0px',
          }}
        >
          <IonSkeletonText animated={true}></IonSkeletonText>
        </p>
      </div>
      <div className="alert-card-locations">
        <p className={'alert-card-locations-caption'}>
          <IonSkeletonText animated={true}></IonSkeletonText>
        </p>
        <p
          className={'alert-card-locations-text'}
          style={{
            marginTop: '0px',
          }}
        >
          <IonSkeletonText animated={true}></IonSkeletonText>
        </p>
      </div>
    </>
  );
};

const CardLocation: React.FC<any> = ({
  lastUpdateId,
  level,
  active,
  setLocationNum,
  shareData,
  setShareData,
  alertLocationsData,
}) => {
  const comunes =
    alertLocationsData &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Array.from(
      new Set(alertLocationsData.data.map((el: any) => el.comuneData.label))
    );

  const provinces =
    alertLocationsData &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Array.from(
      new Set(alertLocationsData.data.map((el: any) => el.provinceData.label))
    );

  const regions =
    alertLocationsData &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Array.from(
      new Set(alertLocationsData.data.map((el: any) => el.regionData.cutRegion))
    );

  const locationsNum =
    alertLocationsData &&
    (level === 'Nacional'
      ? 'Nacional'
      : level === 'Regional'
      ? `${regions.length} ${regions.length > 1 ? 'Regiones' : 'Región'}`
      : level === 'Provincial'
      ? `${provinces.length} ${
          provinces.length > 1 ? 'Provincias' : 'Provincia'
        }`
      : `${comunes.length} ${comunes.length > 1 ? 'Comunas' : 'Comuna'}`);

  useEffect(() => {
    setLocationNum(locationsNum);

    if (alertLocationsData) {
      const locations = [];

      if (level === 'Regional') {
        const { plainTitle, text } = makeLocationsTitleAndText(
          level,
          alertLocationsData,
          '',
          regions
        );

        locations.push({ title: plainTitle, text });
      } else {
        locations.push(
          ...regions.map((region: any, index: any) => {
            const { plainTitle, text } = makeLocationsTitleAndText(
              level,
              alertLocationsData,
              region,
              regions
            );
            return { title: plainTitle, text };
          })
        );
      }

      setShareData({ ...shareData, locationsNum, locations });
    }
  }, [alertLocationsData]);

  const textClamped = useRef(null);

  return (
    <>
      {(regions &&
        provinces &&
        comunes &&
        (level === 'Regional' ? (
          <CardLocationView
            textClamped={textClamped}
            regions={regions}
            level={level}
            active={active}
          />
        ) : (
          regions.map((region: any, index: any) => (
            <CardLocationView
              textClamped={textClamped}
              alertLocationsData={alertLocationsData}
              key={index}
              regions={regions}
              level={level}
              active={active}
              region={region}
            />
          ))
        ))) || <CardLocationSkeleton />}
    </>
  );
};

export default CardLocation;
