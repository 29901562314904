import './HealthCheckNotifications.css';

import React from 'react';

import { HealthCheckNotificationsComponent } from '../components';

const HealthCheckNotifications: React.FC = () => {
  return (
    <>
      <HealthCheckNotificationsComponent />
    </>
  );
};

export default HealthCheckNotifications;
