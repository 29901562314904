import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { Timeline } from 'primereact/timeline';
import React from 'react';
import './TimeLine.scss';
export interface AlertTimelineItem {
  update: {
    id: number;
    actionData: { name: string };
    statusData: { name: string };
    levelData: { name: string };
    updateTime: string;
  };
  icon: string;
  color: string;
}

const TimeLineCustom: React.FC<{
  timeline: any;
  marker: any;
  type?: string;
  elemClassName?: string;
}> = ({ timeline, marker, type = 'Alert', elemClassName = '--caption' }) => {
  return (
    <Timeline
      style={{ marginBottom: '2rem' }}
      value={timeline}
      layout="vertical"
      align="left"
      className={`${type === 'Comments' && 'p-timeline-no-connector'}`}
      content={(item: any) => (
        <IonGrid className={'ion-no-padding ion-no-margin'}>
          <IonRow>
            <IonCol>
              <IonText className={'alert-timeline-event-header'}>
                {type === 'Alert'
                  ? item.update.actionData['name'] +
                    ' Alerta ' +
                    item.update.statusData['name'] +
                    ' ' +
                    item.update.levelData['name']
                  : item.incident.statusData['name']}
              </IonText>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonText
                className={'alert-timeline-event-time'}
                style={{
                  fontSize: `var(${elemClassName})`,
                }}
              >
                {type === 'Alert'
                  ? format(
                      new Date(item.update.updateTime),
                      "dd LLLL yyyy 'a las' HH:mm",
                      {
                        locale: es,
                      }
                    )
                  : format(
                      new Date(item.incident.incidentDatetime),
                      "dd LLLL yyyy 'a las' HH:mm",
                      { locale: es }
                    )}
              </IonText>
            </IonCol>
          </IonRow>

          {type === 'Comments' && (
            <IonRow>
              <IonCol>
                <p
                  style={{
                    paddingTop: 'var(--space-1)',
                    paddingBottom: 'var(--space-2)',
                  }}
                  className={'alert-timeline-event-text-md'}
                >
                  {item.incident.comments}
                </p>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      )}
      marker={marker}
    />
  );
};

export default TimeLineCustom;
