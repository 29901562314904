import React, { Fragment, useState, useEffect, useRef } from 'react';
import './Guide.scss';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToggle,
  IonToolbar,
  IonBackButton,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from '@ionic/react';
import { makeGoBackUrl } from '../../constants';
import openLink from '../../theme/icons/open_in_new.svg';
import { notificationsGuides } from '../../constants';
import { useSwipeable } from 'react-swipeable';
import { swipeConfig, maxPixelsNumberFromEdge } from '../../constants';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { Capacitor } from '@capacitor/core';

const Guide: React.FC = () => {
  const router = useIonRouter();
  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventData) => {
      if (eventData.initial[0] > maxPixelsNumberFromEdge) {
        return;
      }
      router.push(makeGoBackUrl(window.location, 1));
    },
    ...swipeConfig,
  });

  const [easterCount, setEasterCount] = useState(0);
  const [debouncedCount] = useDebounce(easterCount, 1000);
  const easterLimit = 7;
  const history = useHistory();
  const easterElementRef = useRef<HTMLSpanElement>(null);

  const easterHandler = () => {
    if (easterCount >= easterLimit) {
      history.push(ROUTES.healthCheckNotifications.path);
    } else {
      setEasterCount(easterCount + 1);
    }
  };

  useEffect(() => {
    setEasterCount(0);
  }, [debouncedCount]);

  function handleClickOutside(event: any) {
    if (
      easterElementRef.current &&
      !easterElementRef.current.contains(event.target)
    ) {
      setEasterCount(0);
    }
  }

  useIonViewDidEnter(() => {
    if (Capacitor.isNativePlatform()) {
      document.addEventListener('touchstart', handleClickOutside);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }
  });

  useIonViewDidLeave(() => {
    if (Capacitor.isNativePlatform()) {
      document.removeEventListener('touchstart', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  });

  return (
    <IonPage {...swipeHandlers}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={makeGoBackUrl(window.location, 1)} />
          </IonButtons>
          <IonTitle
            style={{
              paddingLeft: '0px',
            }}
          >
            Cómo activar las{' '}
            <span
              onClick={() => {
                easterHandler();
              }}
              ref={easterElementRef}
            >
              notificaciones
            </span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="ion-content-custom-background">
        <IonItem lines="full" className="guide-banner">
          <p className="guide-banner-text">
            Para activar las notificaciones, sigue las instrucciones para tu
            aplicación o navegador
          </p>
        </IonItem>
        {notificationsGuides.map((guide, index) => {
          return (
            <Fragment key={'guide-group-' + index}>
              <IonTitle
                key={guide.title}
                className="ion-margin-bottom ion-margin-top settings-title"
              >
                {guide.title}
              </IonTitle>
              <div className="guide-content-block">
                {guide.items?.map((item, index) => {
                  return (
                    <IonItem
                      key={guide.title + '-' + item.title}
                      lines="full"
                      href={item.link}
                      target="_blank"
                    >
                      <IonIcon
                        icon={openLink}
                        slot="end"
                        size="small"
                        aria-label={item.label}
                      ></IonIcon>
                      <IonLabel>
                        <h2>{item.title}</h2>
                      </IonLabel>
                    </IonItem>
                  );
                })}
              </div>
            </Fragment>
          );
        })}
      </IonContent>
    </IonPage>
  );
};

export default Guide;
