/* eslint-disable @typescript-eslint/no-explicit-any */
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import { useHistory } from 'react-router';
import {
  ROUTES,
  mapBlueGrayColor,
  mapCountryBorderColor,
  mapRegionFillColor,
  mapRegionWeight,
  countryRings,
} from '../../constants';
import { sampleGeoJson } from '../../constants/regiones_chile';
import './Map.scss';
import { reagionHasNotNationalAlerts } from '../../pages/Map';

const Map: React.FC<any> = ({ regionsWithCounts, height, width }) => {
  const routerHistory = useHistory();

  const map = useMap();

  useEffect(() => {
    map.invalidateSize();
  }, [width, height]);

  const mapStyles = {
    fillColor: mapBlueGrayColor,
    color: mapCountryBorderColor,
    weight: mapRegionWeight,
    // fillOpacity: mapRegionFillOpacity,
    fillOpacity: 0.4,
  };

  const getMaxAlertLevel = (region: any) => {
    if (
      region.activeRedAlerts.meta.count -
        region.activeNationalRedAlerts.meta.count >
      0
    ) {
      return 'roja';
    }

    if (
      region.activeYellowAlerts.meta.count -
        region.activeNationalYellowAlerts.meta.count >
      0
    ) {
      return 'amarilla';
    }

    if (
      region.activeGreenAlerts.meta.count -
        region.activeNationalGreenAlerts.meta.count >
      0
    ) {
      return 'temprana preventiva';
    }

    return null;
  };

  const geoJsonLayer = L.geoJSON([], {
    style: function (feature: any) {
      return {
        ...mapStyles,
        fillColor:
          mapRegionFillColor[
            getMaxAlertLevel(
              regionsWithCounts.find((el: any) => {
                return el.cutRegion === String(feature.properties.CUT_REG);
              })
            ) as keyof typeof mapRegionFillColor
          ],
      };

      return mapStyles;
    },
    onEachFeature: onEachFeature,
  });

  const geoJsonLayerRef = useRef(geoJsonLayer);

  useEffect(() => {
    if (geoJsonLayer && geoJsonLayerRef.current) {
      geoJsonLayerRef.current.clearLayers();
      geoJsonLayerRef.current.addData(sampleGeoJson as any);
      geoJsonLayerRef.current.addTo(map);

      // map.fitBounds(geoJsonLayerRef.current.getBounds());
      map.fitBounds(countryRings);
    }
  }, [geoJsonLayer]);

  const goToVigentesPage = (region: string) => {
    routerHistory.push(ROUTES.map.path + '/regions/' + region);
  };

  function onEachFeature(feature: any, layer: any) {
    layer.on('click', function (e: any) {
      const region = regionsWithCounts.find((el: any) => {
        return (
          el.cutRegion === String(e.sourceTarget.feature.properties.CUT_REG)
        );
      });

      if (reagionHasNotNationalAlerts(region)) {
        goToVigentesPage(e.sourceTarget.feature.properties.CUT_REG);
      }
    });
  }

  useEffect(() => {
    map.attributionControl.remove();
    map.off('touchstart');
    map.off('touchmove');
    map.off('touchend');
  }, []);

  return null;
};

export default Map;
