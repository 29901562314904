import './Suscripciones.css';

import React from 'react';

import { AlertsList } from '../components';
import { setSortBySuscripciones } from '../features/settings/settingsActions';
import { useAppSelector } from '../hooks';
import { RootState } from '../store';

const Suscripciones: React.FC = () => {
  const { sortBySuscripciones } = useAppSelector(
    (state: RootState) => state.settings
  );

  return (
    <>
      <AlertsList
        pageName={'Suscripciones'}
        setSortBy={setSortBySuscripciones}
        sortBy={sortBySuscripciones}
      />
    </>
  );
};

export default Suscripciones;
