/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';

import {
  addCardToFavoritesWithoutSubscribingBulk,
  addCardToFavorites,
  addCardToFavoritesBulk,
  addCardToFavoritesBulkRaw,
  disableUnfollowMessages,
  removeCardFromFavorites,
  removeCardFromFavoritesBulkRaw,
  removeCardsFromFavorites,
  setAlertIdToRemoveFromList,
  toggleAlertsListUpdate,
} from './favoritesActions';

interface FavoritesState {
  currentFavorites: string[];
  historyFavorites: string[];
  unfollowMessagesEnabled: boolean;
  alertsListShouldBeUpdated: boolean;
  alertIdToRemoveFromList: string;
}

const initialState: FavoritesState = {
  currentFavorites: [],
  historyFavorites: [],
  unfollowMessagesEnabled: true,
  alertsListShouldBeUpdated: false,
  alertIdToRemoveFromList: '',
};

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* *********************** ADD CARD TO FAVORITES ********** */
    builder.addCase(
      addCardToFavoritesWithoutSubscribingBulk.fulfilled,
      (state, { payload }) => {
        const cardIds = payload;
        for (const cardId of cardIds) {
          /* Add to current favorites, if not present */
          if (!state.currentFavorites.includes(cardId)) {
            state.currentFavorites.push(cardId);
          }

          /* Keep track in history, if not present */
          if (!state.historyFavorites.includes(cardId)) {
            state.historyFavorites.push(cardId);
          }
        }
      }
    );

    builder.addCase(addCardToFavoritesBulk.fulfilled, (state, { payload }) => {
      const cardIds = payload;
      for (const cardId of cardIds) {
        /* Add to current favorites, if not present */
        if (!state.currentFavorites.includes(cardId)) {
          state.currentFavorites.push(cardId);
        }

        /* Keep track in history, if not present */
        if (!state.historyFavorites.includes(cardId)) {
          state.historyFavorites.push(cardId);
        }
      }
    });

    builder.addCase(
      addCardToFavoritesBulkRaw.fulfilled,
      (state, { payload }) => {
        const cardIds = payload;
        for (const cardId of cardIds) {
          /* Add to current favorites, if not present */
          if (!state.currentFavorites.includes(cardId)) {
            state.currentFavorites.push(cardId);
          }

          /* Keep track in history, if not present */
          if (!state.historyFavorites.includes(cardId)) {
            state.historyFavorites.push(cardId);
          }
        }
      }
    );

    builder.addCase(addCardToFavorites.fulfilled, (state, { payload }) => {
      const cardId = payload;
      if (!state.currentFavorites.includes(cardId)) {
        state.currentFavorites.push(cardId);
      }

      /* Add the cardId to the historical favorites data to
      keep track of when "first" vs "latter" usages occur */
      if (!state.historyFavorites.includes(cardId)) {
        state.historyFavorites.push(cardId);
      }
    });

    /* *********************** REMOVE SINGLE CARD FROM FAVORITES ********** */
    builder.addCase(
      removeCardFromFavoritesBulkRaw.fulfilled,
      (state, { payload }) => {
        const cardIds = new Set(payload);
        state.currentFavorites = [
          ...state.currentFavorites.filter((f) => !cardIds.has(f)),
        ];
      }
    );

    /* Notice we don't remove cardId from historyFavorites, as this historical data
    allows us to determine whether to show alerts on "first adding" or not. */
    builder.addCase(removeCardFromFavorites.fulfilled, (state, { payload }) => {
      const cardId = payload;
      if (state.currentFavorites.includes(cardId)) {
        state.currentFavorites = [
          ...state.currentFavorites.filter((f) => f !== cardId),
        ];
      }
    });

    /* *********************** REMOVE LIST OF CARDS FROM FAVORITES ********** */
    /* Notice we don't remove cardId from historyFavorites, as this historical data
    allows us to determine whether to show alerts on "first adding" or not. */
    builder.addCase(
      removeCardsFromFavorites.fulfilled,
      (state, { payload }) => {
        const cardIds = new Set(payload);
        state.currentFavorites = [
          ...state.currentFavorites.filter((f) => !cardIds.has(f)),
        ];
      }
    );

    /* *********************** HANDLE TURNING OFF UNFOLLOW MESSAGES ********** */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(disableUnfollowMessages.fulfilled, (state, _) => {
      state.unfollowMessagesEnabled = false;
    });

    builder.addCase(toggleAlertsListUpdate.fulfilled, (state, { payload }) => {
      state.alertsListShouldBeUpdated = payload;
    });
    builder.addCase(
      setAlertIdToRemoveFromList.fulfilled,
      (state, { payload }) => {
        state.alertIdToRemoveFromList = payload;
      }
    );
  },
});

export default favoritesSlice.reducer;
