import './Regions.scss';

import React from 'react';

import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';

import { makeGoBackUrl } from '../../constants';
import {
  AlertType,
  alertType2name,
  RegionCode,
  regionCodes,
} from '../../features/notifications/notifications';
import {
  addAllRegionsToNotifications,
  addRegionToNotifications,
  removeAllRegionsToNotifications,
  removeRegionFromNotifications,
} from '../../features/notifications/notificationsActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { useSwipeable } from 'react-swipeable';
import { swipeConfig, maxPixelsNumberFromEdge } from '../../constants';

type RegionAlertReducerState = {
  globalUpdate: boolean;
  globalUpdateRegions: Record<RegionCode, boolean>;
  localRegionState: Record<RegionCode, boolean>;
};

type RegionAlertReducerAction = {
  type: 'set_global_value' | 'set_local_region' | 'set_local_regions';
  alertType: AlertType;
  region?: string;
  regions?: Record<RegionCode, boolean>;
  value?: boolean | Record<string, boolean>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (
  state: RegionAlertReducerState,
  action: RegionAlertReducerAction
): RegionAlertReducerState => {
  if (
    action.type === 'set_global_value' &&
    action.region &&
    action.value !== undefined
  ) {
    return {
      globalUpdate: true,
      localRegionState: { ...state.localRegionState },
      globalUpdateRegions: {
        ...state.globalUpdateRegions,
        [action.region]: action.value,
      },
    };
  }
  if (
    action.type === 'set_local_region' &&
    action.region &&
    action.value !== undefined
  ) {
    return {
      globalUpdate: state.globalUpdate,
      globalUpdateRegions: { ...state.globalUpdateRegions },
      localRegionState: {
        ...state.localRegionState,
        [action.region]: action.value,
      },
    };
  }
  if (action.type === 'set_local_regions' && action.regions) {
    return {
      globalUpdate: state.globalUpdate,
      globalUpdateRegions: Object.fromEntries(
        regionCodes.map((c) => [c, true])
      ) as Record<RegionCode, boolean>,

      localRegionState: action.regions,
    };
  }

  // If there are no modifications
  return state;
};

const Regions: React.FC<{ alertType: AlertType }> = ({ alertType }) => {
  const dispatch = useAppDispatch();

  const {
    isNotificationsUpdating: isNotificationsUpdating,
    notificationUpdatingRegionCode: notification_updating_region_code,
    regionsByType,
  } = useAppSelector((state: RootState) => state.notifications);

  const regions = regionsByType[alertType as AlertType] || {};

  const [allRegions, setAllRegions] = React.useState(
    regionCodes.every((r) => regions[r] === true)
  );
  const regionCode2Name: Record<RegionCode, string> = {
    aricaParinacota: 'Arica y Parinacota',
    tarapaca: 'Tarapacá',
    antofagasta: 'Antofagasta',
    atacama: 'Atacama',
    coquimbo: 'Coquimbo',
    valparaiso: 'Valparaíso',
    metropolitana: 'Metropolitana',
    ohiggins: "O'Higgins",
    maule: 'Maule',
    nuble: 'Ñuble',
    biobio: 'Biobío',
    araucania: 'Araucanía',
    los_rios: 'Los Ríos',
    los_lagos: 'Los Lagos',
    aysen: 'Aysén',
    magallanes: 'Magallanes',
  };

  const [localState, localDispatch] = React.useReducer(reducer, {
    globalUpdate: false,
    globalUpdateRegions: Object.fromEntries(
      regionCodes.map((c) => [c, false])
    ) as Record<RegionCode, boolean>,
    localRegionState: regions,
  });
  const prevLocalRegionState = React.useRef(localState?.localRegionState);

  React.useEffect(() => {
    Object.keys(localState?.localRegionState).map((key: string) => {
      const regionCode = key as RegionCode;
      if (
        localState?.localRegionState[regionCode] !==
        prevLocalRegionState.current[regionCode]
      ) {
        localDispatch({
          type: 'set_global_value',
          region: key,
          value: false,
          alertType: alertType,
        });
        if (!localState?.globalUpdateRegions[regionCode]) {
          localState?.localRegionState[regionCode]
            ? dispatch(
                addRegionToNotifications({ region: regionCode, alertType })
              )
            : dispatch(
                removeRegionFromNotifications({ region: regionCode, alertType })
              );
        }
      }
    });
    prevLocalRegionState.current = localState?.localRegionState;
  }, [
    localState?.localRegionState,
    localState?.globalUpdateRegions,
    localDispatch,
  ]);

  React.useEffect(() => {
    Object.keys(localState?.localRegionState).map((key: string) => {
      const regionCode = key as RegionCode;
      if (!localState?.globalUpdateRegions[regionCode]) {
        localDispatch({
          type: 'set_local_region',
          region: regionCode,
          value: regions[regionCode],
          alertType: alertType,
        });
      }
    });
  }, [regions]);

  const router = useIonRouter();
  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventData) => {
      if (eventData.initial[0] > maxPixelsNumberFromEdge) {
        return;
      }

      router.push(makeGoBackUrl(window.location, 1));
    },
    ...swipeConfig,
  });

  return (
    <IonPage {...swipeHandlers}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={makeGoBackUrl(window.location, 1)}
              disabled={isNotificationsUpdating}
            />
          </IonButtons>
          <IonTitle>Alerta {alertType2name[alertType]} por Regiones</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-content-custom-background">
        <IonTitle className="ion-margin-bottom ion-margin-top settings-title">
          Recibir notificaciones de
        </IonTitle>

        <div className="regions-content-block">
          <IonItem style={{ paddingBottom: 'var(--space-4)' }}>
            <IonLabel>Todas las regiones</IonLabel>

            {isNotificationsUpdating &&
              notification_updating_region_code === 'all' && (
                <IonSpinner
                  className="alert-card-buttons-fav fav-spinner"
                  style={{
                    width: 'var(--headline-medium)',
                    heigth: 'var(--headline-medium)',
                  }}
                  name="circular"
                ></IonSpinner>
              )}

            {notification_updating_region_code !== 'all' && (
              <IonToggle
                disabled={isNotificationsUpdating}
                slot="end"
                checked={allRegions}
                onIonChange={async (evt) => {
                  setAllRegions(evt.detail.checked);
                  const newRegions = Object.fromEntries(
                    Object.entries(localState?.localRegionState).map((pair) => [
                      pair[0],
                      evt.detail.checked,
                    ])
                  );
                  localDispatch({
                    type: 'set_local_regions',
                    regions: newRegions as Record<RegionCode, boolean>,
                    alertType: alertType,
                  });
                  evt.detail.checked
                    ? dispatch(addAllRegionsToNotifications({ alertType }))
                    : dispatch(removeAllRegionsToNotifications({ alertType }));
                }}
              ></IonToggle>
            )}
          </IonItem>

          {regionCodes &&
            regionCodes.map((r: RegionCode) => (
              <IonItem key={r}>
                <IonLabel>{regionCode2Name[r]}</IonLabel>
                {isNotificationsUpdating &&
                  notification_updating_region_code === r && (
                    <IonSpinner
                      className="alert-card-buttons-fav fav-spinner"
                      style={{
                        width: 'var(--headline-medium)',
                        heigth: 'var(--headline-medium)',
                      }}
                      name="circular"
                    ></IonSpinner>
                  )}

                {notification_updating_region_code !== r && (
                  <IonToggle
                    disabled={isNotificationsUpdating}
                    slot="end"
                    checked={localState?.localRegionState[r]}
                    onIonChange={async (evt) => {
                      if (localState?.globalUpdateRegions[r]) {
                        // do nothing when update comes from All regions
                        return;
                      }
                      localDispatch({
                        type: 'set_local_region',
                        region: r,
                        value: evt.detail.checked,
                        alertType: alertType,
                      });
                    }}
                  ></IonToggle>
                )}
              </IonItem>
            ))}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Regions;
