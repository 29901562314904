import './Profile.scss';

import React from 'react';

import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  useIonAlert,
} from '@ionic/react';
import {
  AccountSettings,
  useAuthenticator,
  Button,
  Card,
  Flex,
  Heading,
  Text,
  TextField,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

const CustomWarning: React.FC<any> = ({ onCancel, onConfirm, isDisabled }) => {
  const [value, setValue] = React.useState('');

  const handleChange = (event: any) => {
    event.preventDefault();

    const { value } = event.target;
    setValue(value);
  };

  return (
    <form>
      <Flex direction="column" style={{ textAlign: 'center' }}>
        <p
          style={{
            margin: '0',
            fontWeight: 'var(--font-weight-bold)',
            color: 'var(--error-base)',
          }}
        >
          ATENCIÓN
        </p>
        <p
          style={{
            margin: '0',
            color: 'var(--error-base)',
          }}
        >
          Perderá el acceso a la aplicación y todos sus datos serán eliminados.
          Por favor escriba eliminar a continuación si desea confirmar la
          eliminación de su cuenta.
        </p>
        <p
          style={{
            margin: '0',
            fontWeight: 'var(--font-weight-bold)',
            color: 'var(--error-base)',
          }}
        >
          ESTA ACCIÓN ES IRREVERSIBLE
        </p>
        <TextField
          labelHidden
          label="Confirmar elimincacion de cuenta"
          placeholder="eliminar"
          onChange={handleChange}
          value={value}
        />
        <Button onClick={onCancel}>Cancelar</Button>
        <Button
          onClick={onConfirm}
          isDisabled={isDisabled || value !== 'eliminar'}
          className={
            'amplify-button amplify-field-group__control amplify-button--destructive'
          }
        >
          Eliminar mi cuenta
        </Button>
      </Flex>
    </form>
  );
};

const components = { WarningView: CustomWarning };

const Profile: React.FC = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { username } = user;
  const { name, family_name, email } = (user as any) && user.attributes;
  const [presentAlert] = useIonAlert();

  const handleDeleteSuccess = () => {
    window.location.href = '/';
    localStorage.clear();
  };

  const handleChangeSuccess = () => {
    passwordChangeSuccess();
  };

  const passwordChangeSuccess = () => {
    presentAlert({
      header: 'Contraseña cambiada',
      cssClass: 'custom-alert',
      message: 'Su contraseña ha sido cambiada exitosamente.',
      buttons: [
        {
          text: 'Ok',
          cssClass: 'alert-button-confirm',
          role: 'confirm',
          handler: () => {
            // console.log('Confirm clicked');
            // performLogout();
          },
        },
      ],
    });
  };

  const handleChangeError = (error: any) => {
    // console.log('Password changed error', error);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle
            style={{
              paddingLeft: '0px',
            }}
          >
            Cuenta del Usuario
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        className={`ion-content-custom-background event-details-container`}
      >
        <div className="event-details-content-block event event-details-item">
          <h2 className="alert-details-item-header2">Datos del Usuario</h2>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonText>Username:</IonText>
              </IonCol>
              <IonCol>
                <IonText>{username}</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText>Email:</IonText>
              </IonCol>
              <IonCol>
                <IonText>{email}</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText>Nombre:</IonText>
              </IonCol>
              <IonCol>
                <IonText>{name}</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText>Apellido:</IonText>
              </IonCol>
              <IonCol>
                <IonText>{family_name}</IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        <div className="event-details-content-block event event-details-item">
          <h2 className="alert-details-item-header2">Cambiar contraseña</h2>

          <AccountSettings.ChangePassword
            onSuccess={handleChangeSuccess}
            onError={handleChangeError}
          />
        </div>
        <div className="event-details-content-block event event-details-item">
          <h2 className="alert-details-item-header2">Eliminar cuenta</h2>

          <AccountSettings.DeleteUser
            onSuccess={handleDeleteSuccess}
            components={components}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
