import { IonContent } from '@ionic/react';
import React from 'react';

const CompleteListNodal: React.FC<any> = ({ completeListProps, active }) => {
  return (
    <IonContent
      fullscreen
      className={`ion-content-custom-background${!active ? '-inactive' : ''}`}
    >
      <div
        className={`alert-details-content-block${!active ? '-inactive' : ''}`}
        style={{
          marginTop: 'var(--space-2)',
          minHeight: 'calc(100% - var(--space-2))',
        }}
      >
        {completeListProps &&
          completeListProps.map((el: any) => {
            return (
              <div key={'list-all-' + el.title.props.children[1]}>
                <p
                  style={{
                    marginTop: 'var(--space-3)',
                  }}
                >
                  {el.title}
                </p>
                <ul>
                  {el.text.split(', ').map((el2: any) => {
                    return (
                      <li key={'list-all-' + el.title + '-' + el2}>{el2}</li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
      </div>
    </IonContent>
  );
};

export default CompleteListNodal;
