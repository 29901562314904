/* eslint-disable @typescript-eslint/no-explicit-any */
import { FirebaseOptions } from 'firebase/app';
import * as _ from 'underscore';

import { PermissionState } from '@capacitor/core';
import { createSlice } from '@reduxjs/toolkit';

import {
  addAlertLifecycleToNotifications,
  addAllRegionsToNotifications,
  addRegionToNotifications,
  appendTestNotificationData,
  closeDeniedBanner,
  openDeniedBannerAgain,
  disableNotifyFollowing,
  enableNotifyFollowing,
  removeAlertLifecycleFromNotifications,
  removeAllRegionsToNotifications,
  removeRegionFromNotifications,
  setCurrentNotificationPermissions,
  setFirebaseInitialized,
  updateFirebaseOptionsAndARN,
  updateIndividualAlertsFromData,
  updateNotificationsRegionTypeSettings,
  updateSnsEndpoint,
  updateStaticTopics,
  isNotificationsInitializingNative,
  isNotificationsInitializingWebpush,
} from './notificationsActions';

/// * Typing trick to get both the type and the array of options
// https://vieirinha.dev/blog/2020/12/27/typescript-iterate-over-string-literal.html */
export const regionCodes = [
  'aricaParinacota',
  'tarapaca',
  'antofagasta',
  'atacama',
  'coquimbo',
  'valparaiso',
  'metropolitana',
  'ohiggins',
  'maule',
  'nuble',
  'biobio',
  'araucania',
  'los_rios',
  'los_lagos',
  'aysen',
  'magallanes',
] as const;

export type RegionCode = (typeof regionCodes)[number];

export const regionCutCodes = {
  aricaParinacota: '15',
  tarapaca: '01',
  antofagasta: '02',
  atacama: '03',
  coquimbo: '04',
  valparaiso: '05',
  metropolitana: '13',
  ohiggins: '06',
  maule: '07',
  nuble: '16',
  biobio: '08',
  araucania: '09',
  los_rios: '14',
  los_lagos: '10',
  aysen: '11',
  magallanes: '12',
} as const;

export const cutCodesToRegion: Record<string, RegionCode> =
  _.invert(regionCutCodes);

export const alert_type_roja_str = 'alerta_roja';
export const alert_type_amarilla_str = 'alerta_amarilla';
export const alert_type_preventiva_str = 'alerta_temprana_preventiva';

export const alertTypes = [
  alert_type_roja_str,
  alert_type_amarilla_str,
  alert_type_preventiva_str,
] as const;

export type AlertType = (typeof alertTypes)[number];

export const alertType2name: Record<AlertType, string> = {
  alerta_temprana_preventiva: 'Temprana Preventiva',
  alerta_amarilla: 'Amarilla',
  alerta_roja: 'Roja',
} as const;

export const name2alertType: Record<string, AlertType> =
  _.invert(alertType2name);

export const alertLifecycle = [
  'alert_declaration',
  'alert_cancellation',
  'alert_type_asc',
  'alert_type_desc',
  'alert_modify',
  'alert_update',
  'alert_type_escalate',
  'alert_type_deescalate',
] as const;

export const alertLifecycleCodes = {
  alert_declaration: 'Declara',
  alert_cancellation: 'Cancela',
  alert_type_asc: 'Amplía',
  alert_type_desc: 'Reduce',
  alert_modify: 'Modifica',
  alert_update: 'Actualiza',
  alert_type_escalate: 'Escala',
  alert_type_deescalate: 'Desescala',
} as const;

export type AlertLifecycle = (typeof alertLifecycle)[number];

export type StaticTopicType = {
  arn: string;
  regionCut: string;
  regioncut?: string;
  alertStatus: string;
};

export interface NotificationSettingsState {
  // [Push Notifications Permissions, LocalNotifications Permission]
  currentNotificationPermissions?: [PermissionState, PermissionState?];
  testNotificationsData: any[];
  isNotificationsUpdating: boolean;
  notificationUpdatingRegionCode?: RegionCode | 'all';
  notificationUpdatingLifecycleList?: Array<AlertLifecycle>;
  fcmToken?: string;
  didCloseDeniedBanner?: boolean;
  closeDeniedBannerTimestamp?: string;
  regionsByType: Record<AlertType, Record<RegionCode, boolean>>;
  types: Record<AlertType, boolean>;
  lifecycle: Record<AlertLifecycle, boolean>;
  notifyFollowing: boolean;
  firebaseOptions?: FirebaseOptions;
  isFirebaseInitialized: boolean;
  platformApplicationARN: string;
  endpointARN?: string;
  vapid?: string;
  staticTopics: StaticTopicType[];
  isNotificationsInitializingNative: boolean;
  isNotificationsInitializingWebpush: boolean;
}

const initialState: NotificationSettingsState = {
  testNotificationsData: [],
  isNotificationsUpdating: false,
  didCloseDeniedBanner: false,
  isNotificationsInitializingNative: false,
  isNotificationsInitializingWebpush: false,
  isFirebaseInitialized: false,
  regionsByType: {
    alerta_roja: {
      aricaParinacota: false,
      tarapaca: false,
      antofagasta: false,
      atacama: false,
      coquimbo: false,
      valparaiso: false,
      metropolitana: false,
      ohiggins: false,
      maule: false,
      nuble: false,
      biobio: false,
      araucania: false,
      los_rios: false,
      los_lagos: false,
      aysen: false,
      magallanes: false,
    },
    alerta_amarilla: {
      aricaParinacota: false,
      tarapaca: false,
      antofagasta: false,
      atacama: false,
      coquimbo: false,
      valparaiso: false,
      metropolitana: false,
      ohiggins: false,
      maule: false,
      nuble: false,
      biobio: false,
      araucania: false,
      los_rios: false,
      los_lagos: false,
      aysen: false,
      magallanes: false,
    },
    alerta_temprana_preventiva: {
      aricaParinacota: false,
      tarapaca: false,
      antofagasta: false,
      atacama: false,
      coquimbo: false,
      valparaiso: false,
      metropolitana: false,
      ohiggins: false,
      maule: false,
      nuble: false,
      biobio: false,
      araucania: false,
      los_rios: false,
      los_lagos: false,
      aysen: false,
      magallanes: false,
    },
  },
  types: {
    alerta_roja: false,
    alerta_amarilla: false,
    alerta_temprana_preventiva: false,
  },
  lifecycle: {
    alert_cancellation: true,
    alert_declaration: true,
    alert_type_asc: true,
    alert_type_desc: true,
    alert_modify: true,
    alert_update: true,
    alert_type_escalate: true,
    alert_type_deescalate: true,
  },
  notifyFollowing: true,
  platformApplicationARN: '',
  staticTopics: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* ********************** UPDATE NOTIFICATION PERMISSIONS ************ */
    builder.addCase(
      setCurrentNotificationPermissions.fulfilled,
      (state, payload) => {
        state.currentNotificationPermissions = payload?.payload;
      }
    );
    /* ********************** UPDATE SUBSCRIPTIONS SETTINGS ************** */
    builder.addCase(
      updateNotificationsRegionTypeSettings.fulfilled,
      (state, payload) => {
        const regionTypeSettings: Record<
          AlertType,
          Record<RegionCode, boolean>
        > = payload.payload;
        state.regionsByType = { ...state.regionsByType, ...regionTypeSettings };
      }
    );
    /* ********************** APPEND TO TEST NOTIFICATIONS DATA ********** */
    builder.addCase(appendTestNotificationData.fulfilled, (state, payload) => {
      state.testNotificationsData.push(payload?.payload);
    });
    /* *********************** ADD ALL REGIONS TO NOTIFICATIONS ********** */
    builder.addCase(addAllRegionsToNotifications.pending, (state) => {
      state.isNotificationsUpdating = true;
      state.notificationUpdatingRegionCode = 'all';
    });
    builder.addCase(
      addAllRegionsToNotifications.fulfilled,
      (state, { payload }) => {
        const { target, alertType, regionTypeSuccess } = payload;

        if (target !== 'all') {
          state.regionsByType = { ...state.regionsByType };
        } else {
          // TODO Now we can get the list of regions actually subscribed
          // and update accordingly. We need an inverse cutCode -> regionCode
          // mapping for this.
          if (regionTypeSuccess) {
            regionCodes.forEach((r) => {
              state.regionsByType[alertType as AlertType][r] = true;
            });
          }
        }
        state.isNotificationsUpdating = false;
        state.notificationUpdatingRegionCode = undefined;
      }
    );

    /* *********************** REMOVE ALL REGIONS FROM NOTIFICATIONS ********** */
    builder.addCase(removeAllRegionsToNotifications.pending, (state) => {
      state.isNotificationsUpdating = true;
      state.notificationUpdatingRegionCode = 'all';
    });

    builder.addCase(
      removeAllRegionsToNotifications.fulfilled,
      (state, { payload }) => {
        const { target, alertType, regionTypeSuccess } = payload;
        if (target !== 'all') {
          state.regionsByType = { ...state.regionsByType };
        } else {
          if (regionTypeSuccess) {
            regionCodes.forEach((r) => {
              state.regionsByType[alertType as AlertType][r] = false;
            });
          }
        }
        state.isNotificationsUpdating = false;
        state.notificationUpdatingRegionCode = undefined;
      }
    );

    /* *********************** ADD REGION TO NOTIFICATIONS ********** */
    builder.addCase(addRegionToNotifications.pending, (state, { meta }) => {
      state.isNotificationsUpdating = true;
      state.notificationUpdatingRegionCode = meta?.arg?.region as RegionCode;
    });

    builder.addCase(
      addRegionToNotifications.fulfilled,
      (state, { payload }) => {
        const { region, alertType, regionTypeSuccess } = payload;
        if (regionTypeSuccess) {
          if (alertTypes.includes(alertType as AlertType)) {
            state.regionsByType[alertType as AlertType][region as RegionCode] =
              true;
          }
        }
        state.isNotificationsUpdating = false;
        state.notificationUpdatingRegionCode = undefined;
      }
    );

    /* *********************** REMOVE REGION FROM NOTIFICATIONS ********** */
    builder.addCase(
      removeRegionFromNotifications.pending,
      (state, { meta }) => {
        state.isNotificationsUpdating = true;
        state.notificationUpdatingRegionCode = meta?.arg?.region as RegionCode;
      }
    );

    builder.addCase(
      removeRegionFromNotifications.fulfilled,
      (state, { payload }) => {
        const { region, alertType, regionTypeSuccess } = payload;
        if (regionTypeSuccess) {
          if (alertTypes.includes(alertType as AlertType)) {
            state.regionsByType[alertType as AlertType][region as RegionCode] =
              false;
          }
        }
        state.isNotificationsUpdating = false;
        state.notificationUpdatingRegionCode = undefined;
      }
    );

    /* *********************** ADD ALERT LIFECYCLES TO NOTIFICATIONS ********** */
    builder.addCase(
      addAlertLifecycleToNotifications.pending,
      (state, { meta }) => {
        state.isNotificationsUpdating = true;
        state.notificationUpdatingLifecycleList =
          meta?.arg as Array<AlertLifecycle>;
      }
    );
    builder.addCase(
      addAlertLifecycleToNotifications.fulfilled,
      (state, { payload }) => {
        state.isNotificationsUpdating = false;
        state.notificationUpdatingLifecycleList = undefined;
        const alertLifecycles: Array<string> = payload;
        alertLifecycles.forEach((alertLifecycle) => {
          if (alertLifecycle in state.lifecycle) {
            state.lifecycle[alertLifecycle as AlertLifecycle] = true;
          }
        });
      }
    );

    /* *********************** REMOVE ALERT LIFECYCLES FROM NOTIFICATIONS ********** */
    builder.addCase(
      removeAlertLifecycleFromNotifications.pending,
      (state, { meta }) => {
        state.isNotificationsUpdating = true;
        state.notificationUpdatingLifecycleList =
          meta?.arg as Array<AlertLifecycle>;
      }
    );
    builder.addCase(
      removeAlertLifecycleFromNotifications.fulfilled,
      (state, { payload }) => {
        state.isNotificationsUpdating = false;
        state.notificationUpdatingLifecycleList = undefined;
        const alertLifecycles: Array<string> = payload;
        console.log('actions remove', alertLifecycles);
        alertLifecycles.forEach((alertLifecycle) => {
          if (alertLifecycle in state.lifecycle) {
            state.lifecycle[alertLifecycle as AlertLifecycle] = false;
          }
        });
      }
    );

    /* *********************** UPDATE FAVORITES WHEN RECEIVING NEW ALERTS ********** */
    builder.addCase(updateIndividualAlertsFromData.fulfilled, (_state, _) => {
      // console.log('Updated subscriptions for received alerts');
    });

    /* *********************** ENABLE NOTIFY FOLLOWING ********** */
    builder.addCase(enableNotifyFollowing.pending, (state, _) => {
      state.isNotificationsUpdating = true;
      state.notifyFollowing = true;
    });
    builder.addCase(enableNotifyFollowing.fulfilled, (state, _) => {
      state.isNotificationsUpdating = false;
      state.notifyFollowing = true;
    });

    /* *********************** DISABLE NOTIFY FOLLOWING ********** */
    builder.addCase(disableNotifyFollowing.pending, (state, _) => {
      state.isNotificationsUpdating = true;
      state.notifyFollowing = false;
    });
    builder.addCase(disableNotifyFollowing.fulfilled, (state, _) => {
      state.isNotificationsUpdating = false;
      state.notifyFollowing = false;
    });

    /* *********************** UPDATE FIREBASE OPTIONS & ARN PLATFORM ********** */
    builder.addCase(setFirebaseInitialized.fulfilled, (state, _) => {
      state.isFirebaseInitialized = true;
    });

    builder.addCase(
      updateFirebaseOptionsAndARN.fulfilled,
      (state, { payload }) => {
        const {
          arn: platformApplicationARN,
          vapid,
          ...firebaseOptions
        }: FirebaseOptions & {
          arn?: string;
          vapid?: string;
        } = payload;

        if (platformApplicationARN) {
          state.platformApplicationARN = platformApplicationARN;
        }

        if (vapid) {
          state.vapid = vapid;
        }

        state.firebaseOptions = firebaseOptions;
      }
    );

    /* *********************** UPDATE STATIC TOPICS ********** */
    builder.addCase(updateStaticTopics.fulfilled, (state, { payload }) => {
      const { staticTopics } = payload;
      // Register new FCM token, if present.
      state.staticTopics = staticTopics as Array<{
        arn: string;
        regionCut: string;
        alertStatus: string;
      }>;
    });

    /* *********************** UPDATE FCM TOKEN ********** */
    builder.addCase(updateSnsEndpoint.rejected, (state, rejectValue) => {
      // TODO: inform user whenever token registration fails
      // console.log('REJECTED HANDLER');
    });

    builder.addCase(updateSnsEndpoint.fulfilled, (state, { payload }) => {
      const { token, endpointARN } = payload;

      // Register new FCM token, if present.
      if (token) {
        state.fcmToken = token;
      }

      // Register new endpointARN, if present.
      if (endpointARN) {
        state.endpointARN = endpointARN;
      }
    });

    /* *********************** CLOSE DENIED BANNER ********** */
    builder.addCase(closeDeniedBanner.fulfilled, (state, _) => {
      state.didCloseDeniedBanner = true;
      state.closeDeniedBannerTimestamp = new Date().toISOString();
    });

    /* *********************** OPEN DENIED BANNER ********** */
    builder.addCase(openDeniedBannerAgain.fulfilled, (state, _) => {
      state.didCloseDeniedBanner = false;
      state.closeDeniedBannerTimestamp = '';
    });

    /* *********************** SET IS NOTIFICATION INIT IS IN PROCESS - NATIVE ********** */
    builder.addCase(
      isNotificationsInitializingNative.fulfilled,
      (state, { payload }) => {
        state.isNotificationsInitializingNative = payload;
      }
    );

    /* *********************** SET IS NOTIFICATION INIT IS IN PROCESS - WEBPUSH********** */
    builder.addCase(
      isNotificationsInitializingWebpush.fulfilled,
      (state, { payload }) => {
        state.isNotificationsInitializingWebpush = payload;
      }
    );
  },
});

export default notificationsSlice.reducer;
