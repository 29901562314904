import { addIcons } from 'ionicons';
import React from 'react';
import './EventCard.scss';

/* eslint-disable no-constant-condition */
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonChip,
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useLocation } from 'react-router';
import { ROUTES } from '../constants';
import { CardFooter } from './AlertCard';

addIcons({
  'fav-a': 'assets/icon/fav-a.svg',
  'fav-ia': 'assets/icon/fav-ia.svg',
  share: 'assets/icon/share.svg',
});

export interface EventCardProps {
  startTime: string;
  idInt: string;
  surface: number;
  id: string | undefined;
  active: any;
  // pageName: any;
  type: string;
  name: string;
  comunes: string[];
  sectors: string[];
  status: string;
  updateCount?: number;
  idIntParent?: string;
}

const EventCard: React.FC<EventCardProps> = ({
  type,
  startTime,
  id,
  idInt,
  name,
  active,
  comunes = [],
  sectors = [],
  status,
  updateCount,
  idIntParent,
  surface,
}) => {
  const location = useLocation();
  const pageName = location.pathname.split('/').slice(0, 3).join('/');
  const sectorsCleaned =
    sectors && sectors.filter((el) => el !== null && el.length > 0);

  const isShared = location.pathname.includes(ROUTES.shared.path);

  return (
    <IonCard className="ion-no-padding ion-no-margin event-card">
      <IonCardHeader>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <IonCardSubtitle
              style={{
                fontSize: 'var(--caption)',
                fontWeight: 'var(--font-weight-regular)',
              }}
            >
              {type}
            </IonCardSubtitle>
            {/* <div className="event-card-badge-block">
              <IonLabel className="event-card-badge-caption">
                Registros
              </IonLabel>

              <IonBadge slot="end" className="event-card-badge-badge">
                {updateCount}
              </IonBadge>
            </div> */}
          </div>
          <IonCardSubtitle className="event-card-title">{name}</IonCardSubtitle>
        </div>
      </IonCardHeader>

      <IonCardContent>
        <IonGrid className="ion-no-padding">
          <IonRow
            style={{
              borderTop: '1px solid var(--itrend--blueGray-100)',
              paddingTop: 'var(--space-2)',
            }}
          >
            <IonCol
              size="3"
              className="event-card-locations"
              style={{
                fontWeight: 'var(--font-weight-bold)',
              }}
            >
              Comuna:
            </IonCol>
            <IonCol className="event-card-locations">
              {comunes.join(', ')}
            </IonCol>
          </IonRow>

          {sectorsCleaned.length > 0 && (
            <IonRow>
              <IonCol
                size="3"
                className="event-card-locations"
                style={{
                  fontWeight: 'var(--font-weight-bold)',
                }}
              >
                Sector:
              </IonCol>
              <IonCol className="event-card-locations">
                {sectorsCleaned.join(', ')}
              </IonCol>
            </IonRow>
          )}

          <IonRow>
            <IonCol
              size="3"
              className="event-card-locations"
              style={{
                fontWeight: 'var(--font-weight-bold)',
              }}
            >
              Inicio:
            </IonCol>
            <IonCol className="event-card-locations">
              {format(new Date(startTime), "dd LLLL yyyy 'a las' HH:mm", {
                locale: es,
              })}
            </IonCol>
          </IonRow>

          <IonRow
            style={{
              borderBottom: '1px solid var(--itrend--blueGray-100)',
              paddingBottom: 'var(--space-2)',
              paddingTop: 'var(--space-2)',
            }}
          >
            <IonCol
              style={{
                display: 'flex',
                // justifyContent: 'space-between',
              }}
            >
              <IonChip
                outline={true}
                style={{
                  backgroundColor: 'var(--itrend--secondary-50)',
                  border: 'none',
                  color: 'var(--itrend--secondary-700)',
                }}
                className={`no-click event-card-chip ${
                  !active && 'event-card-chip-inactive'
                }`}
              >
                {'ha: '}
                {surface}
              </IonChip>
              <IonChip
                outline={true}
                className={`no-click event-card-chip ${
                  !active && 'event-card-chip-inactive'
                }`}
                style={{
                  backgroundColor: 'var(--itrend--secondary-50)',
                  border: 'none',
                  color: 'var(--itrend--secondary-700)',
                }}
              >
                {status}
              </IonChip>
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* Evento [name] de [alert] [status] en alerta [alert id = id] */}

        {/* 
        idInt     - internal event id
        id        - alert senapred id 
        pageName  - current tab name
        active    - is alert active 
        */}

        <CardFooter
          alert={type}
          buttonTitle={'Ver Detalle'}
          cardType={'Event'}
          eventName={name}
          eventStatus={status}
          idInt={idInt}
          id={id}
          buttonLink={`${
            isShared ? '' : 'alerts/'
          }${idIntParent}/events/${idInt}`}
          active={active}
          idIntParent={idIntParent}
          shareData={{
            id,
            type,
            name,
            status,
          }}
        />
      </IonCardContent>
    </IonCard>
  );
};

export default EventCard;
