import './PermissionsBanner.scss';

import { close } from 'ionicons/icons';
import React from 'react';

import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
  IonText,
} from '@ionic/react';

import {
  closeDeniedBanner,
  setCurrentNotificationPermissions,
} from '../../features/notifications/notificationsActions';
import { requestPermissions } from '../../firebaseUtils';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../constants';

const PermissionsBanner: React.FC = () => {
  const { didCloseDeniedBanner, currentNotificationPermissions } =
    useAppSelector((state: RootState) => state.notifications);

  const dispatch = useAppDispatch();
  const history = useHistory();

  return (
    <>
      {currentNotificationPermissions &&
        (['prompt', 'prompt-with-rationale'].includes(
          currentNotificationPermissions[0]
        ) ||
          (currentNotificationPermissions[1] &&
            ['prompt', 'prompt-with-rationale'].includes(
              currentNotificationPermissions[1]
            ))) && (
          <IonGrid className="permission-banner">
            <IonRow style={{ marginTop: 'var(--space-2)' }}>
              <IonCol>
                <IonText className="body-medium-bold">
                  Recibe notificaciones de Alertas tan pronto se emitan desde
                  SENAPRED
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  className="ion-no-padding"
                  fill="clear"
                  onClick={async () => {
                    let pushPermission = null;
                    let localPermission = null;
                    let wasGranted = false;

                    if (Capacitor.getPlatform() === 'web') {
                      pushPermission = await requestPermissions();

                      await dispatch(
                        setCurrentNotificationPermissions([pushPermission])
                      );
                      wasGranted =
                        pushPermission && pushPermission === 'granted';
                    } else if (Capacitor.isNativePlatform()) {
                      const pushPermission = await requestPermissions();

                      const localResult =
                        await LocalNotifications.requestPermissions();

                      localPermission = localResult.display;
                      wasGranted =
                        pushPermission &&
                        localPermission &&
                        pushPermission === 'granted' &&
                        localPermission === 'granted';
                    }

                    if (wasGranted) {
                      // * We need to refresh the page to properly run
                      // * the notification initializer with granted permissions.
                      // * This is required in both web and native platforms.
                      window?.location?.reload();
                    }
                  }}
                >
                  Permitir notificaciones
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      {!didCloseDeniedBanner &&
        currentNotificationPermissions &&
        (currentNotificationPermissions[0] === 'denied' ||
          (currentNotificationPermissions[1] &&
            currentNotificationPermissions[1] === 'denied')) && (
          <IonGrid className="permission-banner">
            <IonRow style={{ marginTop: 'var(--space-2)' }}>
              <IonCol>
                <IonItem className="ion-no-padding">
                  <IonText className="body-medium-bold">
                    Cómo permitir notificaciones en la aplicación.
                  </IonText>
                  <IonButton
                    fill="clear"
                    size="large"
                    style={{ color: 'var(--itrend--blueGray-900)' }}
                    onClick={async () => {
                      await dispatch(closeDeniedBanner());
                    }}
                  >
                    <IonIcon slot="icon-only" icon={close}></IonIcon>
                  </IonButton>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  className="ion-no-padding"
                  fill="clear"
                  onClick={async () => {
                    await dispatch(closeDeniedBanner());
                    history.push(`${ROUTES.settings.path}/guide`);
                  }}
                >
                  Ver guía paso a paso
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
    </>
  );
};

export default PermissionsBanner;
