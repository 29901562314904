/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */

import 'primereact/resources/primereact.min.css';
import './AlertsList.scss';

import { format } from 'date-fns';
import { arrowDownOutline, arrowUpOutline, close } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

/* eslint-disable @typescript-eslint/no-explicit-any */
import { menuController } from '@ionic/core/components';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSkeletonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import {
  makeCardProps,
  regions,
  requestParams as requestParamsTemplates,
  sortByValues,
} from '../constants';
import {
  removeCardsFromFavorites,
  setAlertIdToRemoveFromList,
  toggleAlertsListUpdate,
} from '../features/favorites/favoritesActions';
import { updateIndividualAlertsFromData } from '../features/notifications/notificationsActions';
import { SettingsState } from '../features/settings/settings';
import {
  setFilterByLevelCanceladas,
  setFilterByLevelVigentes,
  setFilterByRegionCanceladas,
  setFilterByRegionVigentes,
  setFilterByTypeCanceladas,
  setFilterByTypeVigentes,
} from '../features/settings/settingsActions';
import { useAppSelector } from '../hooks';
import { goToTopOfPage } from '../pages/AppTabs';
import { API_ALERTS_PAGE_SIZE, CDAlertasApi } from '../services/CDAlertasApi';
import { RootState } from '../store';
import closeOutline from '../theme/icons/close-outline.svg';
import { Card, OrderByModal, PermissionsBanner, SkeletonCard } from './';
import SuscripcionesTutorialCard from './SuscripcionesTutorialCard/SuscripcionesTutorialCard';
import { useAuthenticator } from '@aws-amplify/ui-react';

enum PageNameEnum {
  VIGENTES = 'Vigentes',
  SUSCRIPCIONES = 'Suscripciones',
  CANCELADO = 'Canceladas',
}

const AlertsList: React.FC<{
  refMenu?: React.MutableRefObject<HTMLIonMenuElement | null>;
  setIsFilterMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  pageName: string;

  setSortBy?: (
    value: string
  ) => (dispatch: ThunkDispatch<SettingsState, void, Action>) => void;
  sortBy?: string;
}> = ({
  refMenu,
  setIsFilterMenuOpen,
  pageName,
  setSortBy,
  sortBy = sortByValues[0],
}) => {
  const dispatch: Dispatch<any> = useDispatch();

  const [showNumAlertsMessage, setShowNumAlertsMessage] = useState(true);

  const {
    currentFavorites,
    alertsListShouldBeUpdated,
    alertIdToRemoveFromList,
  } = useAppSelector((state: RootState) => state.favorites);
  const { regionsByType } = useAppSelector(
    (state: RootState) => state.notifications
  );

  const [enableRemoveCancelled, setEnableRemoveCancelled] = useState(false);

  const requestPrefix =
    pageName === PageNameEnum.SUSCRIPCIONES
      ? ''
      : pageName === PageNameEnum.CANCELADO
      ? 'filter[isActive]=0'
      : 'filter[isActive]=1';

  const makeSortBy = (sortBy: string) =>
    sortBy === 'Actualizacion mas reciente'
      ? // ? '-lastUpdateData.updateTime'
        '-updatedAt'
      : sortBy === 'Mayor tiempo en vigencia'
      ? '-creationTime'
      : sortBy === 'Fecha de inicio'
      ? '-creationTime'
      : '';

  const [
    retrieveAlertData,
    {
      isLoading,
      isFetching,
      isSuccess,
      isError: isAlertDataError,
      error: alertDataError,
    },
  ] = CDAlertasApi.useLazyGetAllAlertsQuery();

  const [
    retrieveCancelledAlertsWithIds,
    {
      isLoading: cancelledIsLoading,
      isFetching: cancelledIsFetching,
      isSuccess: cancelledIsSuccess,
      isError: isCancelledAlertWithIdsError,
      error: cancelledAlertsWithIdsError,
    },
  ] = CDAlertasApi.useLazyGetAllCancelledAlertsWithIdsQuery();

  const alertData = { meta: { page: { total: 0, lastPage: 0 } }, data: [] };

  const [accumulatedAlertData, setAccumulatedAlertData] = useState({
    metaPageTotal: 0,
    metaFinalPage: 0,
    data: [],
  });

  // * Update individual alert subscriptions
  // * as they appear in the alert data.
  useEffect(() => {
    const doEffect = async () => {
      dispatch(updateIndividualAlertsFromData(accumulatedAlertData));
    };
    doEffect();

    setEnableRemoveCancelled(
      accumulatedAlertData?.data.filter((el: any) => el.isActive === 0).length >
        0
    );
  }, [accumulatedAlertData]);

  const [reloadToggle, setReloadToggle] = useState(false);
  const [isLoadingOpen, setIsLoadingOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [infiniteScrollEvent, setInfiniteScrollEvent] = useState<any>();
  const [refresherEvent, setRefresherEvent] = useState<any>();

  const [isFavsDataLoaded, setIsFavsDataLoaded] = useState(false);
  const [alertsCountSinceLastLoad, setAlertsCountSinceLastLoad] = useState(0);

  const {
    filterByTypeVigentes,
    filterByLevelVigentes,
    filterByRegionVigentes,
  } = useAppSelector((state: RootState) => state.settings);
  const {
    filterByTypeCanceladas,
    filterByLevelCanceladas,
    filterByRegionCanceladas,
  } = useAppSelector((state: RootState) => state.settings);
  const { filterByType, filterByLevel, filterByRegion } =
    pageName === 'Vigentes'
      ? {
          filterByType: filterByTypeVigentes,
          filterByLevel: filterByLevelVigentes,
          filterByRegion: filterByRegionVigentes,
        }
      : {
          filterByType: filterByTypeCanceladas,
          filterByLevel: filterByLevelCanceladas,
          filterByRegion: filterByRegionCanceladas,
        };

  useEffect(() => {
    if (pageName !== PageNameEnum.SUSCRIPCIONES) {
      setIsLoadingOpen(
        isFetching || isLoading || cancelledIsFetching || cancelledIsLoading
      );
    } else {
      setIsLoadingOpen(
        (isFetching || isLoading) && (cancelledIsFetching || cancelledIsLoading)
      );
    }

    setIsFavsDataLoaded(
      !(isFetching || isLoading || cancelledIsFetching || cancelledIsLoading) &&
        pageName === PageNameEnum.SUSCRIPCIONES &&
        currentFavorites.length > 0
    );
  }, [
    isFetching,
    isLoading,
    isSuccess,
    cancelledIsFetching,
    cancelledIsLoading,
    cancelledIsSuccess,
  ]);

  const triggerReload = () => {
    setPageNumber(1);
    setReloadToggle(!reloadToggle);
  };

  useEffect(() => {
    if (pageName === PageNameEnum.SUSCRIPCIONES) {
      triggerReload();
    }
  }, [regionsByType]);

  useEffect(() => {
    const processUseEffect = async () => {
      if (
        pageName &&
        [
          PageNameEnum.VIGENTES as string,
          PageNameEnum.SUSCRIPCIONES as string,
          PageNameEnum.CANCELADO as string,
        ].includes(pageName)
      ) {
        const requestParameters = regenerateQueryParameters();

        const apiResult = await retrieveAlertData({
          ...requestParameters,
          pageNumber,
        }).unwrap();

        setAccumulatedAlertData({
          metaFinalPage: apiResult?.meta?.page?.lastPage || 0,
          metaPageTotal: apiResult?.meta?.page?.total || 0,
          data:
            pageNumber > 1
              ? accumulatedAlertData.data.concat(apiResult.data)
              : apiResult.data,
        });

        setAlertsCountSinceLastLoad(apiResult?.meta?.page?.total || 0);
      }
    };
    processUseEffect()
      .then((_) => {
        if (infiniteScrollEvent) {
          infiniteScrollEvent.target.complete();
          setInfiniteScrollEvent(null);
        }

        if (refresherEvent) {
          refresherEvent.detail.complete();
          setRefresherEvent(null);
        }

        if (!infiniteScrollEvent) {
          goToTopOfPage('/tabs/' + pageName.toLowerCase());
        }
      })
      .catch(console.error);
  }, [reloadToggle, pageNumber]);

  useEffect(() => {
    setAccumulatedAlertData({
      metaPageTotal: 0,
      metaFinalPage: 0,
      data: [],
    });

    if (
      pageName &&
      [
        PageNameEnum.VIGENTES as string,
        PageNameEnum.SUSCRIPCIONES as string,
        PageNameEnum.CANCELADO as string,
      ].includes(pageName)
    ) {
      triggerReload();
    }
  }, [filterByType, filterByLevel, filterByRegion, sortBy]);

  useEffect(() => {
    const processUseEffect = async () => {
      if (
        pageName &&
        [PageNameEnum.SUSCRIPCIONES as string].includes(pageName)
      ) {
        const cancelledAlerts = await retrieveCancelledAlertsWithIds({
          idsToCheck: currentFavorites,
        }).unwrap();

        setEnableRemoveCancelled((cancelledAlerts?.data || []).length > 0);

        triggerReload();
      }
    };

    if (alertsListShouldBeUpdated) {
      processUseEffect();
      setIsFavsDataLoaded(currentFavorites.length > 0);
      dispatch(toggleAlertsListUpdate(false));
    }
  }, [currentFavorites, alertsListShouldBeUpdated]);

  useEffect(() => {
    if (!alertsListShouldBeUpdated && alertIdToRemoveFromList) {
      filterListOfAlerts([alertIdToRemoveFromList]);
      dispatch(setAlertIdToRemoveFromList(''));
    }
  }, [alertIdToRemoveFromList, alertIdToRemoveFromList]);

  const filterListOfAlerts = (alertsIdsToremove: any) => {
    if (pageName === PageNameEnum.SUSCRIPCIONES) {
      const newMaxPage = Math.ceil(
        (accumulatedAlertData.metaPageTotal - alertsIdsToremove.length) /
          API_ALERTS_PAGE_SIZE
      );
      const newAlertsCount =
        accumulatedAlertData.metaPageTotal &&
        accumulatedAlertData.metaPageTotal - alertsIdsToremove.length;

      setAccumulatedAlertData({
        ...accumulatedAlertData,
        data: accumulatedAlertData.data.filter((el: any) => {
          return !alertsIdsToremove.includes(el.sinapredId);
        }),
        metaPageTotal: newAlertsCount,
        metaFinalPage: newMaxPage,
      });

      if (
        newAlertsCount <=
        alertsCountSinceLastLoad - pageNumber * API_ALERTS_PAGE_SIZE
      ) {
        triggerReload();
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRefresh = async (evt: any) => {
    setRefresherEvent(evt);
    triggerReload();
  };

  const { user } = useAuthenticator((context) => [context.user]);

  const regenerateQueryParameters = () => {
    let { username } = user;
    username = process.env.REACT_APP_COGNITO_CLIENT_ID + '.' + username;

    let params = requestPrefix;
    let regCodes: any;

    const favsString = currentFavorites.join(',');
    const requestPostfix =
      pageName === PageNameEnum.SUSCRIPCIONES && favsString ? favsString : '';

    regCodes =
      filterByRegion &&
      regions.filter((region: any) =>
        filterByRegion.includes(Object.keys(region)[0])
      );
    regCodes = (regCodes || []).map((region: any) => Object.values(region)[0]);

    if (
      filterByType &&
      filterByType.length &&
      pageName !== PageNameEnum.SUSCRIPCIONES
    ) {
      params += `&filter${
        requestParamsTemplates.filterByStatus
      }=${filterByType.join(',')}`;
    }

    if (
      filterByLevel &&
      filterByLevel.length &&
      pageName !== PageNameEnum.SUSCRIPCIONES
    ) {
      params += `&filter${
        requestParamsTemplates.filterByLevel
      }=${filterByLevel.join(',')}`;
    }

    // &filter[lastUpdate][level][name]=Regional

    if (
      regCodes &&
      regCodes.length &&
      pageName !== PageNameEnum.SUSCRIPCIONES
    ) {
      params += `&filter${
        requestParamsTemplates.filterByRegion
      }=${regCodes.join(',')}`;
    }

    if (pageName === PageNameEnum.SUSCRIPCIONES) {
      params += `&filter${requestParamsTemplates.filterBySinapredId}=${requestPostfix}`;
    }

    params += sortBy && `&sort=${makeSortBy(sortBy)}`;

    // request last 3 months for canceled
    if (pageName === PageNameEnum.CANCELADO) {
      const date = new Date();
      const threeMonthsAgo = format(
        date.setMonth(date.getMonth() - 3),
        'yyyy-MM-dd kk:mm:ss'
      );
      params += `&filter[closureTime]=${threeMonthsAgo}`;
    }

    return {
      filter: params,
      pageNumber: 1,
    };
  };

  const clearLevelFilter = () => {
    if (pageName === 'Vigentes') {
      dispatch(setFilterByLevelVigentes([]));
    } else {
      dispatch(setFilterByLevelCanceladas([]));
    }
  };

  const clearTypeFilter = () => {
    if (pageName === 'Vigentes') {
      dispatch(setFilterByTypeVigentes([]));
    } else {
      dispatch(setFilterByTypeCanceladas([]));
    }
  };

  const clearRegionFilter = () => {
    if (pageName === 'Vigentes') {
      dispatch(setFilterByRegionVigentes([]));
    } else {
      dispatch(setFilterByRegionCanceladas([]));
    }
  };

  // const setClickedFavIdFunc = (id: string) => {
  //   setClickedFavId(id);
  // };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonTitle className="ion-no-padding">
              <span role="heading" aria-level={1}>
                {pageName}
              </span>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        {pageName !== 'Canceladas' && (
          <div style={{ flex: 'none' }}>
            <PermissionsBanner />
          </div>
        )}

        <div
          style={{
            marginTop: '0',
            borderBottom: '1px solid var(--itrend--blueGray-100)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: 'var(--space-4)',
            paddingRight: 'var(--space-4)',
            backgroundColor: 'var(--itrend--white)',
          }}
        >
          {isLoadingOpen && (
            <IonThumbnail style={{ width: '50vw', height: '50%' }}>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonThumbnail>
          )}

          {!isLoadingOpen && accumulatedAlertData?.data?.length >= 0 && (
            <p
              className="body-medium-medium"
              style={{ color: 'var(--itrend--gray-800)' }}
            >
              {`${accumulatedAlertData.metaPageTotal} Alerta${
                (accumulatedAlertData.metaPageTotal || 0) == 1 ? '' : 's'
              }`}
            </p>
          )}

          {(pageName === PageNameEnum.VIGENTES ||
            pageName === PageNameEnum.CANCELADO) && (
            <IonButton
              fill="clear"
              slot="end"
              style={{
                color: 'var(--itrend--secondary-500)',
              }}
              onClick={() => {
                menuController.toggle('filter-menu');
                setIsFilterMenuOpen?.(true);
              }}
              className="body-medium-medium"
            >
              Filtrar
            </IonButton>
          )}

          {pageName === PageNameEnum.SUSCRIPCIONES && (
            <IonButton
              fill="clear"
              slot="end"
              style={{
                color: 'var(--itrend--secondary-500)',
              }}
              disabled={!enableRemoveCancelled}
              onClick={async () => {
                /* Get ids of cancelled cards in favs */
                const cancelledFavoriteAlerts =
                  await retrieveCancelledAlertsWithIds({
                    idsToCheck: currentFavorites,
                  }).unwrap();
                const cancelledFavoriteAlertsIds = await (
                  cancelledFavoriteAlerts?.data || []
                )
                  .filter(
                    (alertData: { isActive: number }) =>
                      alertData.isActive === 0
                  )
                  .map(
                    (alertData: { sinapredId: string }) => alertData.sinapredId
                  );

                await dispatch(
                  removeCardsFromFavorites(cancelledFavoriteAlertsIds)
                );

                await filterListOfAlerts(cancelledFavoriteAlertsIds);

                setEnableRemoveCancelled(false);
              }}
              className="body-medium-medium"
            >
              Quitar canceladas
            </IonButton>
          )}
        </div>

        {pageName === PageNameEnum.CANCELADO && showNumAlertsMessage && (
          <div
            style={{
              backgroundColor: 'var(--itrend--secondary-50)',
              marginTop: '0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: 'var(--space-4)',
              paddingRight: 'var(--space-4)',
            }}
          >
            <p
              className="body-medium-bold"
              style={{
                color: 'var(--itrend--primary-800)',
              }}
            >
              Se muestran alertas canceladas en los últimos 3 meses
            </p>
            <IonButton
              fill="clear"
              onClick={() => setShowNumAlertsMessage(false)}
            >
              <IonIcon
                aria-label='Cerrar un mensaje de "Se muestran alertas canceladas en los últimos 3 meses"'
                icon={close}
                size="large"
                style={{ color: 'var(--itrend--blueGray-900)' }}
              ></IonIcon>
            </IonButton>
          </div>
        )}

        <div
          style={{
            marginTop: '0',
            padding: 'var(--space-2) var(--space-4)',
            borderBottom: 'var(--space-2) solid var(--itrend--blueGray-50)',
            backgroundColor: 'var(--itrend--white)',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                padding: 'var(--space-2) var(--space-2) var(--space-2) 0',
                display: 'flex',
                alignItems: 'center',
              }}
              id={pageName + '-order-by-trigger'}
            >
              <IonIcon
                style={{
                  paddingRight: 'var(--space-2)',
                }}
                icon={
                  sortBy === sortByValues[1] ? arrowDownOutline : arrowUpOutline
                }
                aria-label={
                  'Orden ' +
                  (sortBy === sortByValues[1] ? 'descendente' : 'ascendente')
                }
              ></IonIcon>
              {sortBy}
            </div>

            {((filterByType && filterByType.length) ||
              (filterByLevel && filterByLevel.length) ||
              (filterByRegion && filterByRegion.length)) &&
            pageName !== PageNameEnum.SUSCRIPCIONES ? (
              <div
                style={{
                  padding: '0 var(--space-2) var(--space-2) var(--space-2)',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {filterByType && filterByType.length ? (
                  <div className="custom-chip-with-no-ripple-effect">
                    <IonLabel>Tipo de Alerta</IonLabel>
                    <IonIcon
                      src={closeOutline}
                      onClick={() => clearTypeFilter()}
                    ></IonIcon>
                  </div>
                ) : null}
                {filterByLevel && filterByLevel.length ? (
                  <div className="custom-chip-with-no-ripple-effect">
                    <IonLabel>Cobertura</IonLabel>
                    <IonIcon
                      src={closeOutline}
                      onClick={() => clearLevelFilter()}
                    ></IonIcon>
                  </div>
                ) : null}
                {filterByRegion && filterByRegion.length ? (
                  <div className="custom-chip-with-no-ripple-effect">
                    <IonLabel>Regiones</IonLabel>
                    <IonIcon
                      src={closeOutline}
                      onClick={() => clearRegionFilter()}
                    ></IonIcon>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>

        <IonContent
          // fullscreen
          scrollEvents={true}
          id="info-pane"
          scroll-y={true}
          style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            overflowY: 'scroll',
          }}
        >
          <div
            style={{
              padding: 'var(--space-4)',
              height: '100%',
            }}
          >
            <div
              className="scroll-to-top"
              id={`scroll-to-top-${pageName.toLowerCase()}`}
            ></div>
            <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            {!refresherEvent && accumulatedAlertData.data && (
              <>
                {accumulatedAlertData.data.length > 0 &&
                  accumulatedAlertData.data.map((a: any) => {
                    return (
                      <Card
                        key={a.id}
                        {...makeCardProps(a, pageName, filterListOfAlerts)}
                      />
                    );
                  })}
                {pageName === PageNameEnum.SUSCRIPCIONES &&
                  currentFavorites.length === 0 &&
                  (accumulatedAlertData.metaPageTotal || 0) == 0 && (
                    <SuscripcionesTutorialCard />
                  )}
              </>
            )}

            {isLoadingOpen &&
              !infiniteScrollEvent &&
              pageName !== PageNameEnum.SUSCRIPCIONES && (
                <>
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                </>
              )}

            {isLoadingOpen &&
              !infiniteScrollEvent &&
              pageName === PageNameEnum.SUSCRIPCIONES &&
              !isFavsDataLoaded &&
              (currentFavorites.length < 4 ? (
                currentFavorites.map((a: any, i: number) => (
                  <SkeletonCard key={i} isFav={true} />
                ))
              ) : (
                <>
                  <SkeletonCard isFav={true} />
                  <SkeletonCard isFav={true} />
                  <SkeletonCard isFav={true} />
                  <SkeletonCard isFav={true} />
                </>
              ))}

            {((pageName === PageNameEnum.SUSCRIPCIONES &&
              currentFavorites.length !== 0 &&
              (accumulatedAlertData.metaPageTotal || 0) != 0) ||
              pageName !== PageNameEnum.SUSCRIPCIONES) && (
              <IonInfiniteScroll
                onIonInfinite={(ev) => {
                  if (
                    accumulatedAlertData.metaFinalPage &&
                    pageNumber < accumulatedAlertData.metaFinalPage
                  ) {
                    setInfiniteScrollEvent(ev);
                    setPageNumber(pageNumber + 1);
                  } else {
                    ev.target.complete();
                  }
                }}
              >
                <IonInfiniteScrollContent
                  loadingText="Cargando..."
                  loadingSpinner="circular"
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            )}
          </div>
        </IonContent>

        {pageName && setSortBy && sortBy && (
          <OrderByModal page={pageName} setSortBy={setSortBy} sortBy={sortBy} />
        )}
      </IonPage>
    </>
  );
};

export default AlertsList;
