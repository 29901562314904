import './Canceladas.css';

import React from 'react';

import { AlertsList } from '../components';
import { setSortByCanceladas } from '../features/settings/settingsActions';
import { useAppSelector } from '../hooks';
import { RootState } from '../store';

const Canceladas: React.FC<{ refMenu: any; setIsFilterMenuOpen: any }> = ({
  refMenu,
  setIsFilterMenuOpen,
}) => {
  // const location = useLocation();
  const { sortByCanceladas } = useAppSelector(
    (state: RootState) => state.settings
  );

  return (
    <>
      <AlertsList
        setIsFilterMenuOpen={setIsFilterMenuOpen}
        refMenu={refMenu}
        pageName={'Canceladas'}
        setSortBy={setSortByCanceladas}
        sortBy={sortByCanceladas}
      />
    </>
  );
};

export default Canceladas;
