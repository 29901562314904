import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../constants';
import './Error.scss';

const Error: React.FC<any> = ({ code, header, message }) => {
  const history = useHistory();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="ion-padding">{code}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent
        scrollEvents={true}
        fullscreen
        className="ion-content-custom-background"
        style={{
          boxSizing: 'border-box',
        }}
      >
        <div
          className="home-content-block"
          style={{
            height: 'calc(100% - var(--space-2))',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              fontWeight: 'var(--font-weight-bold)',
              color: 'var(--itrend--primary-500)',
              fontSize: 'var(--headline-large)',
              textAlign: 'center',
            }}
          >
            {header}
          </p>
          <p
            style={{
              fontSize: 'var(--body-large)',
              textAlign: 'center',
            }}
          >
            {message}
          </p>
          <IonButton
            fill="outline"
            style={{
              marginTop: 'var(--space-4)',
            }}
            href={ROUTES.home.path}
          >
            Regresar al inicio
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Error;
