/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IonButton,
  IonButtons,
  IonChip,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
  useIonViewDidEnter,
} from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Event, NotIonBackButton } from '../components';
import CommentsModal from '../components/CommentsModal';
import './EventDetails.scss';
import { useSwipeable } from 'react-swipeable';
import { goBackHandler } from '../components/NotIonBackButton';
import { swipeConfig, maxPixelsNumberFromEdge } from '../constants';
import { sendActivityToRum } from '../App';

const EventDetails: React.FC<{ location: any }> = ({ location }) => {
  const { eventId } = useParams<{
    eventId: string;
    type: string;
    name: string;
  }>();

  const locationLink = useLocation();
  const dataLink: any = locationLink && locationLink.state;

  const [headerData, setHeaderData] = useState({
    type: '',
    name: '',
    status: '',
    active: true,
  });

  // const isEventShared = location.pathname.includes(ROUTES.shared.path);
  const parentAlertUrl = location.pathname.includes('regions')
    ? location.pathname.split('/').slice(0, 7).join('/')
    : location.pathname.split('/').slice(0, 5).join('/');
  // const parentAlertUrl = location.pathname.split('/').slice(0, 5).join('/');
  const parentAlertId = location.pathname.includes('regions')
    ? parentAlertUrl.split('/')[6]
    : parentAlertUrl.split('/')[4];

  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [commentsModalProps, setCommentsModalProps] = useState<any>({});
  const router = useIonRouter();

  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventData) => {
      if (eventData.initial[0] > maxPixelsNumberFromEdge) {
        return;
      }

      if (isCommentsModalOpen) {
        setIsCommentsModalOpen(false);
      } else {
        goBackHandler(router);
      }
    },
    ...swipeConfig,
  });

  // useIonViewDidEnter(() => {
  //   sendActivityToRum('event', 'view');
  // });

  useEffect(() => {
    sendActivityToRum('event', 'view');
  }, []);

  return (
    <IonPage {...swipeHandlers}>
      <IonHeader>
        {isCommentsModalOpen ? (
          <IonToolbar>
            <IonTitle className="ion-no-padding">
              Comentarios generales
            </IonTitle>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsCommentsModalOpen(false)}>
                <IonIcon
                  style={{
                    color: 'var(--itrend--white)',
                    fontSize: '24px',
                  }}
                  icon={arrowBackOutline}
                ></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        ) : (
          <IonToolbar
            style={{
              padding: 'var(--space-2)',
              paddingTop: 'calc(var(--space-2) + var(--ion-safe-area-top))',
            }}
            className="align-items-start"
          >
            <div
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
              }}
            >
              {/* <IonBackButton
                defaultHref={makeGoBackUrl(location, 2)}
                ></IonBackButton> */}
              {/* <IonButtons slot="start"> */}
              <NotIonBackButton />
              {/* </IonButtons> */}
              <div>
                <IonTitle
                  style={{
                    padding: '0',
                    fontWeight: 'var(--font-weight-regular)',
                    fontSize: 'var(--body-large)',
                    color: 'var(--itrend--white)',
                  }}
                >
                  {headerData.type}
                </IonTitle>
                <span role="heading" aria-level={1}>
                  <IonTitle
                    style={{
                      padding: '0',
                      fontWeight: 'var(--font-weight-bold)',
                      fontSize: 'var(--headline-large)',
                      color: 'var(--itrend--white)',
                    }}
                  >
                    {headerData.name}
                  </IonTitle>
                </span>
                {headerData.status && (
                  <IonChip
                    className={`no-click event-card-chip ${
                      !headerData.active && 'event-card-chip-inactive'
                    }`}
                    outline={true}
                    style={{
                      marginTop: 'var(--space-3)',
                      border: 'none',
                      background: 'var(--itrend--secondary-50)',
                      color: 'var(--itrend--secondary-600)',
                    }}
                  >
                    {headerData.status}
                  </IonChip>
                )}
              </div>
            </div>
          </IonToolbar>
        )}
      </IonHeader>

      {isCommentsModalOpen ? (
        <CommentsModal commentsModalProps={commentsModalProps} />
      ) : (
        <Event
          idIntParent={parentAlertId}
          eventId={eventId}
          setHeaderData={setHeaderData}
          setCommentsModalProps={setCommentsModalProps}
          setIsCommentsModalOpen={setIsCommentsModalOpen}
        />
      )}
    </IonPage>
  );
};

export default withRouter(EventDetails);
