import { FirebaseMessaging, GetTokenOptions } from '@capacitor-firebase/messaging';

export const getPermissions = async () => {
  const result = await FirebaseMessaging.checkPermissions();
  return result.receive;
};

export const requestPermissions = async () => {
  const result = await FirebaseMessaging.requestPermissions();
  return result.receive;
};

export const getToken = async (
  vapid: string,
  serviceWorkerRegistration: ServiceWorkerRegistration | null | undefined
): Promise<string> => {
  const options: GetTokenOptions = {
    vapidKey: vapid,
  };

  if (
    serviceWorkerRegistration !== null &&
    serviceWorkerRegistration !== undefined
  ) {
    options.serviceWorkerRegistration = serviceWorkerRegistration;
  }

  const result = await FirebaseMessaging.getToken(options);
  return result.token;
};

export const getTopicFromSubscription = (topic: string) =>
  topic.split(':').slice(0, -1).join(':');
