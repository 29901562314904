import {
  IonAccordionGroup,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonText,
} from '@ionic/react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import React, { useEffect, useState } from 'react';
import { CDAlertasApi } from '../services/CDAlertasApi';
import FireIcon from '../theme/icons/fire.svg';
import HouseIcon from '../theme/icons/house.svg';
import PersonOutlineIcon from '../theme/icons/person-outline.svg';
import PersonIcon from '../theme/icons/person.svg';
import './Alert.scss';
import { CardFooter } from './AlertCard';
import './Event.scss';
import Resources from './Resources';
import TimeLineCustom from './TimeLine';
import FullPageLoader from './loaders/FullPageLoader';

enum AffectationType {
  PERSONA = 'persona',
  VIVIENDA = 'vivienda',
}

enum AffectationPersonaType {
  LESIONADAS = 'Lesionadas',
  DAMNIFICADAS = 'Damnificadas',
  FALLECIDAS = 'Fallecidas',
}

enum AffectationViviendaType {
  DESTRUIDAS = 'Destruidas',
  DANO_MAYOR = 'Daño Mayor',
  EVALUACION = 'En Evaluación',
}

interface EventTimelineItem {
  incident: {
    id: number;
    statusData: { name: string };
    incidentDatetime: Date;
  };
  icon: string;
  color: string;
}

export interface EventProps {
  isParentActive: any;
  id: string;
  type: string;
  name: string;
  status: string;
  startTime: Date;
  updateTime: Date;
  sectors: string[];
  comunes: string[];
  region: string;
  affectations?: {
    surface?: number;
    lesionadas?: number;
    damnificadas?: number;
    fallecidas?: number;
    destruidas?: number;
    danoMayor?: number;
    evaluacion?: number;
    comments?: string[];
  };
  resources?: string[];
  resourcesNew?: any;
  resourcesComments?: string[];
  informationSources?: string;
  saeIncidents?: { updatedAt: Date }[];
  timeline?: EventTimelineItem[];
  latestIncidentComments?: string;
  idIntParent: string;
  handleRefresh?: (evt: any) => void;
  setCommentsModalProps: any;
  setIsCommentsModalOpen: any;
}

/*********** Handler for timeline marker styling ***********/
const makeStatusBasedMarker = (
  timeline: EventTimelineItem[],
  isParentActive: any
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function statusBasedMarker(item: any) {
    const itemIndex = timeline.findIndex(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (e: any) => e.incident.id === item.incident.id
    );
    if (
      isParentActive &&
      (itemIndex === 0 ||
        (itemIndex > 0 &&
          new Date(item.incident.incidentDatetime).getTime() ===
            new Date(timeline[0].incident.incidentDatetime).getTime()))
    ) {
      return <div className="p-timeline-event-marker timeline-active"></div>;
    } else {
      return <div className="p-timeline-event-marker"></div>;
    }
  };
};

const EventWrapper: React.FC<{
  idIntParent: string;
  eventId: string;
  setHeaderData: any;
  setCommentsModalProps: any;
  setIsCommentsModalOpen: any;
}> = ({
  eventId,
  setHeaderData,
  idIntParent,
  setCommentsModalProps,
  setIsCommentsModalOpen,
}) => {
  const [isLoadingOpen, setIsLoadingOpen] = useState(false);

  const {
    data: eventDetailData,
    isLoading,
    isFetching,
    isSuccess,
    refetch,
    isError: isEventDetailError,
    error: eventDetailError,
  } = CDAlertasApi.useGetEventByIdQuery(eventId);

  const {
    data: alertData,
    isError: isAlertDataError,
    error: alertDataError,
  } = CDAlertasApi.useGetAlertByIdQuery(idIntParent, {
    skip: idIntParent === undefined,
  });

  const lastIncidentAffectationsAny =
    eventDetailData &&
    eventDetailData?.data?.lastIncident?.data?.affectations?.data;

  /*********** Filtered affectation entries for type 'persona' ***********/
  const affectationsPersonasLesionadas =
    lastIncidentAffectationsAny &&
    lastIncidentAffectationsAny.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (aff: any) =>
        aff.typeData.name === AffectationPersonaType.LESIONADAS &&
        aff.typeData.type === AffectationType.PERSONA
    );
  const isPersonasLesionadasEmpty =
    affectationsPersonasLesionadas &&
    affectationsPersonasLesionadas.length === 0;

  const affectationsPersonasDamnificadas =
    lastIncidentAffectationsAny &&
    lastIncidentAffectationsAny.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (aff: any) =>
        aff.typeData.name === AffectationPersonaType.DAMNIFICADAS &&
        aff.typeData.type === AffectationType.PERSONA
    );
  const isPersonasDamnificadasEmpty =
    affectationsPersonasDamnificadas &&
    affectationsPersonasDamnificadas.length === 0;

  const affectationsPersonasFallecidas =
    lastIncidentAffectationsAny &&
    lastIncidentAffectationsAny.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (aff: any) =>
        aff.typeData.name === AffectationPersonaType.FALLECIDAS &&
        aff.typeData.type === AffectationType.PERSONA
    );
  const isPersonasFallecidasEmpty =
    affectationsPersonasFallecidas &&
    affectationsPersonasFallecidas.length === 0;

  /*********** Filtered affectation entries for type 'vivienda' ***********/
  const affectationsViviendasDestruidas =
    lastIncidentAffectationsAny &&
    lastIncidentAffectationsAny.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (aff: any) =>
        aff.typeData.name === AffectationViviendaType.DESTRUIDAS &&
        aff.typeData.type === AffectationType.VIVIENDA
    );
  const isViviendasDestruidasEmpty =
    affectationsViviendasDestruidas &&
    affectationsViviendasDestruidas.length === 0;

  const affectationsViviendasDanoMayor =
    lastIncidentAffectationsAny &&
    lastIncidentAffectationsAny.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (aff: any) =>
        aff.typeData.name === AffectationViviendaType.DANO_MAYOR &&
        aff.typeData.type === AffectationType.VIVIENDA
    );
  const isViviendasDanoMayorEmpty =
    affectationsViviendasDanoMayor &&
    affectationsViviendasDanoMayor.length === 0;

  const affectationsViviendasEvaluacion =
    lastIncidentAffectationsAny &&
    lastIncidentAffectationsAny.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (aff: any) =>
        aff.typeData.name === AffectationViviendaType.EVALUACION &&
        aff.typeData.type === AffectationType.VIVIENDA
    );
  const isViviendasEvaluacionEmpty =
    affectationsViviendasEvaluacion &&
    affectationsViviendasEvaluacion.length === 0;

  const affectationsWithComments =
    lastIncidentAffectationsAny &&
    lastIncidentAffectationsAny.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (aff: any) => aff.comments && aff.comments !== ''
    );

  /*********** Resources ***********/
  const lastIncidentResourcesAny =
    eventDetailData &&
    eventDetailData?.data?.lastIncident?.data?.forestFireIncidentResources
      ?.data;

  const resourcesWithComments =
    lastIncidentResourcesAny &&
    lastIncidentResourcesAny.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (resource: any) => resource.comments && resource.comments !== ''
    );

  const resourcesUniqueNames =
    lastIncidentResourcesAny &&
    lastIncidentResourcesAny
      .map((item: any) => item.typeData.name)
      .filter(
        (name: any, index: any, currentVal: any) =>
          currentVal.indexOf(name) === index
      );
  const resourcesWithCommentsGroupedByResource =
    lastIncidentResourcesAny &&
    resourcesUniqueNames.map((el: any) => {
      return {
        name: el,
        resources: lastIncidentResourcesAny
          .filter((el2: any) => el2.typeData.name === el)
          .map((el3: any) => {
            return {
              comment: el3.comments,
              cuantification: el3.cuantification,
            };
          }),
      };
    });

  /*********** Incidents ***********/
  const eventIncidentsData =
    eventDetailData?.data.eventable.data.incidents.data;

  const incidentsDataForTimeline = [...(eventIncidentsData || [])]
    .sort(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (a: any, b: any) =>
        new Date(b.ffIncidentDatetime).getTime() -
        new Date(a.ffIncidentDatetime).getTime()
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((incident: any) => ({
      incident: {
        id: incident.id,
        comments: incident.comments,
        statusData: incident.statusData,
        incidentDatetime: new Date(incident.ffIncidentDatetime),
      },
      icon: 'pi',
      color: '#9C27B0',
    }));

  /*********** Information sources ***********/
  const informationSourcesData =
    eventIncidentsData &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new Set((eventIncidentsData || []).map((i: any) => i.source));

  const informationSources =
    informationSourcesData && Array.from(informationSourcesData).join(', ');
  /*********** SAE messages ***********/
  const incidentsWithSae =
    eventIncidentsData &&
    eventIncidentsData.length > 0 &&
    eventIncidentsData
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .filter((incident: any) => incident.sae === 1);

  /*********** General comments (from latest incident with comments) ***********/
  const incidentWithComments =
    eventIncidentsData &&
    eventIncidentsData.length > 0 &&
    eventIncidentsData
      .filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (incident: any) => incident.comments && incident.comments !== ''
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .sort((a: any, b: any) => b.createdAt.localeCompare(a.createdAt));

  const latestIncidentWithComments =
    incidentWithComments &&
    incidentWithComments.length > 0 &&
    incidentWithComments[0];

  useEffect(() => {
    eventDetailData &&
      setHeaderData({
        status: eventDetailData?.data.lastIncident.data.statusData.name,
        type: eventDetailData?.data.eventTypeData.name,
        name: eventDetailData?.data.name,
        active: eventProps?.isParentActive,
      });
    setIsLoadingOpen(isFetching || isLoading);
  }, [isSuccess, isFetching, isLoading]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRefresh = async (evt: any) => {
    await refetch();
    evt.detail.complete();
  };

  const eventProps = eventDetailData &&
    alertData && {
      isParentActive: alertData.data.isActive,
      id: eventId,
      idIntParent: idIntParent,
      type: eventDetailData?.data.eventTypeData.name,
      name: eventDetailData?.data.name,
      status: eventDetailData?.data.lastIncident.data.statusData.name,
      startTime: eventDetailData && new Date(eventDetailData.data.startTime),
      updateTime:
        eventDetailData &&
        new Date(eventDetailData.data.lastIncident.data.createdAt),
      sectors: eventDetailData && [
        eventDetailData?.data?.lastIncident.data?.comunes?.data[0].sector,
      ],
      comunes: eventDetailData && [
        eventDetailData?.data?.lastIncident.data?.comunes?.data[0].comuneData
          .label,
      ],
      region:
        eventDetailData?.data?.lastIncident.data?.comunes?.data[0].regionData
          .label,
      affectations: {
        surface: eventDetailData?.data.lastIncident.data.surface,
        lesionadas: !isPersonasLesionadasEmpty
          ? affectationsPersonasLesionadas.reduce(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (acc: number, aff: any) => acc + aff.cuantification,
              0
            )
          : undefined,
        damnificadas: !isPersonasDamnificadasEmpty
          ? affectationsPersonasDamnificadas.reduce(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (acc: number, aff: any) => acc + aff.cuantification,
              0
            )
          : undefined,
        fallecidas: !isPersonasFallecidasEmpty
          ? affectationsPersonasFallecidas.reduce(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (acc: number, aff: any) => acc + aff.cuantification,
              0
            )
          : undefined,
        destruidas: !isViviendasDestruidasEmpty
          ? affectationsViviendasDestruidas.reduce(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (acc: number, aff: any) => acc + aff.cuantification,
              0
            )
          : undefined,
        danoMayor: !isViviendasDanoMayorEmpty
          ? affectationsViviendasDanoMayor.reduce(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (acc: number, aff: any) => acc + aff.cuantification,
              0
            )
          : undefined,
        evaluacion: !isViviendasEvaluacionEmpty
          ? affectationsViviendasEvaluacion.reduce(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (acc: number, aff: any) => acc + aff.cuantification,
              0
            )
          : undefined,
        comments: affectationsWithComments
          ? Array.from(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              new Set(affectationsWithComments.map((aff: any) => aff.comments))
            )
          : undefined,
      },
      resources: resourcesWithCommentsGroupedByResource,
      resourcesNew: lastIncidentResourcesAny,

      resourcesComments: resourcesWithComments
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Array.from(new Set(resourcesWithComments.map((r: any) => r.comments)))
        : undefined,
      informationSources: informationSources,
      saeIncidents: incidentsWithSae
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          incidentsWithSae
            .sort((a: any, b: any) =>
              b.ffIncidentDatetime > a.ffIncidentDatetime ? 1 : -1
            )
            .map((i: any) => {
              return { updatedAt: new Date(i.ffIncidentDatetime) };
            })
        : undefined,
      timeline: incidentsDataForTimeline,
      latestIncidentComments: latestIncidentWithComments.comments,
      handleRefresh: handleRefresh,

      setCommentsModalProps: setCommentsModalProps,
      setIsCommentsModalOpen: setIsCommentsModalOpen,
    };

  return (
    <>
      {isLoadingOpen && <FullPageLoader />}
      {!isLoadingOpen && eventDetailData && eventProps && (
        <Event {...eventProps} />
      )}
    </>
  );
};

const Event: React.FC<EventProps> = ({
  isParentActive,
  type,
  id,
  name,
  status,
  startTime,
  updateTime,
  sectors,
  comunes,
  region,
  affectations = {},
  resources,
  resourcesNew,
  informationSources,
  saeIncidents,
  timeline,
  latestIncidentComments,
  idIntParent,
  handleRefresh,
  setCommentsModalProps,
  setIsCommentsModalOpen,
}) => {
  const eventDetails = [{ id, name, startTime: new Date(startTime) }];

  return (
    <IonContent
      className={`ion-content-custom-background${
        !isParentActive ? '-inactive' : ''
      } event-details-container`}
    >
      {handleRefresh && (
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
      )}

      <div
        className={`event-details-content-block${
          !isParentActive ? '-inactive' : ''
        } event event-details-item`}
      >
        <IonGrid className="ion-no-padding">
          <IonRow style={{ marginBottom: '10px' }}>
            <IonCol size="4">
              <p
                className="ion-no-margin event-details-item-text-md"
                style={{
                  fontWeight: 'var(--font-weight-bold)',
                }}
              >
                Declarada:
              </p>
            </IonCol>
            <IonCol>
              <p className="ion-no-margin event-details-item-text-md">
                {format(startTime, "dd LLLL yyyy 'a las' HH:mm", {
                  locale: es,
                })}
              </p>
            </IonCol>
          </IonRow>
          <IonRow style={{ margin: '10px 0' }}>
            <IonCol size="4">
              <p
                className="ion-no-margin event-details-item-text-md"
                style={{
                  fontWeight: 'var(--font-weight-bold)',
                }}
              >
                Actualizada:
              </p>
            </IonCol>
            <IonCol>
              <p className="ion-no-margin event-details-item-text-md">
                {format(updateTime, "dd LLLL yyyy 'a las' HH:mm", {
                  locale: es,
                })}
              </p>
            </IonCol>
          </IonRow>
          <IonRow style={{ margin: '10px 0' }}></IonRow>
          {sectors && sectors.length > 0 && sectors[0] && (
            <IonRow style={{ margin: '10px 0' }}>
              <IonCol size="4">
                <p
                  className="ion-no-margin event-details-item-text-md"
                  style={{
                    fontWeight: 'var(--font-weight-bold)',
                  }}
                >
                  Sector:
                </p>
              </IonCol>
              <IonCol>
                <p className="ion-no-margin event-details-item-text-md">
                  {sectors}
                </p>
              </IonCol>
            </IonRow>
          )}

          <IonRow style={{ margin: '10px 0' }}>
            <IonCol size="4">
              <p
                className="ion-no-margin event-details-item-text-md"
                style={{
                  fontWeight: 'var(--font-weight-bold)',
                }}
              >
                Comuna:
              </p>
            </IonCol>
            <IonCol>
              <p className="ion-no-margin event-details-item-text-md">
                {comunes && comunes.length > 0 && comunes[0]}
              </p>
            </IonCol>
          </IonRow>
          <IonRow style={{ margin: '10px 0' }}>
            <IonCol size="4">
              <p
                className="ion-no-margin event-details-item-text-md"
                style={{
                  fontWeight: 'var(--font-weight-bold)',
                }}
              >
                Región:
              </p>
            </IonCol>
            <IonCol>
              <p className="ion-no-margin event-details-item-text-md">
                {region}
              </p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-justify-content-end">
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <CardFooter
                  id={''}
                  idInt={id}
                  alert={type}
                  buttonLink={`events/${id}`}
                  active={true}
                  cardType={'Event'}
                  eventName={name}
                  eventStatus={status}
                  idIntParent={idIntParent}
                  shareData={{
                    id,
                    type,
                    name,
                    status,
                  }}
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>

      <div
        className={`event-details-content-block${
          !isParentActive ? '-inactive' : ''
        } event event-details-item`}
      >
        <div style={{ width: '100%' }}>
          <h2 className="event-details-item-header2">
            Afectación de este evento
          </h2>
          <IonGrid className="event-details-item-grid">
            <IonRow className="ion-align-items-center">
              <IonCol
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <IonIcon
                  size="large"
                  icon={FireIcon}
                  aria-label="Hectáreas consumidos"
                />
                <IonGrid
                  style={{
                    marginLeft: 'var(--space-4)',
                    padding: '0',
                  }}
                >
                  <IonRow className="ion-align-items-center">
                    <IonCol className="alert-afectations-grid-col">
                      <div
                        style={{ marginLeft: '0' }}
                        className="alert-afectations-grid-col-block"
                      >
                        <p className="ion-no-margin">Hectáreas consumidas</p>
                        <p className="ion-no-margin">
                          {affectations?.surface !== undefined ? (
                            Math.round(
                              (affectations.surface + Number.EPSILON) * 100
                            ) / 100
                          ) : (
                            <IonText>--</IonText>
                          )}
                        </p>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>

            {/* =========== AFFECTATIONS OF TYPE 'persona' =========== */}
            <IonRow className="ion-align-items-center">
              <IonCol
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <IonIcon
                  size="large"
                  icon={PersonIcon}
                  aria-label="Lesionados"
                />
                <IonGrid
                  style={{
                    marginLeft: 'var(--space-4)',
                    padding: '0',
                  }}
                >
                  <IonRow className="ion-align-items-center">
                    <IonCol className="alert-afectations-grid-col">
                      <div
                        style={{ marginLeft: '0' }}
                        className="alert-afectations-grid-col-block"
                      >
                        <p className="ion-no-margin">Lesionados</p>
                        <p className="ion-no-margin">
                          {affectations?.lesionadas !== undefined ? (
                            <IonText>{affectations.lesionadas}</IonText>
                          ) : (
                            <IonText>--</IonText>
                          )}
                        </p>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-align-items-center">
                    <IonCol className="alert-afectations-grid-col">
                      <div
                        style={{ marginLeft: '0' }}
                        className="alert-afectations-grid-col-block"
                      >
                        <p className="ion-no-margin">Damnificados</p>
                        <p className="ion-no-margin">
                          {affectations?.damnificadas !== undefined ? (
                            <IonText>{affectations.damnificadas}</IonText>
                          ) : (
                            <IonText>--</IonText>
                          )}
                        </p>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>

            <IonRow className="ion-align-items-center">
              <IonCol
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <IonIcon
                  size="large"
                  icon={PersonOutlineIcon}
                  aria-label="Fallecidos"
                />
                <IonGrid
                  style={{
                    marginLeft: 'var(--space-4)',
                    padding: '0',
                  }}
                >
                  <IonRow className="ion-align-items-center">
                    <IonCol className="alert-afectations-grid-col">
                      <div
                        style={{ marginLeft: '0' }}
                        className="alert-afectations-grid-col-block"
                      >
                        <p className="ion-no-margin">Fallecidos</p>
                        <p className="ion-no-margin">
                          {affectations?.fallecidas !== undefined ? (
                            <IonText>{affectations.fallecidas}</IonText>
                          ) : (
                            <IonText>--</IonText>
                          )}
                        </p>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>

            {/* =========== AFFECTATIONS OF TYPE 'vivienda ' =========== */}
            <IonRow
              className="ion-align-items-center"
              style={
                affectations?.comments && affectations?.comments.length > 0
                  ? { ...{ borderBottom: '1px solid #707070' } }
                  : {}
              }
            >
              <IonCol
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <IonIcon
                  size="large"
                  icon={HouseIcon}
                  aria-label="Viviendas destruidas"
                />
                <IonGrid
                  style={{
                    marginLeft: 'var(--space-4)',
                    padding: '0',
                  }}
                >
                  <IonRow className="ion-align-items-center">
                    <IonCol className="alert-afectations-grid-col">
                      <div
                        style={{ marginLeft: '0' }}
                        className="alert-afectations-grid-col-block"
                      >
                        <p className="ion-no-margin">Viviendas destruidas</p>
                        <p className="ion-no-margin">
                          {affectations?.destruidas !== undefined ? (
                            <IonText>{affectations.destruidas}</IonText>
                          ) : (
                            <IonText>--</IonText>
                          )}
                        </p>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-align-items-center">
                    <IonCol className="alert-afectations-grid-col">
                      <div
                        style={{ marginLeft: '0' }}
                        className="alert-afectations-grid-col-block"
                      >
                        <p className="ion-no-margin">
                          Viviendas con daño mayor
                        </p>
                        <p className="ion-no-margin">
                          {affectations?.danoMayor !== undefined ? (
                            <IonText>{affectations.danoMayor}</IonText>
                          ) : (
                            <IonText>--</IonText>
                          )}
                        </p>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-align-items-center">
                    <IonCol className="alert-afectations-grid-col">
                      <div
                        style={{ marginLeft: '0' }}
                        className="alert-afectations-grid-col-block"
                      >
                        <p className="ion-no-margin">Viviendas en evaluación</p>
                        <p className="ion-no-margin">
                          {affectations?.evaluacion !== undefined ? (
                            <IonText>{affectations.evaluacion}</IonText>
                          ) : (
                            <IonText>--</IonText>
                          )}
                        </p>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
          </IonGrid>

          {/* Affectation comments */}
          {affectations?.comments && affectations.comments.length > 0 && (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            <div
              style={{
                paddingLeft: 'var(--space-4)',
              }}
            >
              <p
                style={{
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: 'var(--body-small)',
                  color: '#767676',
                }}
              >
                Último comentario registrado
              </p>

              <p
                style={{
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: 'var(--body-small)',
                  color: '#000000',
                }}
              >
                {affectations.comments[affectations.comments.length - 1]}
              </p>
            </div>
          )}
        </div>
      </div>

      <div
        className={`event-details-content-block${
          !isParentActive ? '-inactive' : ''
        } event event-details-item`}
      >
        <div style={{ width: '100%' }}>
          <h2 className="event-details-item-header2">
            Recursos en este evento
          </h2>
          <p
            style={{
              fontWeight: 'var(--font-weight-regular)',
              fontSize: 'var(--caption)',
              color: 'var(--itrend--gray-500)',
            }}
          >
            Último reporte
          </p>
          <IonGrid className="alert-details-item-grid">
            <IonRow className="ion-align-items-center">
              <IonAccordionGroup
                style={{
                  width: '100%',
                  backgroundColor: 'transparent',
                }}
              >
                {resourcesNew &&
                  (resourcesNew.length > 0 ? (
                    <Resources
                      showInAlertDetails={false}
                      eventsDetails={eventDetails}
                      resources={resources}
                      resourcesNew={resourcesNew}
                    />
                  ) : (
                    <span>Sin recursos</span>
                  ))}
              </IonAccordionGroup>
            </IonRow>
          </IonGrid>
        </div>
      </div>

      {/* <div
        className={`event-details-content-block${
          !isParentActive ? '-inactive' : ''
        } event event-details-item`}
      >
        <div style={{ width: '100%' }}>
          <h2 className="event-details-item-header2">Mensaje SAE</h2>
          {saeIncidents && saeIncidents.length > 0 && (
            <ul
              style={{
                paddingLeft: 'var(--space-4)',
              }}
            >
              {saeIncidents
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .map((sae: { updatedAt: Date }, index: number) => (
                  <li
                    key={`sae_${index}`}
                    style={{
                      marginTop: 'var(--space-2)',
                    }}
                  >
                    <span
                      style={{
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: 'var(--body-small)',
                      }}
                    >
                      Mensaje #{saeIncidents.length - index}
                      {': '}
                    </span>
                    {format(sae.updatedAt, "dd LLLL yyyy 'a las' HH:mm", {
                      locale: es,
                    })}
                  </li>
                ))}
            </ul>
          )}
          {saeIncidents && saeIncidents.length === 0 && <p>Sin mensajes.</p>}
          {!saeIncidents && <p>Sin información.</p>}
        </div>
      </div> */}

      <div
        className={`event-details-content-block${
          !isParentActive ? '-inactive' : ''
        } event event-details-item`}
      >
        <div style={{ width: '100%' }}>
          <h2 className="event-details-item-header2">Comentarios generales</h2>
          {latestIncidentComments ? (
            <p
              style={{
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: 'var(--body-small)',
              }}
            >
              {latestIncidentComments}
            </p>
          ) : (
            <p>Sin comentarios</p>
          )}
          {latestIncidentComments && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <IonButton
                fill="clear"
                style={{
                  align: 'right',
                  fontSize: 'var(--body-medium)',
                }}
                onClick={() => {
                  setCommentsModalProps({
                    isParentActive,
                    timeline,
                    makeStatusBasedMarker,
                  });
                  setIsCommentsModalOpen(true);
                }}
              >
                Ver comentarios Anteriores
              </IonButton>
            </div>
          )}
        </div>
      </div>

      <div
        className={`event-details-content-block${
          !isParentActive ? '-inactive' : ''
        } event event-details-item`}
      >
        <h2 className="event-details-item-header2">Fuentes de información</h2>
        <p>{informationSources}</p>
      </div>

      <div
        className={`event-details-content-block${
          !isParentActive ? '-inactive' : ''
        } event event-details-item`}
      >
        <div style={{ width: '100%' }}>
          <h2 className="event-details-item-header2">Bitácora del evento</h2>
          <p
            style={{
              fontWeight: 'var(--font-weight-regular)',
              fontSize: 'var(--caption)',
              color: 'var(--itrend--gray-500)',
            }}
          >
            Estados del evento en el tiempo
          </p>
          {timeline && (
            <TimeLineCustom
              timeline={timeline}
              marker={makeStatusBasedMarker(timeline, isParentActive)}
              type={'Event'}
            />
          )}
        </div>
      </div>
    </IonContent>
  );
};

export default EventWrapper;
