import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import './App.scss';
import './theme/variables.css';
import '@aws-amplify/ui-react/styles.css';

import { Amplify, I18n, Hub } from 'aws-amplify';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import {
  Authenticator,
  Image,
  translations,
  useAuthenticator,
  useTheme,
  View,
} from '@aws-amplify/ui-react';
import { Capacitor } from '@capacitor/core';
import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import { ErrorComponent } from './components';
import { ROUTES } from './constants';
import NotificationsInitializerNative from './NotificationsInitializerNative';
import NotificationsInitializerWebpush from './NotificationsInitializerWebpush';
import AppTabs from './pages/AppTabs';
import About from './pages/About';
import Politica from './pages/Politica';
import IncendiosLogo from './theme/incendios_logo.png';
import ItrendLogo from './theme/itrend_logo_vertical.png';

const awsUserPoolUri = process.env.REACT_APP_COGNITO_USER_POOL_URI;
const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsUserPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
const awsClientId = process.env.REACT_APP_COGNITO_CLIENT_ID as string;
const awsUri = `https://${awsUserPoolUri}.auth.${awsRegion}.amazoncognito.com`;
const appVersion = process.env.REACT_APP_CONDOR_APP_VERSION;

const awsConfig = {
  Auth: {
    region: awsRegion,
    userPoolId: awsUserPoolId,
    userPoolWebClientId: awsClientId, // change in iac
    mandatorySignIn: false,
    oauth: {
      domain: awsUri,
      scope: ['phone', 'email'],
      responseType: 'code',
    },
  },
};
Amplify.configure(awsConfig);

I18n.putVocabularies(translations);
I18n.setLanguage('es');
I18n.putVocabularies({
  es: {
    'Reset Password': 'Restablecer contraseña',
    'Code *': 'Código *',
    'Please confirm your Password': 'Escriba su Contraseña',
    'Family Name': 'Apellido',
    'Enter your Family Name': 'Escriba su Apellido',
    Name: 'Nombre',
    'Enter your Name': 'Escriba su Nombre',
    'Escriba su nombre de usuario': 'Escriba su Nombre de usuario',
    'Delete Account': 'Eliminar cuenta',
    'Deleting your account is not reversable. You will lose access to your account and all data associated with it.':
      'Eliminar su cuenta no es reversible. Perderá el acceso a su cuenta y todos los datos asociados a ella.',
    'Delete my account': 'Eliminar mi cuenta',
    Cancel: 'Cancelar',
    'Current Password': 'Contraseña actual',
    'Update password': 'Actualizar contraseña',
  },
});

setupIonicReact({ mode: 'md' });

const userErrorLogger = (message: string) => {
  if (
    !Capacitor.isNativePlatform() ||
    window.location.hostname === 'localhost'
  ) {
    return;
  }

  const err = new Error();

  err.message = 'AWS Amplify: ' + message;

  cwr('recordError', err);
};

const listener = (data: any) => {
  switch (data?.payload?.event) {
    case 'signIn_failure':
      userErrorLogger('user sign in failed');
      break;
    case 'autoSignIn_failure':
      userErrorLogger('auto sign in failed');
      break;
    case 'forgotPassword_failure':
      userErrorLogger('password recovery failed');
      break;
    case 'forgotPasswordSubmit_failure':
      userErrorLogger('password confirmation failed');
      break;
    case 'tokenRefresh_failure':
      userErrorLogger('token refresh failed');
      break;
    case 'cognitoHostedUI_failure':
      userErrorLogger('Cognito Hosted UI sign in failed');
      break;
    default:
      break;
  }
};

Hub.listen('auth', listener);

declare function cwr(operation: string, payload: any): void;

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <div
          style={{
            backgroundColor: '#082F5C',
            paddingBottom: '30px',
          }}
        >
          <Image
            alt="Incendios Forestales logo"
            src={IncendiosLogo}
            style={{
              width: '100%',
            }}
          />
        </div>
      </View>
    );
  },

  
  Footer() {
    const { toResetPassword } = useAuthenticator();

    return (
      <View textAlign="center" backgroundColor="white">
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div>
            {/* <a
              href="/politica"
              style={{
                fontWeight: 'var(--font-weight-normal)',
                fontSize: 'var(--caption)',
                marginTop: 'var(--space-4)',
                marginRight: '0px',
                marginBottom: '0px',
                marginLeft: '0px',
                textDecoration: 'none',
              }}
            >
              Crear una cuenta
            </a> */}
          </div>

          <div>
            <Image alt="Itrend logo" src={ItrendLogo} />
            <div
              style={{
                textAlign: 'left',
                width: '100%',
                padding: '0 var(--space-4)',
              }}
            >
              <a
                href="/politica"
                style={{
                  fontWeight: 'var(--font-weight-normal)',
                  fontSize: 'var(--caption)',
                  margin: '0',
                  marginTop: 'var(--space-4)',
                  textDecoration: 'none',
                }}
              >
                Política de privacidad
              </a>
              <p
                style={{
                  fontWeight: 'var(--font-weight-normal)',
                  fontSize: 'var(--caption)',
                  color: 'var(--itrend--gray-800)',
                  margin: '0',
                }}
              >
                © 2023 Instituto para la Resiliencia ante Desastres - Itrend
              </p>
              <p
                style={{
                  margin: '0',
                  fontSize: 'var(--caption)',
                  color: 'var(--itrend--gray-500)',
                  marginBottom: '0',
                }}
              >
                {`Versión ${appVersion || '1.0.0'} © `}
                <a href="/about" style={{ textDecoration: 'none' }}>
                  Itrend
                </a>
              </p>
            </div>
          </div>
        </div>
      </View>
    );
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: 'Escriba su nombre de usuario',
    },
    password: {
      label: 'Contraseña',
    },
  },
};

export const sendActivityToRum = (
  category: string,
  action: string,
  value = ''
) => {
  const type = `custom_event.${category}.${action}${value ? '.' + value : ''}`;

  try {
    cwr('recordEvent', {
      type,
      data: {
        category,
        action,
        value,
      },
    });
  } catch (error) {
    return error;
  }
};

const ProtectedApp = () => {
  const errorFallbackFunction = ({ error, resetErrorBoundary }: any) => {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre style={{ color: 'red' }}>{error}</pre>
      </div>
    );
  };

  const errorFallbackComponent = ({ error, resetErrorBoundary }: any) => {
    return (
      <ErrorComponent
        code={'error'}
        header={ROUTES.error.header}
        message={ROUTES.error.message}
      />
    );
  };

  return (
    <Authenticator components={components} formFields={formFields} hideSignUp>
      <Authenticator.Provider>
        <ErrorBoundary
          // fallbackRender={errorFallbackFunction}
          FallbackComponent={errorFallbackComponent}
          onReset={(details) => {
            // Reset the state of your app so the error doesn't happen again
          }}
        >
          <IonApp>
            <IonReactRouter>
              {Capacitor.getPlatform() === 'web' && (
                <NotificationsInitializerWebpush />
              )}

              {Capacitor.isNativePlatform() && (
                <NotificationsInitializerNative />
              )}

              <Switch>
                <Route
                  exact={true}
                  path={`/tabs${ROUTES.alertDetails.path}${ROUTES.eventDetails.path}`}
                  render={() => <AppTabs />}
                />
                <Route
                  exact={true}
                  path={`/tabs${ROUTES.alertDetails.path}`}
                  render={() => <AppTabs />}
                />
                <Route path="/tabs" render={() => <AppTabs />} />

                <Route
                  exact={true}
                  path="/"
                  render={() => <Redirect to={ROUTES.home.path} />}
                />
                <Route render={() => <Redirect to={ROUTES['404'].path} />} />
              </Switch>
            </IonReactRouter>
          </IonApp>
        </ErrorBoundary>
      </Authenticator.Provider>
    </Authenticator>
  );
};

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact={true} path="/politica" render={() => <Politica />} />
        <Route exact={true} path="/about" render={() => <About />} />
        <Route render={() => <ProtectedApp />} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
