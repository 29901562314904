import './FullPageLoader.scss';

import React from 'react';

import { IonCol, IonContent, IonGrid, IonRow, IonSpinner, IonText } from '@ionic/react';

const FullPageLoader: React.FC = () => {
  return (
    <IonContent>
      <IonGrid
        style={{
          width: '100%',
          height: '100%',
          textAlign: 'center',
        }}
      >
        <IonRow style={{ paddingTop: '50%' }}>
          <IonCol>
            <IonSpinner
              className="full-page-spinner"
              name="circular"
            ></IonSpinner>
            {/* <ProgressSpinner
          className="full-page-spinner"
          strokeWidth="4"
        /> */}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonText
              style={{
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '120%',
              }}
            >
              Cargando ...
            </IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default FullPageLoader;
