import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import {
  combineReducers,
  configureStore,
  MiddlewareAPI,
  Middleware,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import favoritesReducer from './features/favorites/favorites';
import notificationsReducer from './features/notifications/notifications';
import readNewInfoReducer from './features/readNewInfo/readNewInfo';
import settingsReducer from './features/settings/settings';
import { CDAlertasApi } from './services/CDAlertasApi';
import { CDTestNotificationsApi } from './services/CDNotificationsApi';

const settingsPersistConfig = {
  key: 'settings',
  storage,
  whitelist: ['setSettings'],
};

const favoritesPersistConfig = {
  key: 'favorites',
  storage,
  whitelist: [
    'currentFavorites',
    'historyFavorites',
    'unfollowMessagesEnabled',
  ],
};

const readNewInfoPersistConfig = {
  key: 'readNewInfo',
  storage,
  whitelist: ['readNewInfoAlertList'],
};

const notificationsPersistConfig = {
  key: 'notifications',
  storage,
  whitelist: [
    'fcmToken',
    'endpointARN',
    'didCloseDeniedBanner',
    'closeDeniedBannerTimestamp',
    'types',
    'lifecycle',
    'notifyFollowing',
  ],
};

const rootReducer = combineReducers({
  favorites: persistReducer(favoritesPersistConfig, favoritesReducer),
  readNewInfo: persistReducer(readNewInfoPersistConfig, readNewInfoReducer),
  settings: persistReducer(settingsPersistConfig, settingsReducer),
  notifications: persistReducer(
    notificationsPersistConfig,
    notificationsReducer
  ),
  [CDAlertasApi.reducerPath]: CDAlertasApi.reducer,
  [CDTestNotificationsApi.reducerPath]: CDTestNotificationsApi.reducer,
});

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};
const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // if (isRejectedWithValue(action)) {
    //   const statusCode = next(action).payload.originalStatus;
    //   console.log('next(action).payload.originalStatus', action);
    //   if (statusCode === 500) {
    //     window.location.href = '/tabs/500';
    //   } else if (statusCode === 404 || statusCode === 400) {
    //     window.location.href = '/tabs/404';
    //   } else {
    //     window.location.href = '/tabs/error';
    //   }

    //   return;
    // }

    return next(action);
  };
// * Careful with typing: https://github.com/reduxjs/redux-thunk/issues/333
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(thunk)
      .concat(CDAlertasApi.middleware)
      .concat(CDTestNotificationsApi.middleware)
      .concat(rtkQueryErrorLogger),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
export default store;

export type RootState = ReturnType<typeof store.getState & PersistPartial>;
export type AppDispatch = typeof store.dispatch;
