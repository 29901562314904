/* eslint-disable prefer-const */
/* eslint-disable no-var */

import { createAsyncThunk } from '@reduxjs/toolkit';

import { CDAlertasApi } from '../../services/CDAlertasApi';
import { AppDispatch, RootState } from '../../store';
import { snsSubscribeTopicChunked } from '../featuresUtils';
import {
  AlertLifecycle,
  alertLifecycleCodes,
} from '../notifications/notifications';

export const addCardToFavorites = createAsyncThunk<
  string,
  string,
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('favorites/add_card_id', async (cardId: string, thunkApi) => {
  // Check notif config
  const { notifications } = thunkApi.getState();
  // const { isFirebaseInitialized, endpointARN, vapid } = notifications;

  const shouldSubscribe = notifications.notifyFollowing;
  // ! Early return since it should not subscribe
  if (!shouldSubscribe) return cardId;

  // Get alert details (ARN)
  const alert = await thunkApi.dispatch(
    CDAlertasApi.endpoints.getAlertsWithIds.initiate({ idsToCheck: [cardId] })
  );
  const arn = alert?.data?.data?.[0]?.topicData?.arn;

  // Get enabled lifecycles
  const { lifecycle } = notifications;
  const enabledLifecycles = Object.entries(lifecycle)
    .filter((l) => l[1])
    .map((l) => l[0]);
  const lifecycleCodes = enabledLifecycles.map(
    (l) => alertLifecycleCodes[l as AlertLifecycle]
  );
  // Define filter
  const filters = {
    actionType: lifecycleCodes,
  };

  // Subscribe to ARNs
  await snsSubscribeTopicChunked({
    topicARN: [arn],
    endpoint: notifications.endpointARN,
    filters,
    thunkApi,
  });

  // * Always add alert to favorites, even if subscription failed.
  return cardId;
});

export const addCardToFavoritesBulk = createAsyncThunk<
  string[],
  string[],
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('favorites/add_card_id_bulk', async (cardIds: string[], thunkApi) => {
  // Check notif config
  const { notifications } = thunkApi.getState();

  const shouldSubscribe = notifications.notifyFollowing;
  // ! Early return since it should not subscribe
  if (!shouldSubscribe) return cardIds;

  // Get alert details (ARN)
  const alerts = await thunkApi.dispatch(
    CDAlertasApi.endpoints.getAlertsWithIds.initiate({ idsToCheck: cardIds })
  );
  const arns = (alerts?.data?.data || []).map((a: any) => a?.topicData?.arn);

  // Get enabled lifecycles
  const { lifecycle } = notifications;
  const enabledLifecycles = Object.entries(lifecycle)
    .filter((l) => l[1])
    .map((l) => l[0]);
  const lifecycleCodes = enabledLifecycles.map(
    (l) => alertLifecycleCodes[l as AlertLifecycle]
  );
  // Define filter
  const filters = {
    actionType: lifecycleCodes,
  };

  // Attempt to subscribe to ARNs
  await snsSubscribeTopicChunked(
    (lifecycleCodes || []).length > 0
      ? {
          topicARN: arns,
          endpoint: notifications.endpointARN,
          filters,
          thunkApi,
        }
      : { topicARN: arns, endpoint: notifications.endpointARN, thunkApi }
  );

  // * Always add alert to favorites, even if subscription failed.
  return cardIds;
});

export const addCardToFavoritesWithoutSubscribingBulk = createAsyncThunk<
  string[],
  string[],
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('favorites/add_card_id_no_subs_bulk', async (cardIds: string[], thunkApi) => {
  return cardIds;
});

export const addCardToFavoritesBulkRaw = createAsyncThunk<
  string[],
  string[],
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('favorites/add_card_id_bulk_raw', async (cardIds: string[], _) => {
  return cardIds;
});

export const removeCardFromFavorites = createAsyncThunk<
  string,
  string,
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('favorites/remove_card_id', async (cardId: string, thunkApi) => {
  // Check notif config
  const { notifications } = thunkApi.getState();
  // Get alert details (ARN)
  const alert = await thunkApi.dispatch(
    CDAlertasApi.endpoints.getAlertsWithIds.initiate({ idsToCheck: [cardId] })
  );
  const arn = alert?.data?.data?.[0]?.topicData?.arn;
  // Unsubscribe to ARN
  const _resp = await thunkApi.dispatch(
    CDAlertasApi.endpoints.snsUnsubscribeTopic.initiate({
      topicARN: [arn],
      endpoint: notifications.endpointARN,
    })
  );

  // * Always remove alert to favorites, even if subscription failed.
  return cardId;
});

export const removeCardFromFavoritesBulkRaw = createAsyncThunk<
  string[],
  string[],
  {
    dispatch: AppDispatch;
    rejectValue: string;
    state: RootState;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('favorites/remove_card_id_bulk_raw', async (cardIds: string[], _) => {
  return cardIds;
});

export const removeCardsFromFavorites = createAsyncThunk<
  string[],
  string[],
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('favorites/remove_cards_id', async (cardIds: string[], _) => {
  return cardIds;
});

export const disableUnfollowMessages = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('favorites/disable_unfollow_messages', async (_) => {
  return;
});

export const toggleAlertsListUpdate = createAsyncThunk<
  boolean,
  boolean,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('favorites/toggle_alerts_list_update', async (value: boolean, _) => {
  return value;
});

export const setAlertIdToRemoveFromList = createAsyncThunk<
  string,
  string,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>('favorites/set_alert_id_to_remove', async (value: string, _) => {
  return value;
});
