import { IonContent } from '@ionic/react';
import React from 'react';
import TimeLineCustom from './TimeLine';

const CommentsModal: React.FC<any> = ({ commentsModalProps }) => {
  const { isParentActive, timeline, makeStatusBasedMarker } =
    commentsModalProps;

  return (
    <IonContent
      className={`ion-content-custom-background${
        !isParentActive ? '-inactive' : ''
      }`}
    >
      <div
        className={`event-details-content-block${
          !isParentActive ? '-inactive' : ''
        } event event-details-item`}
      >
        <p
          style={{
            marginTop: '0',
            fontWeight: 'var(--font-weight-bold)',
            fontSize: 'var(--body-small)',
            color: '#717171',
            marginBottom: 'var(--space-4)',
          }}
        >
          Comentarios generales por cada registro del evento
        </p>
        {timeline && (
          <TimeLineCustom
            timeline={timeline}
            marker={makeStatusBasedMarker(timeline, isParentActive)}
            type={'Comments'}
            elemClassName={'--body-small'}
          />
        )}
      </div>
    </IonContent>
  );
};

export default CommentsModal;
