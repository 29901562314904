//import { setFilterByRegion, setFilterByType } from '../features/settings/settingsActions';
import './FilterMenu.scss';

import { addIcons } from 'ionicons';
import { close } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { menuController } from '@ionic/core/components';
import {
  IonButton,
  IonCheckbox,
  IonChip,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenu,
  IonMenuToggle,
  IonTitle,
} from '@ionic/react';

import { alertLevels, alertTypes, regions } from '../constants';
import {
  setFilterByLevelCanceladas,
  setFilterByLevelVigentes,
  setFilterByRegionCanceladas,
  setFilterByRegionVigentes,
  setFilterByTypeCanceladas,
  setFilterByTypeVigentes,
} from '../features/settings/settingsActions';
import { useAppSelector } from '../hooks';
import { RootState } from '../store';
import { sendActivityToRum } from '../App';

addIcons({
  'fav-a': 'assets/icon/fav-a.svg',
  'fav-ia': 'assets/icon/fav-ia.svg',
  share: 'assets/icon/share.svg',
});

const FilterMenu: React.FC<{
  refMenu: React.MutableRefObject<HTMLIonMenuElement | null>;
  setIsFilterMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ refMenu, setIsFilterMenuOpen }) => {
  const currentPage = window.location.pathname.split('/')[2];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: Dispatch<any> = useDispatch();

  const {
    filterByTypeVigentes,
    filterByLevelVigentes,
    filterByRegionVigentes,
  } = useAppSelector((state: RootState) => state.settings);
  const {
    filterByTypeCanceladas,
    filterByLevelCanceladas,
    filterByRegionCanceladas,
  } = useAppSelector((state: RootState) => state.settings);
  const { filterByType, filterByRegion, filterByLevel } =
    currentPage === 'vigentes'
      ? {
          filterByType: filterByTypeVigentes,
          filterByLevel: filterByLevelVigentes,
          filterByRegion: filterByRegionVigentes,
        }
      : {
          filterByType: filterByTypeCanceladas,
          filterByLevel: filterByLevelCanceladas,
          filterByRegion: filterByRegionCanceladas,
        };

  const [tempFilterByRegion, setTempFilterByRegion] = useState(filterByRegion);
  const [tempFilterByLevel, setTempFilterByLevel] = useState(filterByLevel);
  const [tempFilterByType, setTempFilterByType] = useState(filterByType);

  const filterCardsByType = (type: string, remove: boolean) => {
    if (remove) {
      setTempFilterByType(tempFilterByType.filter((t: string) => t !== type));
    } else {
      setTempFilterByType([...tempFilterByType, type]);
    }
  };

  const filterCardsByRegion = (el: string, region: any, e: any) => {
    if (region.checked) {
      setTempFilterByRegion([...tempFilterByRegion, el]);
    } else {
      setTempFilterByRegion(
        tempFilterByRegion.filter((region: string) => region !== el)
      );
    }
  };

  const filterCardsByLevel = (el: string, level: any, e: any) => {
    if (level.checked) {
      setTempFilterByLevel([...tempFilterByLevel, el]);
    } else {
      setTempFilterByLevel(
        tempFilterByLevel.filter((level: string) => level !== el)
      );
    }
  };

  const clearFiltersF = () => {
    setTempFilterByRegion([]);
    setTempFilterByLevel([]);
    setTempFilterByType([]);
  };

  useEffect(() => {
    const handleClose = (_event: any) => {
      // applyFilters();
      setIsFilterMenuOpen(false);
    };

    const element: any = refMenu.current;

    element.addEventListener('ionDidClose', handleClose);

    return () => {
      element.removeEventListener('ionDidClose', handleClose);
    };
  }, []);

  useEffect(() => {
    setTempFilterByType(filterByType);
    setTempFilterByLevel(filterByLevel);
    setTempFilterByRegion(filterByRegion);
  }, [filterByType, filterByRegion, filterByLevel]);

  let hasFocus = false;
  let hasFocus2 = false;

  const applyFilters = () => {
    if (currentPage === 'vigentes') {
      sendActivityToRum('filter', 'use');

      dispatch(setFilterByRegionVigentes(tempFilterByRegion));
      dispatch(setFilterByLevelVigentes(tempFilterByLevel));
      dispatch(setFilterByTypeVigentes(tempFilterByType));
    } else {
      dispatch(setFilterByRegionCanceladas(tempFilterByRegion));
      dispatch(setFilterByLevelCanceladas(tempFilterByLevel));
      dispatch(setFilterByTypeCanceladas(tempFilterByType));
    }
  };

  return (
    <IonMenu
      menuId="filter-menu"
      contentId="info-pane"
      className="filter-menu"
      side="end"
      ref={refMenu}
      // swipeGesture={false}
    >
      <IonHeader
        className="filter-menu-header ion-no-border"
        style={{
          padding: '10px 0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: 'calc(10px + var(--ion-safe-area-top))',
        }}
      >
        <IonTitle
          style={{ margin: '0', color: 'var(--itrend--blueGray-800)' }}
          className="headline-medium-bold"
        >
          Filtrar por
        </IonTitle>
        <IonMenuToggle menu="filter-menu">
          <IonButton fill="clear">
            <IonIcon
              icon={close}
              size="large"
              style={{ color: 'var(--itrend--blueGray-900)' }}
            ></IonIcon>
          </IonButton>
        </IonMenuToggle>
      </IonHeader>
      <IonContent className="ion-padding">
        <div style={{ padding: '0 20px' }}>
          <p
            className="body-large-bold"
            style={{
              color: 'var(--itrend--blueGray-900)',
              fontSize: 'var(--body-large)',
            }}
          >
            Tipo de Alerta
          </p>
          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            className="filter-menu-bytype"
          >
            {Object.keys(alertTypes).map((el) => (
              <IonChip
                className={`filter-menu-bytype-typechip ${
                  tempFilterByType.includes(el) &&
                  'filter-menu-bytype-typechip-active'
                }`}
                key={el}
                outline={!tempFilterByType.includes(el) ? false : true}
                onClick={() =>
                  filterCardsByType(el, tempFilterByType.includes(el))
                }
              >
                {el === 'Temprana Preventiva' ? 'Preventiva' : el}
              </IonChip>
            ))}
          </div>
        </div>
        <div style={{ padding: '0 20px' }}>
          <p
            className="body-large-bold"
            style={{
              color: 'var(--itrend--blueGray-900)',
              fontSize: 'var(--body-large)',
            }}
          >
            Nivel de cobertura
          </p>
          {alertLevels.map((el: any) => {
            // const k = Object.keys(el as object)[0];

            return (
              <IonItem
                key={el}
                style={{
                  padding: '0',
                  margin: '0',
                }}
              >
                <IonCheckbox
                  slot="start"
                  onFocus={() => (hasFocus2 = !hasFocus2)}
                  onIonChange={(e) =>
                    hasFocus2 && filterCardsByLevel(el, e.detail, e)
                  }
                  checked={tempFilterByLevel.includes(el)}
                ></IonCheckbox>
                <IonLabel
                  className="body-medium-medium"
                  style={{ color: 'var(--itrend--blueGray-900)' }}
                >
                  {el}
                </IonLabel>
              </IonItem>
            );
          })}
        </div>
        <div style={{ padding: '0 20px' }}>
          <p
            className="body-large-bold"
            style={{
              color: 'var(--itrend--blueGray-900)',
              fontSize: 'var(--body-large)',
            }}
          >
            Regiones
          </p>
          {regions.map((el: unknown) => {
            const k = Object.keys(el as object)[0];

            return (
              <IonItem
                key={k}
                style={{
                  padding: '0',
                  margin: '0',
                }}
              >
                <IonCheckbox
                  slot="start"
                  onFocus={() => (hasFocus = !hasFocus)}
                  onIonChange={(e) =>
                    hasFocus && filterCardsByRegion(k, e.detail, e)
                  }
                  checked={tempFilterByRegion.includes(k)}
                ></IonCheckbox>
                <IonLabel
                  className="body-medium-medium"
                  style={{ color: 'var(--itrend--blueGray-900)' }}
                >
                  {k}
                </IonLabel>
              </IonItem>
            );
          })}
        </div>
      </IonContent>
      <IonFooter
        style={{
          borderTop: '1px solid var(--itrend--blueGray-100)',
          marginTop: '1px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            padding: 'var(--space-3) 10px',
          }}
        >
          <IonButton
            shape="round"
            fill="outline"
            // outline={true}
            onClick={async () => {
              clearFiltersF();
              // await menuController.close('filter-menu');
            }}
          >
            Limpiar
          </IonButton>
          <IonButton
            shape="round"
            onClick={async () => {
              applyFilters();
              await menuController.close('filter-menu');
            }}
          >
            Aplicar
          </IonButton>
        </div>
      </IonFooter>
    </IonMenu>
  );
};

export default FilterMenu;
