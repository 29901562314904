import { IonContent, IonIcon, IonModal } from '@ionic/react';
import React, { useRef } from 'react';
import activeAlertIcon from '../../theme/icons/tabs-active-act.svg';
import './MapLegendModal.scss';

export const MapLegendModal: React.FC<any> = ({ isOpen, setIsOpen }) => {
  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={() => setIsOpen(false)}
      id="map-legend-modal"
      ref={modal}
      // trigger="open-legend-modal"
    >
      {/* <IonContent className="legend-modal-container"> */}
      <div
        style={{
          paddingLeft: '25px',
          paddingRight: '25px',
        }}
      >
        <p className="legend-modal-header">Símbolos y colores de alerta</p>
      </div>

      <div
        style={{
          paddingLeft: '10px',
          paddingRight: '10px',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
        className="legend-modal-elements"
      >
        <div className="legend-modal-elements-element">
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '90px',
              height: '40px',
            }}
          >
            {/* <div className="legend-modal-elements-element-chip legend-modal-elements-element-chip-big">
                <p style={{ width: '100%' }}>Región</p>
              </div>
              <div className="legend-modal-elements-element-chip legend-modal-elements-element-chip-big"></div>
              <div className="legend-modal-elements-element-chip legend-modal-elements-element-chip-big"></div>
              <div className="legend-modal-elements-element-chip legend-modal-elements-element-chip-big"></div> */}
            <IonIcon
              aria-label="Indicador de alerta regional, provincial o comunal"
              src={activeAlertIcon}
              style={{ width: '50%', height: '50%' }}
            ></IonIcon>
          </div>

          <div className="legend-modal-elements-element-arrow">
            <span className="arrow-right"></span>
          </div>

          <div
            style={{
              width: '50%',
            }}
          >
            Indicador de alerta regional, provincial o comunal
          </div>
        </div>
        <div className="legend-modal-elements-element">
          <div
            style={{
              width: '90px',
              paddingLeft: 'var(--space-3)',
              display: 'flex',
            }}
          >
            <div className="legend-modal-elements-element-chip"></div>
            <div className="legend-modal-elements-element-chip"></div>
            <div className="legend-modal-elements-element-chip"></div>
          </div>

          <div className="legend-modal-elements-element-arrow">
            <span className="arrow-right"></span>
          </div>

          <div
            style={{
              width: '50%',
            }}
          >
            Tipo de alerta Roja, Amarilla y Temprana Preventiva (Verde) - La
            región se colorea con el tipo más alto que afecte a alguno de sus
            territorios
          </div>
        </div>
        {/* <div className="legend-modal-elements-element">
            <div
              style={{
                width: '90px',
                paddingLeft: 'var(--space-3)',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div
                className="legend-modal-elements-element-chip"
                style={{
                  //   borderRadius: '50%',
                  backgroundColor: 'var(--itrend--primary-50)',
                }}
              ></div>
            </div>

            <div className="legend-modal-elements-element-arrow">
              <span className="arrow-right"></span>
            </div>

            <div
              style={{
                width: '50%',
              }}
            >
              Color para alertas provinciales o comunales sobre el mapa
            </div>
          </div> */}
      </div>
      {/* </IonContent> */}
    </IonModal>
  );
};

export default MapLegendModal;
