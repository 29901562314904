import './Alert.scss';

import {
  IonAccordionGroup,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonRefresher,
  IonRefresherContent,
  IonRow,
} from '@ionic/react';
import { chevronForwardOutline, chevronDownOutline } from 'ionicons/icons';
import { format, isAfter, subSeconds, sub, isBefore } from 'date-fns';
import { es } from 'date-fns/locale';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { mapResolution, newAlertThresholdTest } from '../constants';
import { addAlertToList } from '../features/readNewInfo/readNewInfoActions';
import { CDAlertasApi } from '../services/CDAlertasApi';
import FireIcon from '../theme/icons/fire.svg';
import HouseIcon from '../theme/icons/house.svg';
import PersonOutlineIcon from '../theme/icons/person-outline.svg';
import PersonIcon from '../theme/icons/person.svg';
import { CardFooter } from './AlertCard';
import {
  CardLocationView,
  makeLocationsTitleAndText,
} from './AlertCardLocation';
import EventCard, { EventCardProps } from './EventCard';
import Resources from './Resources';
import TimeLineCustom, { AlertTimelineItem } from './TimeLine';
import FullPageLoader from './loaders/FullPageLoader';
import warningIcon from '../theme/icons/warning.svg';
import warningIconGray from '../theme/icons/warning-gray.svg';

enum ActionChoices {
  CANCELA = 'Cancela',
}

enum LevelChoices {
  NACIONAL = 'Nacional',
  REGIONAL = 'Regional',
}

export interface EventProps {
  name: string;
}

export interface AlertProps {
  action: string;
  status: string;
  level?: string;
  alert: string;
  declaredDate: Date;
  updatedDate: Date;
  comunes?: string[];
  regions?: string[];
  affectations?: {
    surface?: number;
    lesionadasDamnificadas?: number;
    fallecidas?: number;
    viviendas?: number;
  };
  resources?: string[];
  resourcesNew?: any[];
  hasEventsDetails?: boolean;
  eventsDetails?: unknown[];
  timeline?: AlertTimelineItem[];
  title?: string;
  active?: boolean;
  locationsData?: any;
  id?: string;
  idInt?: string;
  isOpen: any;
  setIsOpen: any;
  checkIfTextClamped: any;
  allLocations: any;
  page: any;
  mapUrl: any;
  handleRefresh?: (evt: any) => void;
  setIsMapModalOpen: any;
  setCompleteListProps: any;
  setIsCompleteListOpen: any;
  subTitle: any;
  setIsMessageDetailsOpen: any;
  len: any;
  saeDataFiltered: any;
}

const saeDataFilterHours = process.env
  .REACT_APP_SAE_MESSAGE_FILTER_HOURS as unknown as number;

const AlertWrapper: React.FC<{
  alertId: string;
  page: string;
  setHeaderData: any;
  setIsMapModalOpen: any;
  setMapModalProps: any;
  setCompleteListProps: any;
  setIsCompleteListOpen: any;
  subTitle: any;
  len: any;
  setIsMessageDetailsOpen: any;
}> = ({
  alertId,
  page,
  setHeaderData,
  setIsMapModalOpen,
  setMapModalProps,
  setIsCompleteListOpen,
  setCompleteListProps,
  subTitle,
  len,
  setIsMessageDetailsOpen,
}) => {
  const dispatch: Dispatch<any> = useDispatch();

  const [isLoadingOpen, setIsLoadingOpen] = useState(false);

  const {
    data: alertData,
    isLoading,
    isFetching,
    isSuccess,
    refetch,
    isError: isAlertError,
    error: alertError,
  } = CDAlertasApi.useGetAlertByIdQuery(
    alertId
    // { skip: !loggedIn }
  );

  const {
    data: locationsData,
    isLoading: isLocationsLoading,
    isFetching: isLocationsFetching,
    isSuccess: isLocationsSuccess,
    refetch: locationsRefetch,
    isError: isLocationsError,
    error: locationsError,
  } = CDAlertasApi.useGetAllAlertLocationsQuery(
    alertData?.data?.lastUpdate?.data?.id,
    {
      skip: !isSuccess || !alertData,
    }
  );

  const mapApiProtocol = process.env.REACT_APP_BACKEND_PROTOCOL as string;
  const mapApiUrl = process.env.REACT_APP_MAP_API_URL as string;
  const mapApiAddr = process.env.REACT_APP_MAP_API_ADDRESS as string;

  const locationsCuts =
    locationsData &&
    locationsData.data.map((el: any) => el.comuneData.cutComune).join('&cut=');

  const mapUrl =
    locationsCuts &&
    mapApiProtocol +
      '://' +
      mapApiUrl +
      mapApiAddr +
      '?rlevel=' +
      mapResolution +
      '&cut=' +
      locationsCuts;

  const regionsCodes: any =
    locationsData &&
    Array.from(
      new Set(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [...locationsData.data].map(
          (location: any) => location.regionData.cutRegion
        )
      )
    );

  const regionsCutsForRequest =
    regionsCodes && regionsCodes.join('&regionCut=');

  const {
    data: saeData,
    isLoading: isSaeLoading,
    isFetching: isSaeFetching,
    isSuccess: isSaeSuccess,
    refetch: saeRefetch,
    isError: isSaeError,
    error: saeError,
  } = CDAlertasApi.useGetSaeMessagesQuery(
    { region: regionsCutsForRequest },
    { skip: !regionsCodes }
  );

  const saeDataFiltered: any =
    saeData &&
    Object.entries(saeData)
      .map((el: any) => {
        return {
          index: el[0],
          ...el[1],
        };
      })
      .filter((el: any) => {
        const before = alertData.data.isActive
          ? new Date()
          : new Date(alertData.data.lastUpdateData.updateTime);
        const messageDate = new Date(el.DateTime);

        return (
          isAfter(
            messageDate,
            sub(new Date(alertData.data.creationTime), {
              hours: saeDataFilterHours,
              // hours: 5800,
            })
          ) && isBefore(messageDate, before)
        );
      });

  const comunes: string[] =
    locationsData &&
    Array.from(
      new Set(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        locationsData.data.map((location: any) => location.comuneData.label)
      )
    );

  const {
    data: eventsDetails,
    isSuccess: isEventsDetailsSuccess,
    isError: isEventDetailsError,
    error: eventDetailsError,
  } = CDAlertasApi.useGetAlertEventsDetailsQuery(alertData?.data?.id, {
    skip: !isSuccess || !alertData,
  });

  const sumSurface =
    eventsDetails &&
    eventsDetails.data.reduce(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (acc: number, evt: any) => acc + evt.lastIncident.data.surface,
      0
    );

  const {
    data: alertUpdates,
    isLoading: isUpdatesLoading,
    isFetching: isUpdatesFetching,
    isSuccess: isUpdatesSuccess,
    isError: isAlertUpdatesError,
    error: alertUpdatesError,
  } = CDAlertasApi.useGetAlertUpdatesQuery(alertId);

  const alertUpdatesDataForTimeline = [...(alertUpdates?.data || [])]
    .sort(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (a: any, b: any) =>
        new Date(b.updateTime).getTime() - new Date(a.updateTime).getTime()
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((update: any) => ({
      update: update,
      icon: 'pi',
      color: '#9C27B0',
    }));

  useEffect(() => {
    setIsLoadingOpen(
      isFetching ||
        isLoading ||
        isLocationsFetching ||
        isLocationsLoading ||
        isUpdatesLoading ||
        isUpdatesFetching ||
        isSaeLoading ||
        isSaeFetching
    );
  }, [
    isFetching,
    isLoading,
    isSuccess,
    isLocationsFetching,
    isLocationsLoading,
    isLocationsSuccess,
    isUpdatesSuccess,
    isSaeLoading,
    isSaeFetching,
    isSaeSuccess,
  ]);

  const allAffectations =
    alertData &&
    alertData.data.lastUpdate.data.events.data
      .map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (evt: any) => evt.lastIncident.data.affectations.data
      )
      .flat();

  // ! To process affectations and sum values, we need to
  // ! filter before to differentiate when there is no data
  // ! versus when the data is there and the sum is 0.
  const affectationsLesionadasDamnificadas =
    allAffectations &&
    allAffectations.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (aff: any) =>
        aff.typeData.type === 'persona' &&
        (aff.typeData.name === 'Lesionadas' ||
          aff.typeData.name === 'Damnificadas')
    );
  const sumLesionadasDamnificadas =
    affectationsLesionadasDamnificadas &&
    affectationsLesionadasDamnificadas
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((acc: number, aff: any) => acc + aff.cuantification, 0);

  const affectationsFallecidas =
    allAffectations &&
    allAffectations.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (aff: any) =>
        aff.typeData.type === 'persona' && aff.typeData.name === 'Fallecidas'
    );
  const sumLesionadasFallecidas =
    affectationsFallecidas &&
    affectationsFallecidas
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((acc: number, aff: any) => acc + aff.cuantification, 0);

  const affectationsViviendas =
    allAffectations &&
    allAffectations.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (aff: any) => aff.typeData.type === 'vivienda'
    );
  const sumViviendas =
    affectationsViviendas &&
    affectationsViviendas
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((acc: number, aff: any) => acc + aff.cuantification, 0);

  const resources =
    eventsDetails &&
    eventsDetails.data
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((evt: any) => evt.lastIncident.data.forestFireIncidentResources.data)
      .flat();

  const [isOpen, setIsOpen] = useState(false);
  const [isLocationsTextClamped, setIsLocationsTextClamped] = useState(false);

  const checkIfTextClamped = () => {
    const el = document.querySelectorAll(
      '.text-cut-3-line.alert-card-locations-text'
    );
    let res = false;

    if (el.length > 0) {
      el.forEach((elm: any) => {
        if (elm.scrollHeight > 0 && elm.scrollHeight > elm.clientHeight) {
          res = true;
        }
      });
    }

    return res;
  };
  const level = alertData && alertData.data.lastUpdateData.level;

  // 1 step
  const allLocations =
    regionsCodes &&
    (level === 'Regional'
      ? [
          makeLocationsTitleAndText(
            level,
            locationsData,
            regionsCodes[0],
            regionsCodes
          ),
        ]
      : regionsCodes.map((el: any) => {
          const { title, text } = makeLocationsTitleAndText(
            level,
            locationsData,
            el,
            regionsCodes
          );

          const res = { title: title, text: text };

          return res;
        }));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRefresh = async (evt: any) => {
    refetch();
    await locationsRefetch();
    evt.detail.complete();
  };

  const alertProps = alertData && {
    action: alertData.data.lastUpdateData.action,
    status: alertData.data.lastUpdateData.status,
    level,
    alert: 'Incendio forestal',
    declaredDate: new Date(alertData.data.creationTime),
    updatedDate: new Date(alertData.data.lastUpdateData.updateTime),
    comunes: comunes,
    regions: regionsCodes,
    affectations: {
      surface: sumSurface,
      lesionadasDamnificadas: sumLesionadasDamnificadas,
      fallecidas: sumLesionadasFallecidas,
      viviendas: sumViviendas,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resources: resources && resources.map((r: any) => r.typeData.name),
    resourcesNew: resources,
    hasEventsDetails: eventsDetails && eventsDetails.data.length >= 0,
    eventsDetails: eventsDetails && eventsDetails.data,
    timeline: alertUpdatesDataForTimeline,

    // title,
    active: alertData.data.isActive,
    locationsData,
    id: alertData.data.sinapredId,
    idInt: alertData.data.id,
    isOpen: isOpen,
    setIsOpen: setIsOpen,
    checkIfTextClamped: checkIfTextClamped,
    allLocations: allLocations,
    page: page,
    mapUrl: mapUrl,
    handleRefresh: handleRefresh,
    setIsMapModalOpen: setIsMapModalOpen,
    setCompleteListProps: setCompleteListProps,
    setIsCompleteListOpen: setIsCompleteListOpen,
    subTitle: subTitle,
    setIsMessageDetailsOpen: setIsMessageDetailsOpen,
    len: len,
    saeDataFiltered: saeDataFiltered,
  };

  const newAlertThreshold =
    (process.env.REACT_APP_SMTH_NEW_TIME_THRESHOLD_SECS as unknown as number) !=
    0
      ? process.env.REACT_APP_SMTH_NEW_TIME_THRESHOLD_SECS
      : newAlertThresholdTest;

  useEffect(() => {
    alertProps &&
      setHeaderData({
        id: alertProps.id,
        type: alertProps.status,
        active: alertProps.active,
        page: alertProps.page,
      });

    const limit = subSeconds(
      new Date(),
      newAlertThreshold as unknown as number
    );

    // if alert is in time limit - add to read list
    alertProps &&
      isAfter(alertProps.updatedDate, limit) &&
      dispatch(
        addAlertToList({
          alertId: alertProps.id,
          updateDateTime: alertProps.updatedDate.toISOString(),
        })
      );

    alertProps &&
      setMapModalProps({
        mapUrl,
        level,
        regions: regionsCodes,
        status: alertData.data.lastUpdateData.status,
      });
    // throw new Error('test');
  }, [alertData, mapUrl]);

  return (
    <>
      {isLoadingOpen && <FullPageLoader />}
      {!isLoadingOpen && alertData && <Alert {...alertProps} />}
    </>
  );
};

const makeStatusBasedMarker = (timeline: AlertTimelineItem[], active: any) => {
  return function statusBasedMarker(item: AlertTimelineItem) {
    const itemIndex = timeline.findIndex((e) => e.update.id === item.update.id);
    if (
      active &&
      (itemIndex === 0 ||
        /* Compare rendered strings to determine
      whether the events are 'at the same time'
      from the user point of view, and render them accordingly. */
        (itemIndex > 0 &&
          format(new Date(item.update.updateTime), 'dd LLLL yyyy HH:mm') ===
            format(
              new Date(timeline[0].update.updateTime),
              'dd LLLL yyyy HH:mm'
            )))
    ) {
      return <div className="p-timeline-event-marker timeline-active"></div>;
    } else {
      return <div className="p-timeline-event-marker"></div>;
    }
  };
};

const Alert: React.FC<AlertProps> = ({
  action,
  status,
  level,
  alert,
  declaredDate,
  updatedDate,
  comunes,
  isOpen,
  setIsOpen,
  regions,
  affectations = {},
  resources,
  resourcesNew,
  hasEventsDetails,
  eventsDetails,
  timeline,
  title,
  active,
  locationsData,
  id,
  idInt,
  checkIfTextClamped,
  allLocations,
  page,
  mapUrl,
  handleRefresh,
  setIsMapModalOpen,
  setCompleteListProps,
  setIsCompleteListOpen,
  subTitle,
  setIsMessageDetailsOpen,
  len,
  saeDataFiltered,
}) => {
  // const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [isLoadingOpen, setIsLoadingOpen] = useState(false);
  const [isLoactionsTextClamped, setIsLocationsTextClamped] = useState(false);

  useLayoutEffect(() => {
    if (!isLoactionsTextClamped)
      setIsLocationsTextClamped(checkIfTextClamped());
  });

  const [shareData, setShareData] = useState({
    id,
    alert,
    type: status,
    active,
    level,
    date: declaredDate,
    dateUpd: updatedDate,
  });

  const noEventsMessage = (
    <div className="alert-details-no-events-container">
      <p>
        UAT aún debe asociar Eventos a esta Alerta para ver esta información
      </p>
    </div>
  );

  return (
    <>
      <IonContent
        className={`ion-content-custom-background${
          !active ? '-inactive' : ''
        } alert-details-container alert-details-container-${
          active ? '' : 'in'
        }active-${status?.replace(/\s/g, '').toLocaleLowerCase()}`}
      >
        {handleRefresh && (
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
        )}

        {!active ? (
          <IonItem
            style={{
              '--background': 'var(--itrend--secondary-500)',
              marginTop: '0',
              position: 'fixed',
              zIndex: 100,
              width: '100%',
            }}
          >
            <div
              style={{
                height: 'var(--display-large)',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  marginRight: 'var(--space-3)',
                  fontWeight: 'var(--font-weight-bold)',
                  fontSize: 'var(--body-large)',
                  textTransform: 'uppercase',
                  color: 'var(--itrend--white)',
                }}
                className="ion-no-margin alert-details-item-text-lg"
              >
                CANCELADA:
              </p>
              <p
                className="ion-no-margin alert-details-item-text-lg"
                style={{
                  marginRight: 'var(--space-3)',
                  fontWeight: 'var(--font-weight-regular)',
                  fontSize: 'var(--body-large)',
                  color: 'var(--itrend--white)',
                }}
              >
                {format(updatedDate, "dd LLLL yyyy 'a las' HH:mm", {
                  locale: es,
                })}
              </p>
            </div>
          </IonItem>
        ) : null}

        <div
          className={`alert-details-content-block${
            !active ? '-inactive' : ''
          } alert ionitem-no-margin alert-details-item ${
            !active && 'alert-details-item-inactive'
          }`}
          style={{
            paddingBottom: '0',
            marginTop: active ? '0' : 'var(--display-large)',
          }}
        >
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol>
                <h1
                  className="alert-details-item-header1"
                  style={{
                    textOverflow: 'inherit',
                    whiteSpace: 'normal',
                    paddingTop: '0',
                    // textDecoration: !active ? 'line-through' : 'none',
                  }}
                >
                  {`${action} Alerta ${status} ${level} por ${alert}`}
                </h1>
              </IonCol>
            </IonRow>

            <IonRow style={{ margin: '10px 0' }}>
              <IonCol size="4">
                <p
                  className="ion-no-margin
              alert-details-item-text-md"
                  style={{ fontWeight: 'var(--font-weight-bold)' }}
                >
                  Declarada:
                </p>
              </IonCol>
              <IonCol>
                <p className="ion-no-margin alert-details-item-text-md">
                  {format(declaredDate, "dd LLLL yyyy 'a las' HH:mm", {
                    locale: es,
                  })}
                </p>
              </IonCol>
            </IonRow>

            {active ? (
              <IonRow
                style={{
                  margin: '10px 0',
                  paddingBottom: 'var(--space-3)',
                  borderBottom: '1px solid var(--itrend--blueGray-100)',
                }}
              >
                <IonCol size="4">
                  <p
                    className="ion-no-margin alert-details-item-text-md"
                    style={{ fontWeight: 'var(--font-weight-bold)' }}
                  >
                    {action === ActionChoices.CANCELA
                      ? 'Cancelada'
                      : 'Actualizada:'}
                  </p>
                </IonCol>
                <IonCol>
                  <p className="ion-no-margin alert-details-item-text-md">
                    {format(updatedDate, "dd LLLL yyyy 'a las' HH:mm", {
                      locale: es,
                    })}
                  </p>
                </IonCol>
              </IonRow>
            ) : null}

            {level !== 'Nacional' && (
              <IonRow
                style={{
                  paddingBottom: 'var(--space-3)',
                  borderBottom: '1px solid var(--itrend--blueGray-100)',
                }}
              >
                <IonCol>
                  {level !== 'Nacional' &&
                    (level === 'Regional' ? (
                      <CardLocationView
                        regions={regions}
                        level={level}
                        active={active}
                        title={title}
                        linesToCut={3}
                        source={'details'}
                        setShareData={setShareData}
                        shareData={shareData}
                      />
                    ) : (
                      regions?.map((region: any, index: any) => (
                        <CardLocationView
                          alertLocationsData={locationsData}
                          key={index}
                          linesToCut={3}
                          regions={regions}
                          level={level}
                          active={active}
                          title={title}
                          region={region}
                          source={'details'}
                          setShareData={setShareData}
                          shareData={shareData}
                        />
                      ))
                    ))}
                  {isLoactionsTextClamped && (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <IonButton
                        fill="clear"
                        style={{
                          align: 'right',
                          // marginTop: 'var(--space-3)',
                          fontSize: 'var(--body-medium)',
                        }}
                        onClick={() => {
                          setCompleteListProps(allLocations);
                          setIsCompleteListOpen(true);
                        }}
                      >
                        Ver listado completo
                      </IonButton>
                    </div>
                  )}
                </IonCol>
              </IonRow>
            )}

            <IonRow>
              <IonCol>
                <CardFooter
                  type={status}
                  level={level}
                  buttonTitle={'VER MAPA'}
                  buttonLink={`alerts/${idInt}`}
                  toggleFunc={setIsMapModalOpen}
                  id={id}
                  alert={alert}
                  shareData={shareData}
                  idInt={idInt}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        <div
          className={`alert-details-content-block${
            !active ? '-inactive' : ''
          } alert-details-item ${!active && 'alert-details-item-inactive'}`}
        >
          <div
            style={{
              width: '100%',
            }}
          >
            <h2 className="alert-details-item-header2">Afectación</h2>
            <p className="alert-details-item-text-sm">
              Suma de afectaciones de todos los eventos
            </p>

            {hasEventsDetails && eventsDetails && eventsDetails.length > 0
              ? affectations && (
                  <IonGrid className="alert-details-item-grid">
                    <IonRow className="ion-align-items-center">
                      <IonCol className="alert-afectations-grid-col">
                        <IonIcon
                          src={FireIcon}
                          aria-label="Hectáreas consumidas"
                        />
                        <div className="alert-afectations-grid-col-block">
                          <p className="ion-no-margin">Hectáreas consumidas</p>
                          <p className="ion-no-margin">
                            {affectations?.surface !== undefined
                              ? Math.round(
                                  (affectations.surface + Number.EPSILON) * 100
                                ) / 100
                              : '--'}
                          </p>
                        </div>
                      </IonCol>
                    </IonRow>

                    <IonRow className="ion-align-items-center">
                      <IonCol className="alert-afectations-grid-col">
                        <IonIcon
                          src={PersonIcon}
                          aria-label="Lesionados y/o damnificados"
                        />
                        <div className="alert-afectations-grid-col-block">
                          <p className="ion-no-margin">
                            Lesionados y/o damnificados
                          </p>
                          <p className="ion-no-margin">
                            {affectations?.lesionadasDamnificadas !== undefined
                              ? affectations.lesionadasDamnificadas
                              : '--'}
                          </p>
                        </div>
                      </IonCol>
                    </IonRow>

                    <IonRow className="ion-align-items-center">
                      <IonCol className="alert-afectations-grid-col">
                        <IonIcon
                          src={PersonOutlineIcon}
                          aria-label="Fallecidos"
                        />
                        <div className="alert-afectations-grid-col-block">
                          <p className="ion-no-margin">Fallecidos</p>
                          <p className="ion-no-margin">
                            {affectations?.fallecidas !== undefined
                              ? affectations?.fallecidas
                              : '--'}
                          </p>
                        </div>
                      </IonCol>
                    </IonRow>

                    <IonRow className="ion-align-items-center">
                      <IonCol className="alert-afectations-grid-col">
                        <IonIcon
                          src={HouseIcon}
                          aria-label="Viviendas afectadas"
                        />
                        <div className="alert-afectations-grid-col-block">
                          <p className="ion-no-margin">Viviendas afectadas</p>
                          <p className="ion-no-margin">
                            {affectations?.viviendas !== undefined
                              ? affectations.viviendas
                              : '--'}
                          </p>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                )
              : noEventsMessage}
          </div>
        </div>

        <div
          className={`alert-details-content-block${
            !active ? '-inactive' : ''
          } alert-details-item ${!active && 'alert-details-item-inactive'}`}
        >
          <div
            style={{
              width: '100%',
            }}
          >
            <h2 className="alert-details-item-header2">Recursos</h2>
            <p className="alert-details-item-text-sm">
              Última información de los eventos asociados
            </p>

            {hasEventsDetails && eventsDetails && eventsDetails.length > 0 ? (
              <IonGrid className="alert-details-item-grid">
                <IonRow className="ion-align-items-center">
                  <IonAccordionGroup
                    style={{
                      width: '100%',
                      backgroundColor: 'transparent',
                    }}
                  >
                    {resourcesNew &&
                      (resourcesNew.length > 0 ? (
                        <Resources
                          eventsDetails={eventsDetails}
                          resourcesNew={resourcesNew}
                          resources={resources}
                        />
                      ) : (
                        <span>Sin recursos</span>
                      ))}
                  </IonAccordionGroup>
                </IonRow>
              </IonGrid>
            ) : (
              noEventsMessage
            )}
          </div>
        </div>

        <div
          className={`alert-details-content-block${
            !active ? '-inactive' : ''
          } ion-padding alert-details-item ${
            !active && 'alert-details-item-inactive ion-item-but-div-inactive'
          }`}
        >
          <div
            style={{
              width: '100%',
            }}
          >
            <h2 className="alert-details-item-header2">Eventos</h2>

            {hasEventsDetails && eventsDetails && eventsDetails.length > 0
              ? eventsDetails.map(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (ed: any, index: number) => {
                    const eventCardProps: EventCardProps = {
                      startTime: ed.startTime,
                      idInt: ed.id,
                      surface: ed.lastIncident.data.surface,
                      id: id,
                      idIntParent: idInt,
                      // pageName: page,
                      active: active,
                      type: ed.eventTypeData.name,
                      name: ed.name,
                      comunes: (
                        ed?.lastIncident?.data?.comunes?.data || []
                      ).map(
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (c: any) => c.comuneData.label
                      ),
                      sectors: (
                        ed?.lastIncident?.data?.comunes?.data || []
                      ).map(
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (c: any) => c.sector
                      ),
                      status: ed.lastIncident.data.statusData.name,
                      updateCount: ed.eventable.data.incidents.data.length,
                    };
                    return <EventCard key={index} {...eventCardProps} />;
                  }
                )
              : noEventsMessage}
          </div>
        </div>

        <div
          className={`alert-details-content-block${
            !active ? '-inactive' : ''
          } alert-details-item ${!active && 'alert-details-item-inactive'}`}
        >
          <div>
            <h2 className="alert-details-item-header2">Historial</h2>
            <p className="alert-details-item-text-sm">
              Resumen de actualizaciones de la Alerta
            </p>
            {timeline && (
              <TimeLineCustom
                timeline={timeline}
                marker={makeStatusBasedMarker(timeline, active)}
              />
            )}
          </div>
        </div>

        <div
          className={`event-details-content-block${
            !active ? '-inactive' : ''
          } event event-details-item`}
        >
          <div style={{ width: '100%' }}>
            <h2 className="event-details-item-header2">
              Mensaje SAE {len === 1 ? 'en región ' : 'en regiones'} {subTitle}
            </h2>
            <p className="alert-details-item-text-sm">
              Mensajes enviados {saeDataFilterHours} horas antes de la
              declaración de la alerta hasta que se cancele.
            </p>

            {saeDataFiltered && saeDataFiltered.length === 0 ? (
              <p>Sin mensajes.</p>
            ) : (
              <div
                style={{
                  borderRadius: 'var(--border-radius-sm)',
                  backgroundColor: active
                    ? 'var(--warning-light)'
                    : 'transparent',
                  border:
                    '1px dashed ' +
                    (active
                      ? 'var(--warning-dark)'
                      : 'var(--itrend--gray-500)'),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}
              >
                <img
                  src={active ? warningIcon : warningIconGray}
                  style={{
                    width: 'var(--headline-medium) !important',
                    margin: 'var(--space-2)',
                    marginRight: 'var(--space-3)',
                  }}
                />
                <p
                  style={{
                    color: active ? 'var(--warning-dark)' : 'var(--color)',
                    fontSize: 'var(--body-medium)',
                  }}
                >
                  Estos mensajes no necesariamente están relacionados a la
                  alerta.
                </p>
              </div>
            )}

            {saeDataFiltered &&
              saeDataFiltered.map((sae: any, index: number) => {
                return (
                  <IonButton
                    key={index}
                    className="button-outline alert-details-sae-button ion-no-padding"
                    style={{
                      '--border-style': 'none',
                      borderBottom: '1px solid var(--itrend--blueGray-50)',
                      borderTop:
                        (index === 0 ? '1px' : '0px') +
                        ' solid var(--itrend--blueGray-50)',
                      borderTopWidth: index === 0 ? '1px' : '0px',
                      marginTop: index === 0 ? 'var(--space-4)' : '0px',
                      borderBottomWidth: '1px',
                      backgroundColor: 'transparent',
                      // pointerEvents: el.maxLevel ? 'auto' : 'none',
                    }}
                    onClick={() => {
                      setIsMessageDetailsOpen(sae);
                    }}
                  >
                    <div className="alert-details-sae-button-inner">
                      <p
                        className="alert-details-sae-button-inner-label"
                        style={{
                          flexGrow: 1,
                          textAlign: 'left',
                          paddingLeft: '0',
                          fontWeight: 'var(--font-weight-bold)',
                        }}
                      >
                        {sae.Polygon}
                      </p>
                      <p
                        className="alert-details-sae-button-inner-label"
                        style={{ textOverflow: 'initial' }}
                      >
                        {format(
                          new Date(sae.DateTime),
                          "dd/LL/yyyy ' ' HH:mm",
                          {
                            locale: es,
                          }
                        )}
                      </p>

                      <IonIcon
                        icon={chevronForwardOutline}
                        style={{
                          height: '20px',
                          width: '20px',
                          color: 'var(--itrend--gray-500)',
                        }}
                        aria-label={'Ir a mensage de ' + sae.Polygon}
                      />
                    </div>
                  </IonButton>
                );
              })}

            {/* {saeIncidents && saeIncidents.length > 0 && (
              <ul
                style={{
                  paddingLeft: 'var(--space-4)',
                }}
              >
                {saeIncidents
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  .map((sae: { updatedAt: Date }, index: number) => (
                    <li
                      key={`sae_${index}`}
                      style={{
                        marginTop: 'var(--space-2)',
                      }}
                    >
                      <span
                        style={{
                          fontStyle: 'normal',
                          fontWeight: '700',
                          fontSize: 'var(--body-small)',
                        }}
                      >
                        Mensaje #{saeIncidents.length - index}
                        {': '}
                      </span>
                      {format(sae.updatedAt, "dd LLLL yyyy 'a las' HH:mm", {
                        locale: es,
                      })}
                    </li>
                  ))}
              </ul>
            )}
            {saeIncidents && saeIncidents.length === 0 && <p>Sin mensajes.</p>}
            {!saeIncidents && <p>Sin información.</p>} */}
          </div>
        </div>
      </IonContent>
    </>
  );
};

export default AlertWrapper;
