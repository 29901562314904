import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import store from '../../store';
import { ReadNewInfoState } from './readNewInfo';
import {
  ADD_ALERT_TO_LIST,
  CHANGE_DATE_TIME_OF_ALERT,
  REMOVE_ALERT_FROM_LIST,
} from './readNewInfoTypes';

export const addAlertToList =
  (value: any) => (dispatch: ThunkDispatch<ReadNewInfoState, void, Action>) => {
    dispatch({
      type: ADD_ALERT_TO_LIST,
      payload: {
        [value.alertId]: value.updateDateTime,
      },
    });
  };

export const changeDateTimeOfAlert =
  (value: any) => (dispatch: ThunkDispatch<ReadNewInfoState, void, Action>) => {
    dispatch({
      type: CHANGE_DATE_TIME_OF_ALERT,
      payload: value,
    });
  };

export const removeAlertFromList =
  (value: any) => (dispatch: ThunkDispatch<ReadNewInfoState, void, Action>) => {
    dispatch({
      type: REMOVE_ALERT_FROM_LIST,
      payload: Object.keys(store.getState().readNewInfo.readNewInfoAlertList)
        .filter((key) => !key.includes(value))
        .reduce((cur, key) => {
          return Object.assign(cur, {
            [key]: store.getState().readNewInfo.readNewInfoAlertList[key],
          });
        }, {}),
    });
  };
