import './Home.scss';

import React, { useEffect, useState } from 'react';

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { regions } from '../constants';

import { PermissionsBanner } from '../components';
import HomeWidgetResumen from '../components/home/HomeWidgetResumen';
import { updateIndividualAlertsFromData } from '../features/notifications/notificationsActions';
import { useAppDispatch } from '../hooks';
import { CDAlertasApi } from '../services/CDAlertasApi';

const Home: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    data: alertData,
    isLoading,
    isFetching,
    isSuccess,
    refetch,
    isError: isAlertError,
    error: alertError,
  } = CDAlertasApi.useGetAllAlertsNoPaginationQuery({});
  const [isHomeLegendModalOpen, setIsHomeLegendModalOpen] = useState(false);

  const {
    data: alertDataSummaries,
    isLoading: isLoadingSummaries,
    isFetching: isFetchingSummaries,
    isSuccess: isSuccessSummaries,
    refetch: refetchSummaries,
    isError: isAlertErrorSummaries,
    error: alertErrorSummaries,
  } = CDAlertasApi.useGetAllRegionsSummariesQuery({});

  const alertDataSorted =
    alertDataSummaries &&
    [...alertDataSummaries.data]
      .map((el: any) => {
        return {
          ...el,
          labelShort: Object.keys(
            ((regions || [{}]).find(
              (el2: any) => Object.values(el2 || {})[0] === el.cutRegion
            ) as any) || {}
          )[0],
        };
      })
      .filter((el: any) => el.activeAlerts.meta.count > 0)
      .sort((a: any, b: any) => a.order - b.order);

  // * Update individual alert subscriptions
  // * as they appear in the alert data.
  useEffect(() => {
    const doEffect = async () => {
      dispatch(updateIndividualAlertsFromData(alertData));
    };
    doEffect();
  }, [alertData]);

  const handleRefresh = async (evt: any) => {
    await refetchSummaries();
    await refetch();
    evt.detail.complete();
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonTitle className="ion-no-padding">
              <span role="heading" aria-level={1}>
                Inicio
              </span>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent
          scrollEvents={true}
          fullscreen
          className="ion-content-custom-background"
        >
          <div className="scroll-to-top" id="scroll-to-top-home"></div>
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>

          <PermissionsBanner />

          <HomeWidgetResumen
            alertData={alertDataSorted}
            length={alertData?.data.length}
            isLoading={isLoadingSummaries}
            isFetching={isFetchingSummaries}
            isSuccess={isSuccessSummaries}
            isHomeLegendModalOpen={isHomeLegendModalOpen}
            setIsHomeLegendModalOpen={setIsHomeLegendModalOpen}
          />
        </IonContent>
      </IonPage>
    </>
  );
};

export default Home;
